import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 972px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 800px;
  background: #ffffff;

  form {
    width: 53%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    label {
      width: -50%;
      color: #333333;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      font-size: 16px;

      &::placeholder {
        color: #333;
      }
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #6fa824;
      font-weight: bold;
      color: #fff;
      border: 0;
      outline: none;
      border-radius: 50px;
      font-size: 16px;
      transition: background 0.2s;
      cursor: pointer;
      &:hover {
        background: ${darken(0.03, "#6fa824")};
      }

      .update {
      border: 0;
      outline: 0;
      background: #0275d8;
    }
    
    }

    select {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      //color: #CCCCCC;
      color: #333;
      margin: 0 0 10px;
      padding-right: 10px;
      text-decoration: none !important;
      font-size: 16px;

      &options {
        color: #006cff;
      }
    }

    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
  }
    
`;

/*export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;*/
export const Label = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  width: 600px;

  label {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  select {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
    background-color: #eeeeee;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 15px;
    margin: 0 0 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    margin: auto;
    width: 80%;
  }
`;
/*
export const LabelSignIn = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  width: 600px;

  label {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
  }

  input,
  select {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
    background-color: #eeeeee;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 15px;
    margin: 0 0 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 700px) {
    width: 500px;
    padding: 0;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
    padding: 0;
  }
  @media screen and (max-width: 500px) {
    width: 350px;
    padding: 0;
  }
  @media screen and (max-width: 500px) {
    width: 300px;
    padding: 0;
  }
  @media screen and (max-width: 320px) {
    width: 250px;
    padding: 0;
  }
`;*/

export const Title = styled.h3`
  color: #333333;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  width: 55%;
  text-align: left;
  margin: 3rem 0 1rem 0;

  @media screen and (max-width: 972px) {
    font-size: 20px;
    margin-bottom: 8%;
  }

  @media screen and (max-width: 900px) {
    padding-top: 30px;
    margin-bottom: 8%;
  }
  @media screen and (max-width: 700px) {
    margin: 0;
    width: 80%;
    margin-left: 50px;
    margin-bottom: 8%;
  }

  @media screen and (max-width: 420px) {
    margin-left: 40px;
    margin-bottom: 8%;
  }
`;

export const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-decoration: none;

  @media screen and (max-width: 700px) {
    width: 80%;
    margin-left: 10%;
  }
  @media screen and (max-width: 420px) {
    width: 80%;
    margin-left: 10%;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const ButtonSair = styled(Link)`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "none" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #6fa824;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 1px solid #6fa824;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-align: center;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ButtonAtualizar = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-align: center;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) {
    font-size: 16px;
  }
`;
