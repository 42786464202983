import React, { useState } from "react";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import { useAuth } from "../../hooks/auth";
//import { useHistory } from "react-router";
import api from "../../services/api";
//import { toast } from "react-toastify";
//import { useParams } from "react-router-dom";
import {
  ContainerGeral,
  QuestionCard,
  QuestionBox,
  Question,
  QuestionText,
  Container,
  AnswerListContainer,
  ButtonQuestion,
} from "./QuestionaireAtualization.elements";

function QuestionaireAtualization() {
  const [dataQuestion, setDataQuestion] = useState([]);
  //const [currentQuestion] = useState(0);
  //const [clicked, setClicked] = useState(false);
  const { addAnswered } = useAuth();
  //const [pogress, setPogress] = useState(1);

  const [isChecked, setIsChecked] = useState(false);

  //const params = useParams();
  //const history = useHistory();

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(
        `question/filter?categoryId=614ce9e7a9ed10f8b82ed514&&614ce734a9ed10f8b82ed4c3`
      );
      setDataQuestion(response.data.docs);
    }
    loadData();
  }, []);

  // console.log('some', dataQuestion.map(e => {
  //   return e.answerList
  // }))

  //const complete = localStorage.getItem("@legaro:question")

  const handleChangeAnswer = (data) => {
    setIsChecked(!isChecked);
    addAnswered(data);
    //console.log(data);
  };

  return (
    <div>
      <HeaderAfterLogin />
      {/*<span>{JSON.stringify(dataQuestion, null, 2)}</span>*/}
      <ContainerGeral>
        <h1>Atualize suas respostas</h1>
        <p>Tema Ambiental</p>
        <QuestionCard>
          <Container>
            {dataQuestion.map((item, index) => (
              <QuestionBox key={index}>
                <Question>
                  <QuestionText>
                    {index + 1}. {item.question}
                  </QuestionText>
                  <AnswerListContainer>
                    Respostas em Desenvolvimento
                  </AnswerListContainer>
                </Question>
              </QuestionBox>
            ))}
          </Container>
        </QuestionCard>
        <ButtonQuestion to="/dashboard">
          <button className="returnbutton">voltar</button>
          <button type="button">atualizar</button>
        </ButtonQuestion>
      </ContainerGeral>
    </div>
  );
}

export default QuestionaireAtualization;
