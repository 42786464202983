import styled, {css} from "styled-components";
//import { Link } from "react-router-dom";

export const Container = styled.div`
  margin: 3rem auto 3rem auto;
`;

export const LockPageContainer = styled.div`
  width: 65rem;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1160px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1077px) {
    width: 100%;
  }
`;

export const FaseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 60%;
  margin: auto;
`;

export const FaseTitle = styled.div`
  color: #333333;
  font-size: 2.5rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  margin-bottom: 3rem;

  @media screen and (max-width: 400px) {
    font-size: 2rem;
  }
`;

export const LockCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: auto;
  width: 70%;

  @media screen and (max-width: 1160px) {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    justify-content: center;
  }
  @media screen and (max-width: 660px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LockResponsiveContainer = styled.div`
  @media screen and (max-width: 1160px) {
    margin: auto;
  }
  @media screen and (max-width: 660px) {
    margin: 0;
    margin-bottom: 10%;
  }
`;

export const LockContainer = styled.div`
  width: 290px;
  height: 492px;
  ${({isActive}) => isActive ? css`
    border: 2px solid #6fa824;  
  ` :
  css`
    border: 2px solid #c1c1c1;  
  `
}
  border-radius: 5px 5px 0 0;
  padding: 35px;

  @media screen and (max-width: 660px) {
    width: 100%;
    height: 30%;
    padding: 20px;
    padding-bottom: 25%;
  }
`;

export const LockImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  @media screen and (max-width: 660px) {
    margin: 0;
    padding: 0;
    margin-bottom: 5%;
  }
`;
export const LockContainerBlocked = styled.div`
  width: 290px;
  height: 492px;
  border: 2px solid #c1c1c1;
  border-radius: 5px 5px 0 0;
  padding: 35px;

  @media screen and (max-width: 660px) {
    width: 100%;
    height: 30%;
    padding: 20px;
    padding-bottom: 25%;
  }
`;
export const LockImage = styled.div`
  text-align: center;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color:  ${({isActive}) => isActive ? '#6fa824' : '#c1c1c1'};
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 660px) {
    width: 80px;
  }
`;
export const LockImageBlocked = styled.div`
  text-align: center;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color: #c1c1c1;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;
export const Image = styled.div`
  text-align: center;
  padding-bottom: 30px;
  padding-top: 15px;
`;
export const LockSubtitle = styled.div`
  color: #666666;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
`;
export const LockTitle = styled.div`
  color: #4b4b4b;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-top: 10px;
`;
export const LockText = styled.div`
  color: #333333;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin-top: 30px;
`;
export const ButtonResult = styled.div`
  cursor: pointer;
  width: 290px;
  height: 71px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #6fa824;
  border-radius: 0 0 5px 5px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    padding-left: 8px;
  }

  @media screen and (max-width: 420px) {
    width: 250px;
    height: 71px;
    padding: 20px;
  }

  @media screen and (max-width: 660px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;
export const ButtonResultBlocked = styled.div`
  width: 376px;
  height: 71px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #c1c1c1;
  border-radius: 0 0 5px 5px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    padding-left: 8px;
    color: white;
  }
`;
