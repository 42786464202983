import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;
export const giCcXI = styled.div`
  color: green;

  &:active {
    color: green;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20px;
  //border: 1px solid red;
  @media screen and (max-width: 972px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (max-width: 1371px) {
    width: 90rem;
  }
  button {
    margin: 5px 0 0;
    height: 44px;
    background: #6fa824;
    font-weight: bold;
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    transition: background 0.2s;
    cursor: pointer;
    &:hover {
      background: ${darken(0.03, "#6fa824")};
    }
    .update {
      border: 0;
      outline: 0;
      background: #0275d8;
    }
  }
  .divButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .divHr {
    width: 90%;
    margin-top: 1%;
    margin-bottom: 2%;
    border: none;
    border-top: 1px solid #c8c4c4;
  }
`;

export const Title = styled.h3`
  color: #333333;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-align: left;

  //border: 1px solid #6fa824;
  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`;

export const MarketPlace = styled.h3`
  color: #333333;
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  background-color: #6fa824;
  color: white;
  border-radius: 30px;
  padding: 10px 20px;

  //border: 1px solid #6fa824;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 1rem 0;
  width: 85%;

  //border: 1px solid red;

  @media screen and (max-width: 600px) {
    margin: 2rem 0 1rem 0;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  vertical-align: super;
  //border: 1px solid red;
  width: 100%;
  background: #ffffff;
  form {
    width: 53%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    label {
      width: -50%;
      color: #333333;
      margin-bottom: 5px;
      font-weight: bold;
    }
    input {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      font-size: 16px;
      //border: 1px solid red;
      &::placeholder {
        color: #333;
      }
    }
    select {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      padding-right: 10px;
      text-decoration: none !important;
      font-size: 16px;
      &options {
        color: #006cff;
      }
    }
    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 85%;
  margin-bottom: 20px;
  //border: 1px solid green;
  .form-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1110px) {
      flex-direction: column;
    }

    span {
      color: transparent;
    }
    //border: 1px solid blue;
  }
  textarea {
    border: 0;
    outline: none;
    border-radius: 10px;
    height: 50px;
    padding: 15px;
    color: #333;
    margin: 0 0 10px;
    font-size: 16px;
    background-color: #eeeeee;
    resize: vertical;
  }
`;

export const ContainerInterno = styled.div`
  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  width: 100%;
  :nth-child(2n) {
    margin-left: 5%;
    @media screen and (max-width: 1110px) {
      margin-left: 0;
      margin-top: 5%;
    }
  }

  .form-input,
  textarea,
  select,
  option,
  .form-responsible,
  .form-email,
  .form-phone,
  .form-cargo,
  .form-sector {
    width: 100%;
  }

  .calendarContainer {
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .calendarDiv {
    //border: 1px solid red;
    display: flex;
    flex-direction: column;
    width: 45%;
  }

  .toggleContainer {
    //border: 1px solid red;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .calendario {
    //border: 1px solid red;
    width: 100%;
    cursor: default;
  }
  .calendarioIcon {
    //border: 1px solid red;
    width: 10%;
    cursor: pointer;
    margin: auto;

    background: #eeeeee;
    border-radius: 0 10px 10px 0;
    height: 50px;
    padding: 15px;
    color: #333;
    margin: 0 0 10px;
    padding-right: 10px;
    font-size: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  //border: 1px solid red;
  .btn-cadastro {
    background-color: #6fa824;
    border: 1px solid #6fa824;
    color: #fff;
    margin-left: 20px;
  }
  .btn-cancelar {
    background-color: transparent;
    border: 1px solid #a6a6a6;
    color: #a6a6a6;
    margin-left: 20px;
  }
  .btn-cancelar:hover {
    background-color: transparent;
  }
  .btn-cancelar,
  .btn-cadastro {
    width: 100%;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  //border: 1px solid red;
  justify-content: space-between;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
    width: 100%;
    input {
      //cursor: pointer;
      width: 100%;
    }
    :nth-child(1n) {
      //border: 1px solid red;
      margin-right: 5px;
    }
    :nth-child(2n) {
      //border: 1px solid red;
      margin-left: 5px;
    }
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  width: 600px;
  label {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  input,
  select {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
    height: 50px;
    background-color: #eeeeee;
    border: none;
    border-radius: 10px;
    outline: none;
    padding: 15px;
    margin: 0 0 10px;
    font-size: 16px;
  }
  @media screen and (max-width: 700px) {
    margin: auto;
    width: 80%;
  }
`;

export const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-decoration: none;
  @media screen and (max-width: 700px) {
    width: 80%;
    margin-left: 10%;
  }
  @media screen and (max-width: 420px) {
    width: 80%;
    margin-left: 10%;
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const ButtonSair = styled(Link)`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "none" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #6fa824;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 1px solid #6fa824;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ButtonAtualizar = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 400px) {
    font-size: 16px;
  }
`;
