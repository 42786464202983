import React from "react";
import { IconContext } from "react-icons/lib";
import {
  Container,
  ButtonMoveAndAnswer,
  Closewindow,
} from "../../globalStyles";
import {
  ScoreSection,
  ScoreWrapper,
  ScoreHeading,
  ScoreContainer,
  ScoreCardInfo,
  ScoreCardIcon,
  ScoreCardPlan,
  ScoreCardCost,
  ScoreCardFeatures,
  ScoreCardFeature,
  ScoreCardAnswer,
  CloseHeading,
  CheckBox,
} from "./CheckPages.elements";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";

function CheckPages() {
  const questionItems = JSON.parse(localStorage.getItem("@legaro:question"));
  const takeID = localStorage.getItem("@legaro:companyID");

  // console.log(docs)

  const history = useHistory();

  const handleBack = () => {
    history.push("/dashboard");
  };

  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <Container>
        <CloseHeading>
          <Closewindow
            onClick={() => handleBack()}
            style={{ marginLeft: "auto" }}
          >
            <strong>X</strong>
          </Closewindow>
        </CloseHeading>
        <ScoreHeading>
          Selecione um <br />
          tema abaixo:
        </ScoreHeading>
        <ScoreSection>
          <ScoreWrapper>
            <ScoreContainer>
              {questionItems.map((item, i) => (
                <ScoreCardAnswer key={i}>
                  <CheckBox>
                    <img
                      src="https://codexremote.com.br/esgimages/legaro/CheckIcon-01.png"
                      alt="Check Box"
                    />
                  </CheckBox>
                  <ScoreCardInfo>
                    <ScoreCardIcon>
                      <img src={item.thumb} alt="Tema" />
                    </ScoreCardIcon>
                    <ScoreCardPlan>
                      {item.label} {i + 1}
                    </ScoreCardPlan>
                    <ScoreCardCost>{item.name}</ScoreCardCost>
                    <ScoreCardFeatures>
                      <ScoreCardFeature>{item.description}</ScoreCardFeature>
                    </ScoreCardFeatures>
                    <ButtonMoveAndAnswer>
                      <Link to={`/questionario/${takeID}`}>
                        <strong>atualizar</strong>
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </ButtonMoveAndAnswer>
                  </ScoreCardInfo>
                </ScoreCardAnswer>
              ))}
              <ScoreCardAnswer>
                <CheckBox>
                  <img
                    src="https://codexremote.com.br/esgimages/legaro/CheckIcon-01.png"
                    alt="Check Box"
                  />
                </CheckBox>
                <ScoreCardInfo>
                  <ScoreCardIcon>
                    <img
                      src="https://codexremote.com.br/esgimages/legaro/icon08.png"
                      alt="Econômico"
                    />
                  </ScoreCardIcon>
                  <ScoreCardPlan>Tema 4</ScoreCardPlan>
                  <ScoreCardCost>Econômico</ScoreCardCost>
                  <ScoreCardFeatures>
                    <ScoreCardFeature>
                      Apresenta o desempenho econômico e financeiro da empresa,
                      considerando riscos e oportunidades, assim como a
                      organização de finanças e a disponibilização de
                      investimentos alinhados às práticas de gestão.
                    </ScoreCardFeature>
                  </ScoreCardFeatures>
                  <ButtonMoveAndAnswer>
                    <Link to={`/questionario/${takeID}`}>
                      <strong>atualizar</strong>
                      <i className="fas fa-arrow-right"></i>
                    </Link>
                  </ButtonMoveAndAnswer>
                </ScoreCardInfo>
              </ScoreCardAnswer>
            </ScoreContainer>
          </ScoreWrapper>
        </ScoreSection>
      </Container>
    </IconContext.Provider>
  );
}
export default CheckPages;
