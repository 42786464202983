import styled from "styled-components";
import { Link } from "react-router-dom";

export const ExportResults = styled.div``;

export const Header = styled.div``;

export const ButtonPlanoAcao = styled.div`
  font-family: "Montserrat", sans-serif;
  border-radius: 30px;
  color: #fff;
  border: 1px solid #6fa824;
  background-color: #6fa824;
  margin: 0px 5px;
  margin-top: 20px;
  padding: 15px 4%;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
`;
