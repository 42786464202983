import styled from "styled-components";

export const TitleDashboard = styled.div`
  margin-top: 4rem;
  margin-bottom: 1rem;
  h3 {
    width: 275px;
    font-size: 40px;
    font: normal normal bold 40px/40px Roboto;
    letter-spacing: 0px;
    color: #333333;
    text-align: left;
  }
`;

export const NavLinks = styled.div`
  color: #444444;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: white !important;
  cursor: pointer;
  @media screen and (max-width: 1170px) {
    text-align: center;
    padding: 5% 1rem;
    width: 100%;
    display: table;
    &:hover {
      color: #6fa824;
      transition: all 0.3s ease;
    }
  }
`;

export const ButtonDashboard = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 2rem;
`;

export const TableDashboard = styled.div`
  color: #4b4b4b;
  margin-bottom: 3rem;
  a {
    text-decoration: none;
    color: #4b4b4b;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Legend = styled.div`
  margin-top: 15px;
  display: flex;
  div {
    vertical-align: middle;
    margin: 10px 40px 10px 0;
    color: #808080;
    font-size: 14px;
    text-align: middle;
    justify-content: center;
  }
`;
export const Text = styled.div`
  display: inline-block;
  float: start;
  text-align: middle;
  justify-content: center;
`;

export const AcessoQuestionario = styled.div`
  border: 1px solid #6fa824;
  border-radius: 10px;
  padding: 5px;
  color: #6fa824;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  &:hover {
    text-decoration: underline white;
  }
`;
export const AcessoResultado = styled.div`
  border: 1px solid #4b4b4b;
  border-radius: 10px;
  padding: 5px;
  color: #4b4b4b;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  &:hover {
    text-decoration: underline white;
  }
`;
export const Page = styled.div``;
