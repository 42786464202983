import React from "react";
import { Link } from "react-router-dom";
import { MultiStepForm } from "../../components/Form/MultiStepForm";
import { Container, Background, ModalImg, CloseWindow } from "./Style.Elements";
import SignInText from "../../template/SignInText/index";

function SignUp() {
  return (
    <Container>
      {/* <CloseWindow>
        <Link to="/">x</Link>
      </CloseWindow> */}

      <Background>
        <SignInText />
      </Background>

      {/* <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          alt="someone"
        />
      </Background> */}
      <MultiStepForm />
    </Container>
  );
}

export default SignUp;
