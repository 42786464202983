import React from "react";

import {
  IndicaterContainer,
  IndicaterSession,
  IndicaterWrapper,
  IndicaterCard,
  IndicaterInfos,
  IndicaterPartner,
} from "./Style.Elements";

function Indicadores() {
  return (
    <IndicaterContainer>
      <IndicaterSession>
        <h2>
          A ferramenta de ESG mais completa do Brasil com indicadores
          personalizados.
        </h2>
        <IndicaterWrapper>
          <IndicaterCard>
            <IndicaterInfos>
              <h1>+100</h1>
              <p>
                parâmetros e critérios <br />
                de avaliação utilizados <br />
                na metodologia de <br />
                obtenção do Score
              </p>
            </IndicaterInfos>
          </IndicaterCard>

          <IndicaterCard>
            <IndicaterInfos>
              <h1>+500</h1>
              <p>
                métricas de instituições <br />
                reconhecidas no mercado <br />
                como B3, DowJones, <br />
                MSCI e GRI
              </p>
            </IndicaterInfos>
          </IndicaterCard>

          <IndicaterCard>
            <IndicaterInfos>
              <h1>+400</h1>
              <p>
                indicadores <br />
                disponíveis para <br />
                análise na plataforma
              </p>
            </IndicaterInfos>
          </IndicaterCard>
        </IndicaterWrapper>
        <IndicaterPartner>
          <p>PARCEIROS INSTITUCIONAIS</p>
          <img
            src="https://codexremote.com.br/esgimages/legaro/logoFDC.webp"
            alt="Fundação Dom Cabral"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              padding: "25px 25px",
              borderRadius: "10px",
              height: "70px",
            }}
          />
        </IndicaterPartner>
      </IndicaterSession>
    </IndicaterContainer>
  );
}

export default Indicadores;
