import React from "react";
import { Footer, Navbar } from "../../components";
import { Title, Container, Content, Text, Image } from "./style.elements";

function Partner() {
  return (
    <Container>
      <Navbar />
      <Title>
        <strong>Seja nosso parceiro!</strong>
      </Title>
      <Content>
        <Image>
          <img
            src="https://codexremote.com.br/esgimages/legaro/Home0.webp"
            alt="imagem"
          />
        </Image>
        <Text>
          <h2>
            <strong>Vamos juntos fazer a diferença no mundo?</strong>
          </h2>{" "}
          <br />
          <p className="lead">
            Buscamos instituições que entendam a necessidade e a importância das
            práticas ESG no meio corporativo, assim como seus efeitos na
            sociedade e no mundo. Desejamos alcançar parceiros que queiram
            construir uma metodologia ESG e que apoiem a divulgação da Legaro.
            <br />
            <br />
            O objetivo dessa parceria será capacitar pessoas quanto às práticas
            ESG, fornecendo assistência e direcionamento sobre a sua
            importância, possibilitando o acesso à capacitação de orientadores
            técnicos, especialistas e de equipe, e alavancando o conhecimento e
            o direcionamento sobre essa sigla que é tida como orientadora de
            investimentos.
            <br />
            <br />
            Juntos, podemos fomentar a responsabilidade ambiental e social,
            assim como gerenciar e promover melhorias quanto à gestão de
            governança e econômica de sua empresa e organização.
            <br />
            <br />
            Podemos contar com você?
          </p>
          <p className="MoreInfo">
            Para mais informações, entre em contato conosco através do e-mail{" "}
            <a type="email" href="mailto:contato@legaroesg.com.br">
              contato@legaroesg.com.br
            </a>
            .
          </p>
        </Text>
      </Content>
      <Footer />
    </Container>
  );
}

export default Partner;
