import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  font-size: 50px;
  color: #444444;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;

  @media screen and (max-width: 960px) {
    font-size: 250%;
  }

  @media screen and (max-width: 600px) {
    font-size: 150%;
    padding-left: 6%;
    padding-right: 6%;
    text-align: center;
    width: 100%;
  }
`;
export const Container = styled.div`
  background-color: #f2f2f2;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 70px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-bottom: 15%;
  }
`;
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  font-size: 20px;
  color: #666666;
  font-family: "Roboto", sans-serif;

  .MoreInfo {
    font-size: 20px;
    color: #666666;
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;

    a {
      color: #6fa824;
      font-weight: bold;
    }
  }

  h2 {
    color: #444444;
    font-size: 35px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    justify-content: left;
    width: 100%;

    @media screen and (max-width: 960px) {
      font-size: 150%;
    }

    @media screen and (max-width: 600px) {
      font-size: 130%;
    }
  }

  @media screen and (max-width: 960px) {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    font-size: 100%;
  }
`;
export const Image = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 5%;

  @media screen and (max-width: 1100px) {
    img {
      width: 90%;
    }
  }

  @media screen and (max-width: 960px) {
    align-items: center;
    padding: 0;
    margin-bottom: 8%;
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10%;
  }
`;
