import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function DialogRemoveCompany({ open, handleClosedDialog }) {
  const [loading, setLoading] = React.useState(false);

  async function handleRemove() {
    const ID = localStorage.getItem("@legaro:companyID");

    try {
      await api.delete(`companies/${ID}`);
      setLoading(true);

      toast.success("Empresa removida com sucesso");

      setLoading(false);
    } catch (error) {
      //console.log("ERRO", error);
      return toast.error("Não foi possível remover esta empresa");
    }
  }

  return (
    <>
      {loading && (
        <div>
          <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClosedDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Você tem certeza que deseja remover este item da lista? "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "#333" }}>
            Por favor, confirme abaixo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClosedDialog}
            style={{ color: "6fa824" }}
          >
            Não
          </Button>
          <Button
            onClick={() => {
              handleRemove();
              handleClosedDialog();
            }}
            color="#6fa824"
            autoFocus
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
