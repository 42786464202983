import React, { useState, useEffect } from "react";
import FaseCard from "../../components/Fases/FaseCard";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import api from "../../services/api";

import {
  FasesContainer,
  FaseSubtitle,
  FooterText,
  FasesConsteudo,
} from "./Fases.elements";

function Fases() {
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await api.get("stages");
      setStages(response.data);
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <div>
      <HeaderAfterLogin />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
          <span style={{ marginLeft: 5 }}>Carregando as fases 🕓</span>
        </div>
      ) : (
        <FasesContainer>
          <FasesConsteudo>
            {/*<FaseHeader>
            <FaseTitle>Como obter seu score ESG?</FaseTitle>
          </FaseHeader>*/}
            <FaseCard stages={stages} />
          </FasesConsteudo>
          {/* <FooterText>
          <FaseSubtitle>Clique na opção desejada para desbloquear</FaseSubtitle>
        </FooterText> */}
        </FasesContainer>
      )}
    </div>
  );
}

export default Fases;
