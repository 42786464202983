import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  background: #ffffff;

  form {
    width: 53%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    label {
      width: -50%;
      color: #333333;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      font-size: 16px;

      &::placeholder {
        color: #333;
      }
    }
    select {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      //color: #CCCCCC;
      color: #333;
      margin: 0 0 10px;
      padding-right: 10px;
      text-decoration: none !important;

      &options {
        color: #006cff;
      }
    }

    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #6fa824;
      font-weight: bold;
      color: #fff;
      border: 0;
      outline: none;
      border-radius: 50px;
      font-size: 16px;
      transition: background 0.2s;
      cursor: pointer;
      &:hover {
        background: ${darken(0.03, "#6fa824")};
      }
    }
    a {
      text-align: center;
      margin-top: 1rem;
      font-size: 12px;
      font-family: normal normal medium 16px/50px Roboto;
      letter-spacing: 0px;
      color: #006cff;
      opacity: 0.8;
      font-weight: bold;
      &:hover {
        opacity: 1;
      }
    }
    p {
      font-size: 14px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: #233746;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 900px) {
    display: flex;
    height: 40%;
    align-items: center;
  }
`;

export const ModalImg = styled.img`
  height: 45%;

  @media screen and (max-width: 900px) {
    height: 85%;
  }
`;

export const Title = styled.h3`
  color: #333333;
  font-size: 25px;
  font-family: normal normal bold 25px/30px Roboto;
  font-weight: bold;
  width: 370px;
  text-align: left;
`;

export const Description = styled.p`
  color: #333333;
  font-size: 14px;
  font-family: normal bold 25px/30px Roboto;
  width: 370px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 5px;
  line-height: 2;
`;

export const TermAndCondition = styled.div`
  display: flex;
  align-items: center;

  label {
    font-size: 12px;
    padding-left: 10px;
  }
`;

export const CloseWindow = styled(Link)`
  display: flex;
  position: absolute;
  margin: 20px 30px;
  cursor: pointer;
  text-decoration: none;

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    position: absolute !important;
    margin-left: -90%;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    position: absolute !important;
    margin-left: -85%;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    position: absolute !important;
    margin-left: -80%;
  }
`;

export const Text = styled.span`
  color: #222222;
  font-size: 16px;
  margin-top: 20px;
  max-width: 500px ;
`