import React, { useState } from "react";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
//import * as Yup from "yup";
import { Container, Content, Title } from "./Edit.elements";
import { useAuth } from "../../hooks/auth";
import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';
import api from "../../services/api";

//Validation
/*const schema = Yup.object().shape({
name: Yup.string().required("Nome é obrigatório"),
email: Yup.string().email("Email Inválido").required("E-mail é obrigatório"),
phone: Yup.string().required("Telefone é obrigatório"),
cargo: Yup.string().required("Cargo é obrigatório"),
oldPassword: Yup.string(),
      password: Yup.string()
        .min(6)
        .when('oldPassword', (oldPassword, field) =>
          oldPassword ? field.required() : field
        ),
      confirmPassword: Yup.string().when('password', (password, field) =>
        password ? field.required().oneOf([Yup.ref('password does not match')]) : field
      ),
});*/

// Offices
const CargoOptions = [
  { value: "", name: "Selecione um cargo" },
  { value: "Estudante", name: "Estudante" },
  { value: "Analista", name: "Analista" },
  { value: "Gerente", name: "Gerente" },
  { value: "Diretor", name: "Diretor" },
  { value: "Outro", name: "Outro" },
];

const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const User = () => {

  const { user } = useAuth();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [office, setOffice] = useState(user.cargo);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("")

   async function handleSubmit({ name, email, phone }) {
    if (!name || !email || !phone) {
      return toast.error(
        "Não foi possível atualizar. Preencha  todos os dados!"
      );
    }
    try {
      await api.put(
        `/users/${user._id}`,
        {
          name,
          email,
          phone,
          cargo: office,
          ...(oldPassword && {oldPassword}),
          ...(password && {password}),
          ...(confirmPassword && {confirmPassword})
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log('DATA', name, email, phone, office, oldPassword, password, confirmPassword);
     
      toast.success("Seus dados foram atualizados com sucesso");

    } catch (err) {
      //console.log("ERRO", err);
      return toast.error("Não foi possível atualizar seus dados");
    }
  }

  function handleOffice(event) {
    setOffice(event.target.value);
  }

  return (
    <>
    <HeaderAfterLogin />
    <Container>
      <Content>
        <Title>Atualize suas informações</Title>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="name">Nome</label>
          <Input 
            name="name" 
            type="text"  
            value={name} 
            minLength="8"
            required 
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="email" required>
            E-mail
          </label>
          <Input
            name="email"
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
          <label htmlFor="phone">Telefone</label>
          <Input
            type="phone"
            id="phone"
            name="phone"
            minLength="14"
            maxLength="15"
            required
            value={phone}
            onChange={(e) => setPhone(maskPhone(e.target.value))}
          />
          <label htmlFor="office">Cargo</label>
          <select onChange={handleOffice}  value={office} name="office" required>
            {CargoOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <label htmlFor="oldPassword">Senha atual</label>
          <Input 
            name="oldPassword" 
            type="password" 
            minLength="6"
            value={oldPassword} 
            onChange={(e) => setOldPassword(e.target.value)} 
          />
          <label htmlFor="password">Nova senha</label>
          <Input 
            name="password" 
            type="password"
            minLength="6" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)}
          />
          <label htmlFor="confirmPassword">Confirme a nova senha</label>
          <Input 
            name="confirmPassword" 
            type="password" 
            minLength="6" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <br />
          <hr />
          <br />
          {/* 
          <ButtonContainer>
            <ButtonSair
              style={{ backgroundColor: "white", width: "45%" }}
              to="/dashboard"
            >
              Voltar
            </ButtonSair>
            <ButtonAtualizar
              type="submit"
              style={{ width: "45%" }}
            >
              Atualizar
            </ButtonAtualizar>
          </ButtonContainer>*/}

            <button className="update" type="submit">ATUALIZAR</button>

        </Form>
      </Content>
      </Container>
    </>
  );
};

export default User;
