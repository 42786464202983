import React from "react";
import { TransitionEconomico } from "../../components";
import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';

function TransitionEconomicoPage() {
  return (
    <>
      <HeaderAfterLogin />
      <TransitionEconomico />
    </>
  );
}

export default TransitionEconomicoPage;
