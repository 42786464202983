import React from "react";
import Helmet from "react-helmet";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

export default class CalendarRange extends React.Component {
  static defaultProps = {
    numberOfMonths: 1,
  };

  state = {
    newSelectedDay: undefined,
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleSelectDay = this.handleSelectDay.bind(this);
    this.loadData = this.loadData.bind(this);
    //this.saveDays = this.saveDays.bind(this);
    //this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
    this.state = {
      selectedDay: new Date(),
    };
  }

  getInitialState() {
    return {
      from: new Date(),
      to: undefined,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({ ...this.state, from: this.props.selectedSingleDate });
  }

  handleSelectDay(data) {
    this.props.setSelectedSingleDate(data);
    this.setState({ from: data, to: undefined });
    this.props.setDataStart(data);
    this.props.setFirstToggle();
  }

  handleDayClick(day, modifiers = {}) {
    const range = DateUtils.addDayToRange(day, this.state);
    // console.log({ range });
    // const firstDay = range[Object.keys(range)[0]];
    const secondDay = range[Object.keys(range)[1]];

    if (modifiers.disabled) {
      return;
    }

    this.setState({ ...range, from: this.props.selectedSingleDate });
    this.props.setDataStart(this.props.selectedSingleDate);
    this.props.setDataEnd(secondDay);
    this.props.setFirstToggle();
  }

  /* handleResetClick() {
    this.setState(this.getInitialState());
  } */

  render() {
    const { from, to } = this.state;
    const modifiers = {
      start: from,
      end: to,
    };

    const month = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const week = [
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
      "Domingo",
    ];

    const modifiersStyles = {
      thursdays: {
        color: "#ffc107",
        backgroundColor: "#fffdee",
      },
    };

    return (
      <div className="RangeExample">
        <div>
          <p className="frase-calendar">
            {/*  {!from && !to && "Please select the first day."} */}
            {/*  {from &&
            to &&
            `Data selecionada: ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{" "}
          {from && to && (
            <button className="link" onClick={this.handleResetClick}>
              Reset
            </button>
          )}*/}
          </p>
        </div>
        <div className="calendar">
          {this.props.single ? (
            <DayPicker
              mode="single"
              selected={this.state.newSelectedDay}
              onDayClick={this.handleSelectDay}
              months={month}
              weeks={week}
              disabledDays={[
                {
                  before: new Date(),
                },
              ]}
            />
          ) : (
            <DayPicker
              className="Selectable"
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              months={month}
              onDayClick={this.handleDayClick}
              disabledDays={[
                {
                  before: new Date(),
                },
              ]}
            />
          )}
        </div>
        <Helmet>
          <style>{`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #6fa824;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    background-color: #6fa824 !important;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .DayPicker-Day--today{
    color: black;
  }
  .RangeExample{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .DayPicker-Caption > div{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: bold;
  }
  .DayPicker-Months{
    background-color: #eeeeee;
    border-radius: 5%;
  }
  .DayPicker-Day--disabled {
    color: #C0BEAF;
  }
  .DayPicker-NavButton {
    //border: 1px solid red;
    position: static;
    justify-content: right;
  }
  .DayPicker-NavButton--prev {
    //border: 1px solid red;
  }
  .DayPicker-NavButton--next {
    //border: 1px solid green;
  }
  .DayPicker-NavBar{
    position: absolute;
    display: flex;
    justify-content: right;
    margin: 5% 0 0 80%; 
  }
  .calendar{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .frase-calendar {
    color: red;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}
