import React from "react";
import { Footer, SimpleAccordion, Navbar, HelpForm } from "../../components";

import {
  Container,
  PerguntasRespostas,
  TextoLateral,
  Texto,
  Content,
  HelpButtons,
} from "./Help.elements";

const Results = () => <HelpForm />;

function Help() {
  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);

  return (
    <>
      <Navbar />
      {/*<SearchArea>
        <TextIntro>Olá, como podemos lhe ajudar?</TextIntro>
        <InputArea>
          <input
            type="text"
            placeholder="Insira sua pergunta ou palavra-chave."
          ></input>
        </InputArea>
      </SearchArea>*/}
      <Container>
        <PerguntasRespostas>
          <Content>
            <TextoLateral>
              <h2>Perguntas e Respostas Frequentes</h2>
              <Texto>
                Preparamos este guia para sanar suas principais dúvidas de forma
                simples e rápida. Mas se você ainda precisar de nossa ajuda,
                entre em contato conosco. Estamos à disposição para lhe
                auxiliar.
              </Texto>
            </TextoLateral>
            <SimpleAccordion />
          </Content>
        </PerguntasRespostas>

        <HelpButtons>
          <button
            id="results"
            className="search-results"
            type="submit"
            value="Search"
            onClick={onClick}
            style={{
              backgroundColor: "white",
              border: "none",
              outline: "none",
              shadow: "none",
            }}
          >
            <span>Não conseguiu tirar suas dúvidas? Fale conosco.</span>
          </button>
        </HelpButtons>
        {showResults ? <Results /> : null}
      </Container>
      <Footer />
    </>
  );
}

export default Help;
