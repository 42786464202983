import { Link } from "react-router-dom";
import styled from "styled-components";
//import { Link } from "react-router-dom";

export const TypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  width: 100%;
`;

export const TypographyColumnContainer = styled.div`
  color: #666666;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
`;

export const TypographyColumn = styled.div`
  color: #666666;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const TypographyText = styled.div`
  @media screen and (max-width: 423px) {
    justify-content: left;
    text-align: left;
  }
`;

export const Typography = styled.div`
  color: red;
`;

export const IndicatorsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  vertical-align: middle;
  margin-bottom: 3%;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Indicators = styled.div`
  font-family: "Montserrat", sans-serif;
  margin-right: 40px;
  color: #333333;
  font-weight: bold;
  vertical-align: middle;
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: 14px;
    margin-right: 30px;
    width: 7rem;
  }
`;

export const IndiscatorsWeight = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-weight: bold;
  width: 8rem;
  text-align: right;

  @media screen and (max-width: 700px) {
    font-size: 14px;
    width: 3.5rem;
  }
`;

export const IndicatorsMoreDetails = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-size: 17px;
  text-align: center;
  margin-bottom: 1.5%;
`;

export const ButtonKnowMore = styled(Link)`
  color: #ffffff;
  background-color: #6fa824;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  height: 50px;
  text-decoration: none;

  p {
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
  }
`;

export const Image = styled.div`
  margin-right: 10px;
`;
