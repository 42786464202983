import React, { Component } from "react";
import Chart from "react-apexcharts";

class App extends Component {
  componentDidMount() {
    /* const IDcompany = localStorage.getItem("@legaro:companyID");
    console.log("qual é o ID:", this.props); */

    this.setState({
      customers: this.props.data,
      options: {
        ...this.state.options,
        plotOptions: {
          ...this.state.options.plotOptions,
          radialBar: {
            ...this.state.options.plotOptions.radialBar,
            endAngle: (this.props.data.generalScore * 120) / 5000,
          },
        },
        series: [(this.props.data.generalScore * 120) / 5000],
      },
    });
  }

  constructor(props) {
    super(props);

    // const resultScore = localStorage.getItem("@legaro:score")

    this.state = {
      customers: [],
      options: {
        chart: {
          height: 280,
          type: "radialBar",
          width: "10%",
        },
        series: [],
        plotOptions: {
          backgroundBarOpacity: [0],
          radialBar: {
            hollow: {
              margin: 0,
              size: "78%",
            },
            startAngle: -120,
            endAngle: 0,
            track: {
              show: true,
              dropShadow: {
                enabled: false,
              },
              background: "#e1e8ed",
              startAngle: -120,
              endAngle: 120,
            },
            dataLabels: {
              name: {
                show: true,
                color: "#666666",
                offsetY: 50,
                fontSize: "18px",
              },
              value: {
                formatter: function (val) {
                  return parseInt((val * 5000) / 120);
                },
                fontSize: "95px",
                color: "#333333",
                fontWeight: "bolder",
                show: true,
                offsetY: -7,
                fontFamily: "Roboto",
              },
            },
          },
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        fill: {
          colors: [
            function ({ value }) {
              const newValue = (value * 5000) / 120;
              if (newValue >= 3001) {
                return "#6fa824";
              }
              if (newValue <= 1500) {
                return "#f3482a";
              } else {
                return "#b4b10c";
              }
            },
          ],
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["SCORE ESG"],
      },
    };
  }
  render() {
    return (
      <div className="app">
        {/*JSON.stringify(this.state.customers.generalScore, null, 2)*/}
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.options.series}
              type="radialBar"
              width="500"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
