import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AiFillMail } from "react-icons/ai";
import {
  ContentCompanyInfos,
  Title,
} from "../../components/StepForm/Style.Elements";
import { Container, Background, ModalImg, Text } from "./Style.Elements";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";

const EmailConfirmation = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const userData = history.location.state;
  const { signIn, signOut } = useAuth();

  const handleCheckEmail = async () => {
    try {
      setIsLoading(true);

      const response = await api.post("users/check", {
        email: userData?.email,
      });

      if (response?.data.emailConfirmed) {
        signOut();

        await signIn({
          email: userData?.email,
          password: userData?.password,
          method: "signup",
        });

        history.push({
          pathname: "/sign-up-company",
          state: userData,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.warn("E-mail não confirmado");
      }
    } catch (error) {
      toast.warn("Erro na confirmação de e-mail");
    }
  };

  const handleResendEmail = async () => {
    try {
      setIsLoadingResend(true);
      const userData = history.location.state;

      const response = await api.post("users/resend", {
        email: userData?.email,
      });

      if (response?.data.resend) {
        toast.success("E-mail de confirmação enviado 😃");
      }
      setIsLoadingResend(false);
    } catch (error) {
      setIsLoadingResend(false);
      toast.warn("Erro no reenvio de e-mail");
    }
  };

  return (
    <Container>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          alt="someone"
        />
      </Background>
      <ContentCompanyInfos>
        <AiFillMail size={100} color="#6fa824" />
        <Title
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          Confirme seu e-mail!
        </Title>
        <Text
          style={{
            display: "flex",
            justifyContent: "justify",
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          Clique no link de confirmação enviado para
        </Text>
        <Text
          style={{
            display: "flex",
            justifyContent: "justify",
            fontWeight: "bold",
            marginBottom: "50px",
            color: "#6fa824",
          }}
        >
          {userData?.email}
        </Text>
        <hr style={{ width: "70%" }} />
        <Text
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            fontSize: "16px",
          }}
        >
          Após confirmar, clique em continuar para prosseguir com o cadastro.
        </Text>
        <form>
          <button type="button" onClick={handleCheckEmail}>
            {isLoading ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "Continuar"
            )}
          </button>
          <button
            type="button"
            onClick={handleResendEmail}
            style={{
              backgroundColor: "transparent",
              color: "#6fa824",
            }}
          >
            {isLoadingResend ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "Reenviar e-mail"
            )}
          </button>
        </form>
      </ContentCompanyInfos>
    </Container>
  );
};

export default EmailConfirmation;
