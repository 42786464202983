import styled from "styled-components";

export const InfoStart = styled.div`
  color: #fff;
  padding: 140px 0;
  background: ${({ lightBg }) => (lightBg ? "#fff" : "#f2f2f2")};
  width: 100%;

  @media screen and (max-width: 960px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 400px) {
    padding-top: 20%;
    padding-bottom: 20%;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  flex-direction: ${({ imgStart }) => (imgStart ? "column-reverse" : "column")};

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

export const StartColumn = styled.div`
  //flex-basis: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextContent = styled.div`
  text-align: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 36px;
    }
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 36px;
      padding: 0;
    }
  }
`;

export const Title = styled.h1`
  margin-bottom: 32px;
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.2px;
  color: ${({ lightText }) => (lightText ? "#444444" : "#1c2237")};
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.2rem;
    width: 100%;
  }
`;
