import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
//import * as Yup from "yup";
import {
  Container,
  Content,
  Title,
  FormContainer,
  ButtonsContainer,
  ContainerGeral,
  DateContainer,
  ContainerInterno,
  HeaderContainer,
  MarketPlace,
  /*  ContainerInterno1,
  ContainerInterno2, */
} from "./CadastraAcao.elements";
import HeaderAfterLogin from "../../../components/Header/HeaderAfterLogin";
//import { useParams } from "react-router-dom";
import { ButtonLogin } from "../../../globalStyles";
import { useHistory } from "react-router";
import _ from "lodash";
import sortArray from "sort-array";
import { formateDate } from "../../../utils/formatDate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarRange from "../../../components/Modulo2/react_day_picker";
/* import DateFnsAdapter from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker"; */
import Calendar from "../../../components/Modulo2/calendar";

import api from "../../../services/api";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import TabsUnstyled from "@mui/base/TabsUnstyled";

import "rsuite/styles/index.less"; // or 'rsuite/dist/rsuite.min.css'

const TemasTitle = {
  ambiental: "Ambiental",
  economico: "Econômico",
  governanca: "Governança",
  social: "Social",
};

const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

// Periodicidade da Avaliação
const listAvaliacao = [
  { value: "", name: "-- Selecione a periodicidade --" },
  { value: "Semestral", name: "Semestral" },
  { value: "Bimestral", name: "Bimestral" },
  { value: "Trimestral", name: "Trimestral" },
  { value: "Mensal", name: "Mensal" },
  { value: "Anual", name: "Anual" },
];

const listImplementacao = [
  { value: "", name: "-- Selecione como deseja implementar sua ação --" },
  { value: "Equipe interna", name: "Equipe interna" },
  { value: "Equipe externa/consultor", name: "Equipe externa/consultor" },
];

//Função para separação em abas
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Montserrat",
  textDecoration: "none",
  color: "#333333",
  "&.MuiTab-root:hover, &.MuiTab-root, &.MuiTab-root:active, &.MuiTab-root:link, &.MuiTab-root:visited, &span.MuiTabs-indicator":
    {
      color: "#333333",
      backgroundColor: "transparent",
      fontWeight: theme.typography.fontWeightMedium,
    },
}));

//Fim da função para separação em abas

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOnFirst: true, isToggleOnSecond: true };
    this.handleClickFirst = this.handleClickFirst.bind(this);
    this.handleCloseFirstCalendar = this.handleCloseFirstCalendar.bind(this);
    this.handleClickSecond = this.handleClickSecond.bind(this);
  }

  handleClickFirst() {
    this.setState((prevState) => ({
      isToggleOnFirst: !prevState.isToggleOnFirst,
    }));
  }

  handleCloseFirstCalendar() {
    this.setState((prevState) => ({
      isToggleOnFirst: true,
      isToggleOnSecond: true,
    }));
  }

  handleClickSecond() {
    this.setState((prevState) => ({
      isToggleOnSecond: !prevState.isToggleOnSecond,
    }));
  }

  fecharCalendarioFirst() {
    return this.props.selectedSingleDate
      ? formateDate(new Date(this.props.selectedSingleDate).toISOString())
      : "-- Selecione o período de avaliação --";
  }

  fecharCalendarioSecond() {
    return this.props.dataStart
      ? formateDate(new Date(this.props.dataEnd).toISOString())
      : "-- Selecione o período de avaliação --";
  }

  abrirCalendario({ single, first }) {
    return (
      <CalendarRange
        setDataStart={
          first ? this.props.setSelectedSingleDate : this.props.setDataStart
        }
        setDataEnd={this.props.setDataEnd}
        single={single}
        setSelectedSingleDate={this.props.setSelectedSingleDate}
        selectedSingleDate={this.props.selectedSingleDate}
        setFirstToggle={this.handleCloseFirstCalendar}
        firstDate={this.props.dataStart}
        disabledDays={[
          {
            before: new Date(),
          },
        ]}
      />
    );
  }

  render() {
    return (
      <>
        <div className="calendarContainer">
          <div className="calendarDiv">
            <label htmlFor="dataStart">
              Data inicial da avaliação <span style={{ color: "red" }}>*</span>
            </label>
            <div className="toggleContainer">
              <div
                className="calendario"
                style={{
                  background: "#eeeeee",
                  borderRadius: "10px 0 0 10px",
                  height: "50px",
                  padding: "15px",
                  color: "#333",
                  margin: "0 0 10px",
                  paddingRight: "10px",
                  fontSize: "16px",
                }}
              >
                {this.state.isToggleOnFirst
                  ? this.fecharCalendarioFirst()
                  : this.abrirCalendario({ single: true, first: true })}
              </div>
              <div className="calendarioIcon" onClick={this.handleClickFirst}>
                <ExpandMoreIcon style={{ justifyContent: "center" }} />
              </div>
            </div>
          </div>
          {/* <CalendarRange
              setDataStart={this.props.setDataStart}
              setDataEnd={this.props.setDataEnd}
            /> */}

          <div className="calendarDiv">
            <label htmlFor="dataEnd">
              Data final da avaliação <span style={{ color: "red" }}>*</span>
            </label>
            <div className="toggleContainer">
              <div
                className="calendario"
                style={{
                  background: "#eeeeee",
                  borderRadius: "10px 0 0 10px",
                  height: "50px",
                  padding: "15px",
                  color: "#333",
                  margin: "0 0 10px",
                  paddingRight: "10px",
                  fontSize: "16px",
                }}
              >
                {this.state.isToggleOnSecond
                  ? this.fecharCalendarioSecond()
                  : this.abrirCalendario({ single: false, first: false })}
              </div>
              <div
                className="calendarioIcon"
                onClick={
                  !this.props.selectedSingleDate ? null : this.handleClickSecond
                }
              >
                <ExpandMoreIcon style={{ justifyContent: "center" }} disabled />
              </div>
            </div>
          </div>
          {/* <CalendarRange
              setDataStart={this.props.setDataStart}
              setDataEnd={this.props.setDataEnd}
            /> */}
        </div>
      </>
    );
  }
}

export const CadastraAcao = () => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [temas, setTemas] = useState();
  const [description, setDescription] = useState("");
  const [themeAction, setThemeAction] = useState("");
  const [indicatorAction, setIndicatorAction] = useState("");
  const [dataStart, setDataStart] = useState("");
  const [selectedSingleDate, setSelectedSingleDate] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [firstCalendarDate, setFirstCalendarDate] = useState("");
  const [periodicity, setPeriodicity] = useState("");
  const [nameResponsible, setNameResponsible] = useState("");
  const [emailResponsible, setEmailResponsible] = useState("");
  const [phoneResponsible, setPhoneResponsible] = useState("");
  const [cargoResponsible, setCargoResponsible] = useState("");
  const [sectorResponsible, setSectorResponsible] = useState("");
  const [implementaAcao, setImplementaAcao] = useState("");
  const [phone, setPhone] = useState("");
  const [listIndicador, setListIndicador] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const performances = history.location.state;

  if (_.keys(performances).length === 0) {
    history.push("/resultado-score-final");
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    api.get("categories").then(({ data }) => {
      setTemas(data);
    });
  }, []);

  //const [loading, setLoading] = useState(false);
  const IDcompany = localStorage.getItem("@legaro:companyID");

  async function handleSubmit() {
    setLoading(true);

    if (
      !title ||
      !themeAction ||
      !indicatorAction ||
      !dataStart ||
      !dataEnd ||
      !periodicity ||
      !nameResponsible ||
      !emailResponsible ||
      !phoneResponsible ||
      !cargoResponsible ||
      !sectorResponsible ||
      !implementaAcao
    ) {
      return toast.error("É necessário preencher todos os campos!");
    }

    try {
      const category = temas.find(
        (tema) => tema.title.toLowerCase() === themeAction
      );

      console.log({ category, indicatorAction });

      const { data } = await api.post("indicator/filter", {
        categoryId: String(category._id),
        title: String(indicatorAction),
      });

      const response = await api.post(`/action-plans/`, {
        title,
        description,
        themeAction: category._id,
        indicatorAction: data._id,
        dataStart,
        dataEnd,
        periodicity,
        nameResponsible,
        emailResponsible,
        phoneResponsible,
        cargoResponsible,
        sectorResponsible,
        implementaAcao,
        company: IDcompany,
      });

      toast.success("Ação cadastrada com sucesso! 😃");
      setLoading(false);
      history.push({
        pathname: `/plano-acao/${IDcompany}`,
        state: performances,
      });
      // navigation.next();
    } catch (error) {
      //console.log("ERRO", error);
      return toast.error("Erro ao cadastrar esta ação");
    }
  }

  const submitCadastro = async () => {
    try {
      toast.success("Alterações salvas com sucesso! 😃");

      history.push({
        pathname: `/plano-acao/${IDcompany}`,
        state: performances,
      });
    } catch (error) {
      toast.error("Não foi possível salvar as alterações, tente novamente!");
    }
  };

  const cancelarCadastro = () => {
    history.push(`/plano-acao/${IDcompany}`);
  };

  const handlePhoneChange = (e) => {
    setPhoneResponsible(maskPhone(e.target.value));
  };

  /* const abrirCalendario = () => {
    return (document.getElementById("calendarRange").style.display = "flex");
  };

  const fecharCalendario = () => {
    return (document.getElementById("calendarRange").style.display = "none");
  };
 */
  return (
    <>
      <HeaderAfterLogin />
      <ContainerGeral>
        <Container>
          <HeaderContainer>
            <Title>Cadastre uma ação</Title>
            {/* <MarketPlace>
              <p>Precisando de ajuda?</p>
            </MarketPlace> */}
          </HeaderContainer>
          <Content>
            <FormContainer>
              <Form onSubmit={handleSubmit} className="form-container">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabsUnstyled defaultValue={0}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        selectionFollowsFocus
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#6fa824",
                            textColor: "green",
                          },
                        }}
                      >
                        <AntTabs label="Dados da Ação" {...a11yProps(0)} />
                        <AntTabs
                          label="Dados do Responsável"
                          {...a11yProps(1)}
                          style={{ marginLeft: "1%" }}
                        />
                      </Tabs>
                    </TabsUnstyled>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <ContainerInterno>
                      <label htmlFor="title">
                        Título da ação <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        className="form-input"
                        name="title"
                        type="text"
                        required
                        minLength="8"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <label htmlFor="description">Descrição</label>
                      <textarea
                        name="description"
                        type="text"
                        className="input-descricao"
                        style={{ height: "130px" }}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <label htmlFor="themeAction">
                        Tema <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="themeAction"
                        className="TemaInput"
                        required
                        value={themeAction}
                        onChange={(e) => {
                          setThemeAction(e.target.value);
                          let myIndicators = [];
                          _.keys(performances[e.target.value]).forEach(
                            (ind) => {
                              myIndicators.push({
                                name: ind,
                                value: performances[e.target.value][ind],
                              });
                            }
                          );

                          setListIndicador(
                            sortArray(myIndicators, {
                              by: "value",
                              order: "asc",
                            })
                          );
                        }}
                      >
                        {themeAction.length === 0 && (
                          <option>-- Selecione o tema --</option>
                        )}
                        {_.keys(performances).map((option, index) => (
                          <option key={index} value={option}>
                            {TemasTitle[option]}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="indicatorAction">
                        Indicador <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="indicatorAction"
                        required
                        value={indicatorAction}
                        onChange={(e) => setIndicatorAction(e.target.value)}
                      >
                        {indicatorAction.length === 0 && (
                          <option>-- Selecione o indicador --</option>
                        )}
                        {listIndicador.map((option, index) => (
                          <option key={index} value={option.name}>
                            {option.name} - {option.value}%
                          </option>
                        ))}
                      </select>
                      <label htmlFor="periodicity">
                        Periodicidade da avaliação{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="periodicity"
                        value={periodicity}
                        required
                        onChange={(e) => setPeriodicity(e.target.value)}
                      >
                        {listAvaliacao.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="implementaAcao">
                        Como deseja implementar suas ações?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        name="implementaAcao"
                        required
                        value={implementaAcao}
                        onChange={(e) => setImplementaAcao(e.target.value)}
                      >
                        {listImplementacao.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                        ))
                      </select>
                      <Toggle
                        setDataStart={setDataStart}
                        setDataEnd={setDataEnd}
                        dataStart={dataStart}
                        dataEnd={dataEnd}
                        setSelectedSingleDate={setSelectedSingleDate}
                        selectedSingleDate={selectedSingleDate}
                        setFirstCalendarDate={setFirstCalendarDate}
                        firstCalendarDate={firstCalendarDate}
                      />
                    </ContainerInterno>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <ContainerInterno>
                      <label htmlFor="nameResponsible">
                        Responsável <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="nameResponsible"
                        className="form-responsible"
                        type="text"
                        required
                        minLength="8"
                        value={nameResponsible}
                        onChange={(e) => setNameResponsible(e.target.value)}
                      />
                      <label htmlFor="emailResponsible">
                        E-mail <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="emailResponsible"
                        className="form-email"
                        type="email"
                        required
                        minLength="8"
                        value={emailResponsible}
                        onChange={(e) => setEmailResponsible(e.target.value)}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      />
                      <label htmlFor="phoneResponsible">
                        Telefone <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="phoneResponsible"
                        className="form-phone"
                        type="phone"
                        required
                        minlength="14"
                        maxlength="15"
                        value={phoneResponsible}
                        onChange={handlePhoneChange}
                      />
                      <label htmlFor="cargoResponsible">
                        Cargo <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="cargoResponsible"
                        className="form-cargo"
                        type="text"
                        required
                        minLength="8"
                        value={cargoResponsible}
                        onChange={(e) => setCargoResponsible(e.target.value)}
                      />
                      <label htmlFor="sectorResponsible">
                        Setor <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="sectorResponsible"
                        type="text"
                        className="form-sector"
                        required
                        minLength="8"
                        value={sectorResponsible}
                        onChange={(e) => setSectorResponsible(e.target.value)}
                      />
                    </ContainerInterno>
                  </TabPanel>
                </Box>
              </Form>
            </FormContainer>
          </Content>
          <div className="divButton">
            <hr className="divHr" />
            <ButtonsContainer>
              <ButtonLogin
                type="submit"
                className="btn-cadastro"
                onClick={handleSubmit}
              >
                {loading ? (
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-spinner fa-pulse"
                  />
                ) : (
                  "cadastrar"
                )}
              </ButtonLogin>
              <ButtonLogin
                className="btn-cancelar"
                type="submit"
                onClick={cancelarCadastro}
              >
                cancelar
              </ButtonLogin>
            </ButtonsContainer>
          </div>
        </Container>
      </ContainerGeral>
    </>
  );
};

export default CadastraAcao;
