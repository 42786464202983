import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CustomizedMenus from "./menu";
import _ from "lodash";

import { FooterAfterLogin } from "../../../components";

import { useAuth } from "../../../hooks/auth";
import api from "../../../services/api";

import { useHistory, useParams } from "react-router-dom";
import HeaderAfterLogin from "../../../components/Header/HeaderAfterLogin";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { Container, ButtonLogin } from "../../../globalStyles";
import {
  TitleDashboard,
  ButtonDashboard,
  TableDashboard,
  NavLinks,
} from "./PlanoAcao.element";
import { formateDate, verifyLimit } from "../../../utils/formatDate";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";

const TemasTitle = {
  Ambiental: "Ambiental",
  Economico: "Econômico",
  Governanca: "Governança",
  Social: "Social",
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

//DISCRETE SLIDER

const useStyles = makeStyles((theme) => ({
  root: {
    width: 120 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 0,
    /* label: "0%", */
  },
  {
    value: 100,
    /* label: "100%", */
  },
];

const IOSSlider = withStyles({
  root: {
    color: "#6fa824",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 5px)",
    top: 32,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 3,
  },
  rail: {
    height: 1,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

//DISCRETE SLIDER

const RowIndicator = ({ ind }) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  /* const classes = useStyles(); */
  const [value, setValue] = React.useState();

  function valueLabelFormat(value) {
    return `${value}%`;
  }

  const handleSliderChange = async (event, newValue, id) => {
    setValue(newValue);

    try {
      const response = await api.put(`action-plans/${id}`, {
        status: newValue,
      });

      /* toast.success("Seu progresso foi atualizado 😄", {
        position: "bottom-center",
      }); */
    } catch (error) {
      toast.error("Não foi possível atualizar seu progresso", {
        position: "bottom-center",
      });
    }
  };

  function SituationStatus(status) {
    if (status === -1) {
      return <div style={{ fontWeight: "bold" }}>Em atraso</div>;
    }
    if (status === 0) {
      return <div style={{ fontWeight: "bold" }}>Não iniciado</div>;
    }
    if (status === 100) {
      return <div style={{ fontWeight: "bold" }}>Concluído</div>;
    }
    if (status > 0 || status < 100) {
      return <div style={{ fontWeight: "bold" }}>Em andamento</div>;
    }
    /* verifyDate(); */
  }

  function newSituationStatus() {
    if (value === 0) {
      return <div style={{ fontWeight: "bold" }}>Não iniciado</div>;
    }
    if (value === 100) {
      return <div style={{ fontWeight: "bold" }}>Concluído</div>;
    }
    if (value > 0 || value < 100) {
      return <div style={{ fontWeight: "bold" }}>Em andamento</div>;
    }
    /* verifyDate(); */
  }

  function verifyDate() {
    var prazoDate = document.getElementById("fim").valueAsDate;
    var todayDate = new Date();
    // ajusta o horário para meia-noite em UTC
    todayDate.setUTCHours(0, 0, 0, 0);
    if (todayDate > prazoDate) {
      alert("Em atraso!");
    } else {
      alert("Ok");
    }
  }

  return (
    <div style={{ marginLeft: "35px" }}>
      <span>{ind[0]?.indicatorAction?.title}</span>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            style={{ color: "#6FA824" }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {
        <TableRow className={classes.root}>
          <TableCell></TableCell>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="90%">
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box style={{ marginRight: "35px", width: "1000px" }}>
                <Table aria-label="purchases" style={{ marginBottom: "35px" }}>
                  <TableHead colSpan="90%">
                    <TableRow>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell component="th" scope="row">
                        Avaliação
                      </TableCell>
                      <TableCell align="left" scope="col"></TableCell>
                      <TableCell align="left" scope="col">
                        Prazo
                      </TableCell>
                      <TableCell align="left" scope="col"></TableCell>
                      <TableCell align="left" scope="col">
                        Responsável
                      </TableCell>
                      <TableCell align="left" scope="col"></TableCell>
                      <TableCell align="left" scope="col">
                        Situação
                      </TableCell>
                      <TableCell align="left" scope="col"></TableCell>
                      <TableCell align="left" scope="col">
                        Progresso
                      </TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  {ind.map((indicator, index) => {
                    return (
                      <TableBody colSpan="90%">
                        <TableCell align="left">{indicator.title}</TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {indicator.periodicity}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {formateDate(indicator.dataEnd)}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {indicator.nameResponsible}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {newSituationStatus() ||
                            SituationStatus(
                              verifyLimit(indicator.dataEnd)
                                ? -1
                                : indicator.status
                            )}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          <div>
                            <div className={classes.root}>
                              <IOSSlider
                                aria-label="ios slider"
                                defaultValue={indicator.status}
                                marks={marks}
                                valueLabelDisplay="on"
                                getAriaValueText={valueLabelFormat}
                                valueLabelFormat={valueLabelFormat}
                                onChange={(e, event) =>
                                  handleSliderChange(e, event, indicator._id)
                                }
                                step={10}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left" className="ExportUserTable">
                          <NavLinks
                            id="ExportUser"
                            style={{
                              cursor: "default",
                            }}
                          >
                            <CustomizedMenus id={indicator._id} />
                          </NavLinks>
                        </TableCell>
                      </TableBody>
                    );
                  })}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </div>
  );
};

function Row({ row }) {
  return (
    <>
      <TableRow
        style={{
          border: "none",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TableCell style={{ border: "none" }}></TableCell>
        <TableCell
          style={{
            fontWeight: "bold",
            border: "none",
            fontSize: "18px",
            padding: 0,
            paddingBottom: "20px",
          }}
          component="th"
          scope="row"
        >
          Tema {TemasTitle[row.tema]}
        </TableCell>
      </TableRow>
      <div>
        {row.indicators.map((ind, i) => {
          return <RowIndicator key={i} ind={ind} />;
        })}
      </div>
    </>
  );
}

function RowTable({ data }) {
  return (
    <>
      {_.keys(data).map((tema, index) => (
        <Row
          key={index}
          row={{
            tema,
            indicators: data[tema],
          }}
        />
      ))}
    </>
  );
}

export default function CollapsibleTable() {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [onPrint, setOnPrint] = React.useState(false);
  const componentRef = useRef();
  const params = useParams();
  const history = useHistory();

  const perfomancesIndicatores = history.location.state;

  const IDcompany = localStorage.getItem("@legaro:companyID");

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`action-plans/filter/${params.companyId}`);

      setLoading(false);
      setData(response.data);
    }
    loadData();
  }, [params.companyId]);

  const goToCadastroAcao = () => {
    history.push({
      pathname: `/cadastrar-acao/${IDcompany}`,
      state: perfomancesIndicatores,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const exportTrigger = (setOpen) => {
    setTimeout(function () {
      //Zoom da tela
      var initialValue = document.getElementById("export-page");
      initialValue.style.zoom = "60%";

      //Botões exportar e compartilhar
      var buttonExport = document.getElementById("button-export");
      buttonExport.style.display = "none";
      var buttonCadastro = document.getElementById("button-cadastro");
      buttonCadastro.style.display = "none";

      //Alterções na HeaderAfterLogin
      var exportAjuda = document.getElementById("ExportAjuda");
      exportAjuda.style.display = "none";
      var exportPainel = document.getElementById("ExportPainel");
      exportPainel.style.display = "none";
      var exportUser = document.getElementById("ExportUser");
      exportUser.style.display = "none";

      //Alterações no FooterAfterLogin
      document.getElementById("FooterExport").style.display = "block";
      document.getElementById("footerExportLinksContainer").style.display =
        "none";
      document.getElementById("footerExportLContainer").style.marginTop =
        "300px";
      document.getElementById("ExportText").style.fontSize = "10px";

      handlePrint();
      setOnPrint(true);
      setTimeout(() => {
        //Zoom da tela
        var initialValue = document.getElementById("export-page");
        initialValue.style.zoom = "100%";

        //Botões exportar e compartilhar
        var buttonExport = document.getElementById("button-export");
        buttonExport.style.display = "block";
        var buttonCadastro = document.getElementById("button-cadastro");
        buttonCadastro.style.display = "block";

        //Alterções na HeaderAfterLogin
        var exportAjuda = document.getElementById("ExportAjuda");
        exportAjuda.style.display = "block";
        var exportPainel = document.getElementById("ExportPainel");
        exportPainel.style.display = "block";
        var exportUser = document.getElementById("ExportUser");
        exportUser.style.display = "block";

        //Alterações no FooterAfterLogin
        document.getElementById("FooterExport").style.display = "none";
        document.getElementById("footerExportLinksContainer").style.display =
          "flex";
        document.getElementById("footerExportLContainer").style.marginTop = "0";
        document.getElementById("ExportText").style.fontSize = "14px";

        setOnPrint(false);
      }, 600);
    }, 600);
  };

  return (
    <div ref={componentRef} id="export-page">
      <HeaderAfterLogin />
      <Container>
        <TitleDashboard>
          <h3 className="card-title">Plano de Ação</h3>
        </TitleDashboard>
        <ButtonDashboard>
          <ButtonLogin
            id="button-export"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #808080",
              color: "#808080",
            }}
            onClick={() => {
              exportTrigger();
            }}
          >
            <i class="fas fa-download"></i> exportar
          </ButtonLogin>
          <ButtonLogin
            id="button-cadastro"
            onClick={() => goToCadastroAcao()}
            style={{
              backgroundColor: "#6FA824",
              border: "1px solid #6FA824",
              color: "#fff",
              marginLeft: "20px",
            }}
          >
            <i className="fas fa-plus"></i> adicionar ação
          </ButtonLogin>
        </ButtonDashboard>

        <TableDashboard>
          <TableContainer component={Paper} style={{ border: "none" }}>
            <RowTable data={data} />
          </TableContainer>
        </TableDashboard>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Carregando as ações</span>
          </div>
        )}
      </Container>
      <FooterAfterLogin />
    </div>
  );
}
