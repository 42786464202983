import styled from "styled-components";
import { Link } from "react-router-dom";

export const IndicaterContainer = styled.div`
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  background: #3a4046;
  font-family: "Montserrat", sans-serif;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }
`;

export const IndicaterSession = styled.div`
  padding: 60px 0 70px;
  display: flex;
  flex-direction: column;
  background: #3a4046;

  h2 {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    margin-left: 8%;
    text-align: left;
    margin-bottom: 3%;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
  }

  p {
    font-family: "Montserrat", sans-serif;
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 25px;
      padding-bottom: 20px;
    }

    p {
      font-size: 20px;
      padding-bottom: 20px;
    }
  }
`;

export const IndicaterPartner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  p {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  img {
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 400px) {
    margin: 0;
    padding: 0;

    p {
      padding-bottom: 30px;
      margin-top: 18%;
      justify-content: center;
    }
  }
`;

export const IndicaterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem auto;
  width: 100%;

  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 1150px) {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const IndicaterCard = styled.div`
  //background: #242424;
  //box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 100%;
  height: 275px;
  text-decoration: none;
  border-radius: 4px;
  cursor: auto;
  margin-right: 5%;

  //border: 1px solid red;

  &:nth-child(2) {
    margin: 24px;
  }

  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
    height: auto;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const IndicaterInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  color: #fff;

  h1 {
    font-size: 120px;
    font-weight: bold;
  }

  p {
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    padding: 0;
    margin: 0;

    h1 {
      padding: 0;
      margin: 0;
      font-size: 90px;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 1000px) {
    p {
      text-align: center;
    }
  }
`;
