import React, { useState, useEffect } from "react";
//import { FiArrowLeft, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
//import * as Yup from "yup";
//import { useSelector } from 'react-redux';
import api from "../../services/api";
import RecoverPassword from "../../template/SignUpText/recoverPassword";
import { useAuth } from "../../hooks/auth";
import jwt from "jsonwebtoken";

import {
  Container,
  Background,
  Content,
  ModalImg,
  CloseWindow,
  Title,
  Description,
} from "./Style.Elements";

function PasswordRecover() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const token = history.location.search.split("=")[1];
  const [federation, setFederation] = useState();

  const loadFederation = async () => {
    try {
      setLoading(true);

      const decoded = token
        ? await jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
        : null;

      const federationId = decoded?.federationId || user?.federationId;
      const { data } = await api.get(`/federations/${federationId}`);
      setFederation(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadFederation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit({ email }) {
    try {
      setLoading(true);
      await api.post("recover", {
        email,
      });
      setLoading(false);
      toast.success(`Enviamos um e-mail de recuperação de  senha 😎`);
      history.push("sign-in");
    } catch (error) {
      setLoading(false);
      toast.error(
        "Não foi possível enviar o e-mail de recuperação de senha 😢"
      );
    }
  }

  return (
    <Container>
      {/* <CloseWindow>
        <Link to="/">x</Link>
      </CloseWindow>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          className="lazy"
        />
      </Background> */}
      <Background>
        <RecoverPassword />
      </Background>
      <Content>
        <Title>Recuperação de senha</Title>
        <Description>
          Informe o e-mail cadastrado e lhe enviaremos um link com as instruções
          para recuperar seu acesso.
        </Description>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="email">
            E-mail<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input
            name="email"
            id="email"
            className="input_formulario"
            type="email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
          <button type="submit">
            {loading ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "ENVIAR"
            )}
          </button>
          {/* <Link to="/sign-in">Cancelar</Link> */}
          {!loading && federation ? (
            <Link to={`/sign-in?query=${token}`}>Cancelar</Link>
          ) : (
            <div style={{ display: "flex" }}>
              <Link
                to="/sign-in"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px auto",
                  textDecoration: "none",
                }}
              >
                Cancelar
              </Link>
            </div>
          )}
        </Form>
      </Content>
    </Container>
  );
}

export default PasswordRecover;
