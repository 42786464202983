import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
//import * as Yup from "yup";
import { Container, Content, Title } from "./Edit.elements";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import { useParams } from "react-router-dom";
import api from "../../services/api";

// Validation
//const schema = Yup.object().shape({
//marginname: Yup.string().required("Nome é obrigatório"),
//cnpj: Yup.string().required("CNPJ é obrigatório"),
// state: Yup.string().required("Estado é obrigatório"),
// city: Yup.string().required("Município é obrigatório"),
// sector: Yup.string().required("Setor é obrigatória"),
// segment: Yup.string().required("Segmento é obrigatória"),
//});

// Sectors
const SectorOptions = [
  { value: "", name: "Selecione o setor" },
  { value: "Instituições financeiras", name: "Instituições financeiras" },
  {
    value: "Instituições não financeiras",
    name: "Instituições não financeiras",
  },
];

// Partners
const PartnerOptions = [
  { value: "", name: "Selecione" },
  { value: "De 1 a 19", name: "De 1 a 19" },
  {
    value: "De 20 a 99",
    name: "De 20 a 99",
  },
  {
    value: "De 100 a 499",
    name: "De 100 a 499",
  },
  {
    value: "Mais de 500",
    name: "Mais de 500",
  },
];

// Revenues
const RevenueOptions = [
  { value: "", name: "Selecione" },
  { value: "Até R$ 360 mil", name: "Até R$ 360 mil" },
  {
    value: "De R$ 360 mil até R$ 4,8 milhões",
    name: "De R$ 360 mil até R$ 4,8 milhões",
  },
  {
    value: "De R$ 4,8 milhões até R$ 300 milhões",
    name: "De R$ 4,8 milhões até R$ 300 milhões",
  },
  {
    value: "Mais que R$ 300 milhões",
    name: "Mais que R$ 300 milhões",
  },
];

// Segments
const SegmentOptions = [
  { value: "", name: "Selecione o segmento" },
  { value: "Acessórios", name: "Acessórios" },
  { value: "Açúcar e alcool", name: "Açúcar e alcool" },
  {
    value: "Agricultura e pecuária (atividades produtivas)",
    name: "Agricultura e pecuária (atividades produtivas)",
  },
  { value: "Água e saneamento", name: "Água e saneamento" },
  {
    value: "Alimentos diversos (ativ. processamento e beneficiamento)",
    name: "Alimentos diversos (ativ. processamento e beneficiamento)",
  },
  { value: "Aluguel de carros", name: "Aluguel de carros" },
  { value: "Armas e munições", name: "Armas e munições" },
  { value: "Artefatos de cobre", name: "Artefatos de cobre" },
  { value: "Automóveis e motocicletas", name: "Automóveis e motocicletas" },
  { value: "Bancos", name: "Bancos" },
  { value: "Brinquedos e jogos", name: "Brinquedos e jogos" },
  { value: "Calçados", name: "Calçados" },
  { value: "Cervejas e refrigerantes", name: "Cervejas e refrigerantes" },
  { value: "Cigarros e fumo", name: "Cigarros e fumo" },
  {
    value: "Comercialização de medicamentos",
    name: "Comercialização de medicamentos",
  },
  {
    value: "Comércio de alimentos, livrarias e papelarias",
    name: "Comércio de alimentos, livrarias e papelarias",
  },
  {
    value: "Comércio de máquinas e equipamentos",
    name: "Comércio de máquinas e equipamentos",
  },
  {
    value: "Comércio de material de transporte",
    name: "Comércio de material de transporte",
  },
  {
    value: "Comércio de tecidos, vestuário e calçados",
    name: "Comércio de tecidos, vestuário e calçados",
  },
  {
    value: "Computadores e equipamentos",
    name: "Computadores e equipamentos",
  },
  {
    value: "Construção civil",
    name: "Construção civil",
  },
  {
    value: "Construção pesada",
    name: "Construção pesada",
  },
  {
    value: "Couro (processamento e beneficiamento)",
    name: "Couro (processamento e beneficiamento)",
  },
  {
    value: "Defensivos",
    name: "Defensivos",
  },
  {
    value: "Distribuição de combustíveis e gás",
    name: "Distribuição de combustíveis e gás",
  },
  {
    value: "Distribuição de energia elétrica",
    name: "Distribuição de energia elétrica",
  },
  {
    value: "Eletrodomésticos",
    name: "Eletrodomésticos",
  },
  {
    value: "Embalagens",
    name: "Embalagens",
  },
  {
    value: "Energia elétrica (geração e transmissão)",
    name: "Energia elétrica (geração e transmissão)",
  },
  {
    value: "Engenharia consultiva",
    name: "Engenharia consultiva",
  },
  {
    value: "Equipamentos elétricos",
    name: "Equipamentos elétricos",
  },
  {
    value: "Exploração de imóveis",
    name: "Exploração de imóveis",
  },
  {
    value: "Exploração de rodovias",
    name: "Exploração de rodovias",
  },
  {
    value: "Ferro e aço",
    name: "Ferro e aço",
  },
  {
    value: "Fertilizantes",
    name: "Fertilizantes",
  },
  {
    value: "Fios e tecidos",
    name: "Fios e tecidos",
  },
  {
    value: "Holdings diversificadas",
    name: "Holdings diversificadas",
  },
  {
    value: "Hotelaria",
    name: "Hotelaria",
  },
  {
    value: "Intermediação imobiliária",
    name: "Intermediação imobiliária",
  },
  {
    value: "Jornais, livros e revistas",
    name: "Jornais, livros e revistas",
  },
  {
    value: "Laticínios",
    name: "Laticínios",
  },
  {
    value: "Madeira, papel e celulose",
    name: "Madeira, papel e celulose",
  },
  {
    value: "Máquinas e equipamentos agrícolas e de transporte",
    name: "Máquinas e equipamentos agrícolas e de transporte",
  },
  {
    value: "Máquinas e equipamentos hospitalares",
    name: "Máquinas e equipamentos hospitalares",
  },
  {
    value: "Máquinas e equipamentos industriais",
    name: "Máquinas e equipamentos industriais",
  },
  {
    value: "Materiais de construção",
    name: "Materiais de construção",
  },
  {
    value: "Materiais diversos",
    name: "Materiais diversos",
  },
  {
    value: "Material aeronáutico",
    name: "Material aeronáutico",
  },
  {
    value: "Material ferroviário",
    name: "Material ferroviário",
  },
  {
    value: "Material rodoviário",
    name: "Material rodoviário",
  },
  {
    value: "Minerais metálicos",
    name: "Minerais metálicos",
  },
  {
    value: "Montadora de bicicletas",
    name: "Montadora de bicicletas",
  },
  {
    value: "Motores, compressores e outros",
    name: "Motores, compressores e outros",
  },
  {
    value: "Parques de diversão",
    name: "Parques de diversão",
  },
  {
    value: "Petróleo e gás (exploração e/ou refino)",
    name: "Petróleo e gás (exploração e/ou refino)",
  },
  {
    value: "Petroquímicos",
    name: "Petroquímicos",
  },
  {
    value: "Produção de medicamentos",
    name: "Produção de medicamentos",
  },
  {
    value: "Produtos de limpeza",
    name: "Produtos de limpeza",
  },
  {
    value: "Produtos de uso pessoal",
    name: "Produtos de uso pessoal",
  },
  {
    value: "Produtos diversos",
    name: "Produtos diversos",
  },
  {
    value: "Ração animal",
    name: "Ração animal",
  },
  {
    value: "Seguradoras",
    name: "Seguradoras",
  },
  {
    value: "Serviços de apoio e armazenagem",
    name: "Serviços de apoio e armazenagem",
  },
  {
    value: "Serviços diversos",
    name: "Serviços diversos",
  },
  {
    value: "Serviços educacionais",
    name: "Serviços educacionais",
  },
  {
    value: "Serviços financeiros diversos",
    name: "Serviços financeiros diversos",
  },
  {
    value: "Serviços médicos (hospitalar, análises e diagnósticos)",
    name: "Serviços médicos (hospitalar, análises e diagnósticos)",
  },
  {
    value: "Siderurgia",
    name: "Siderurgia",
  },
  {
    value: "Soc. crédito e financiamento",
    name: "Soc. crédito e financiamento",
  },
  {
    value: "Tecnologia da informação",
    name: "Tecnologia da informação",
  },
  {
    value: "Telefonia fixa",
    name: "Telefonia fixa",
  },
  {
    value: "Telefonia móvel",
    name: "Telefonia móvel",
  },
  {
    value: "Televisão por assinatura",
    name: "Televisão por assinatura",
  },
  {
    value: "Transporte aéreo",
    name: "Transporte aéreo",
  },
  {
    value: "Transporte ferroviário",
    name: "Transporte ferroviário",
  },
  {
    value: "Transporte hidroviário",
    name: "Transporte hidroviário",
  },
  {
    value: "Transporte rodoviário",
    name: "Transporte rodoviário",
  },
  {
    value: "Utensílios domésticos",
    name: "Utensílios domésticos",
  },
  {
    value: "Vestuário",
    name: "Vestuário",
  },
  {
    value: "Outros",
    name: "Outros",
  },
];

const maskCNPJ = (value) => {
  return value
    .replace(/\D/g, "") //Remove tudo o que não é dígito
    .replace(/^(\d{2})(\d)/, "$1.$2") //Coloca ponto entre o segundo e o terceiro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, ".$1/$2") //Coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
};

export const Company = () => {
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [listUf, setListUf] = useState([]);
  const [uf, setUf] = useState("");
  const [listCity, setListCity] = useState([]);
  const [city, setCity] = useState("");
  const [sector, setSector] = useState("");
  const [segment, setSegment] = useState("");
  const [partner, setPartner] = useState("");
  const [revenue, setRevenue] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    async function getCompany() {
      setLoading(true);
      var response = await api.get(`companies/${id}`);
      //console.log('EMPRESA: ', response.data.company)

      setName(response.data.company.name);
      setCnpj(response.data.company.cnpj);
      setUf(response.data.company.state);
      setCity(response.data.company.city);
      setSector(response.data.company.sector);
      setSegment(response.data.company.segment);
      setPartner(response.data.company.numberColabs);
      setRevenue(response.data.company.annualBilling);
      setLoading(false);
    }
    getCompany();
  }, [id]);

  function loadUf() {
    let url = "https://servicodados.ibge.gov.br/";
    url += "api/v1/localidades/estados";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.nome.localeCompare(b.nome));
        console.log({ uf: data });
        setListUf([{ sigla: "", nome: "Selecione o estado" }, ...data]);
      });
  }
  function loadCity(id) {
    const codeUf = listUf.find((f) => {
      return f.nome === id;
    });

    console.log({ codeUf });

    let url = "https://servicodados.ibge.gov.br/api/v1/";
    url += `localidades/estados/${codeUf.id}/municipios`;
    console.log({ idCity: id });
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.nome.localeCompare(b.nome));
        console.log({ city: data });
        setListCity([{ sigla: "", nome: "Selecione o município" }, ...data]);
      })
      .catch((e) => console.log("Error loading city:", e));
  }
  React.useEffect(() => {
    loadUf();
  }, []);
  React.useEffect(() => {
    if (uf) {
      loadCity(uf);
      console.log("chegou aqui", uf);
    }
  }, [uf]);

  async function handleSubmit({ name, cnpj }) {
    const estado = listUf.find((std) => std.nome === uf);
    console.log({ name, cnpj, estado, city, sector, segment });
    if (!name || !cnpj || !estado || !city || !sector || !segment) {
      return toast.error("Não foi possível atualizar esta empresa");
    }

    try {
      const response = await api.put(`/companies/${id}`, {
        name,
        cnpj,
        state: estado.nome,
        city,
        sector,
        segment,
        numberColabs: partner,
        annualBilling: revenue,
      });

      localStorage.setItem("@legaro:company", response.data._id);

      toast.success("Dados da empresa atualizado com sucesso");
      // navigation.next();
    } catch (error) {
      //console.log("ERRO", error);
      return toast.error("Erro ao atualizar esta empresa");
    }
  }

  return (
    <>
      <HeaderAfterLogin />
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse"></i>
          <span style={{ marginLeft: 5 }}>Buscando dados da empresa...</span>
        </div>
      )}
      <Container>
        <Content>
          <Title>Atualize as informações da empresa</Title>
          <Form onSubmit={handleSubmit}>
            <label htmlFor="name">Nome</label>
            <Input
              name="name"
              type="text"
              required
              minLength="8"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="cnpj">CNPJ</label>
            <Input
              name="cnpj"
              type="text"
              required
              maxLength="18"
              value={cnpj}
              onChange={(e) => setCnpj(maskCNPJ(e.target.value))}
            />
            <label htmlFor="uf">Estado sede</label>
            <select
              name="uf"
              value={uf}
              onChange={(e) =>
                setUf(e.target.value.replace(e.target.value.slice(0, 3), ""))
              }
            >
              {uf && <option>{uf}</option>}
              {listUf.map((a, index) => (
                <option key={index} name={uf} value={a.name}>
                  {a.sigla} {a.nome}
                </option>
              ))}
            </select>
            <label htmlFor="city">Município sede</label>
            <select
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              {city && <option>{city}</option>}
              {listCity.map((a, index) => (
                <option key={a.nome} value={a.sigla}>
                  {a.nome}
                </option>
              ))}
            </select>
            <label htmlFor="sector">Setor</label>
            <select
              name="sector"
              value={sector}
              onChange={(e) => setSector(e.target.value)}
            >
              {SectorOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
            <label htmlFor="segment">Segmento</label>
            <select
              name="segment"
              value={segment}
              onChange={(e) => setSegment(e.target.value)}
            >
              {SegmentOptions.map((option, index) => (
                <option key={index} name={segment} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>

            <label htmlFor="partner">Número de colaboradores</label>
            <select
              name="partner"
              value={partner}
              onChange={(e) => setPartner(e.target.value)}
            >
              {PartnerOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>

            <label htmlFor="revenue">Faturamento anual da empresa </label>
            <select
              name="revenue"
              value={revenue}
              onChange={(e) => setRevenue(e.target.value)}
            >
              {RevenueOptions.map((option, index) => (
                <option key={index} name={revenue} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>

            <br />
            <hr />
            <br />
            <button type="submit">ATUALIZAR</button>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Company;
