import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import jwt from "jsonwebtoken";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  ContentCompanyInfos,
  Title,
} from "../../components/StepForm/Style.Elements";
import { Container, Background, ModalImg } from "./Style.Elements";
import api from "../../services/api";

const EmailConfirmed = () => {
  const history = useHistory();
  const url = history.location.search;
  const obj = qs.parse(url);

  const token = obj["?token"];

  const decoded = token
    ? jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
    : null;

  useEffect(() => {
    const userId = decoded?.userId;

    api
      .put(`users/${userId}`, {
        emailConfirmed: true,
      })
      .then((res) => {
        toast.success("E-mail confirmado com sucesso 😃");
      })
      .catch((error) => {
        toast.error("Erro ao confirmar o e-mail");
      });
  }, [decoded]);

  return (
    <Container>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          alt="someone"
        />
      </Background>
      <ContentCompanyInfos>
        <AiOutlineCheckCircle size={100} color="#6fa824" />
        <Title
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            verticalAlign: "middle",
            marginTop: "30px",
          }}
        >
          E-mail confirmado!
        </Title>
      </ContentCompanyInfos>
    </Container>
  );
};

export default EmailConfirmed;
