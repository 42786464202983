import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  /* ${(props) =>
    props.styles[0]?.top &&
    css`
      padding-top: ${props.styles[0].top}px;
    `} */
`;

export const CloseSignUpWindow = styled.div`
  display: flex;
  position: absolute;
  margin: 20px 30px;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    position: absolute !important;
    margin-left: -90%;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    position: absolute !important;
    margin-left: -85%;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    position: absolute !important;
    margin-left: -80%;
  }
`;

export const ModalImg = styled.img`
  height: 50%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    height: 85%;
  }
`;
