import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavLinks,
} from "./HeaderAfterLogin.elements";

import BusinessIcon from "@material-ui/icons/Business";

import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import CustomizedMenus from "./menu";

function Header() {
  //const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClickMenu = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const { user, signOut } = useAuth();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(`companies/author/${user._id}`);
      setData(response.data.docs);
    }
    loadData();
  }, [user._id]);

  return (
    <>
      <IconContext.Provider value={{ color: "#101522" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              <img
                src={
                  "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.webp"
                }
                alt="LEGARO"
                width="200px"
                id="ExportLogo"
              />
              {/* <LogoLegaro />*/}
            </NavLogo>
            <MobileIcon onClick={handleClickMenu}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <NavMenu id="ExportNavMenu" click={click}>
              <NavLinks>
                <Link
                  to="/ajuda-id"
                  id="ExportAjuda"
                  style={{
                    textDecoration: "none",
                    color: "#666666",
                    fontWeight: "bold",
                  }}
                >
                  ajuda
                </Link>
              </NavLinks>
              <NavLinks>
                <Link
                  to="/dashboard"
                  id="ExportPainel"
                  style={{
                    textDecoration: "none",
                    color: "#666666",
                    fontWeight: "bold",
                  }}
                >
                  painel de empresas
                </Link>
              </NavLinks>
              {data[0] && (
                <NavLinks style={{ cursor: "default" }}>
                  <Link
                    to="#"
                    id="ExportCompany"
                    style={{
                      color: "#666666",
                      textTransform: "lowercase",
                      fontWeight: "bold",
                      textDecoration: "none",
                      cursor: "default",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BusinessIcon
                      style={{
                        color: "#6FA824",
                        marginRight: "15px",
                        cursor: "default",
                      }}
                    />
                    {data[0].name}
                  </Link>
                </NavLinks>
              )}
              <NavLinks
                id="ExportUser"
                style={{
                  cursor: "default",
                }}
              >
                <CustomizedMenus />
              </NavLinks>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Header;
