//import { Link } from "react-router-dom";
import styled from "styled-components";

export const ScoreSection = styled.div`
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  background: #ffffff;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1300px) {
    //margin: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ScoreHeading = styled.h1`
  color: #333333;
  width: 100%;
  font-size: 40px;
  margin-bottom: 10px;
  margin-left: 2rem;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-top: 50px;

  @media screen and (max-width: 1300px) {
    font-size: 35px;
    text-align: center;
    margin-left: 0;
  }
  @media screen and (max-width: 340px) {
    text-align: left;
    width: 100%;
    margin-left: 0;
    font-size: 1.6rem;
  }
`;

export const CloseHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px 50px 50px 0;
`;

export const ScoreCard = styled.div`
  width: 275px;
  height: 492px;
  text-decoration: none;
  opacity: 1;
  margin-top: 24px;
  margin-bottom: 0px;
  cursor: default;

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%236FA824FF' stroke-width='3' stroke-dasharray='15%2c 15' stroke-dashoffset='17' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;

  &:nth-child(2) {
    margin-right: 24px;
    margin-left: 24px;
  }

  &:nth-child(4) {
    margin-left: 24px;
  }

  @media screen and (max-width: 1300px) {
    width: 90%;

    &:hover {
      transform: none;
    }
  }
`;

export const Checked = styled.div`
  width: 64px;
  height: 64px;
  background-color: #6fa824;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScoreCardAnswer = styled.div`
  width: 275px;
  height: 492px;
  text-decoration: none;
  opacity: 1;
  margin-top: 24px;
  margin-bottom: 0px;
  cursor: default;

  border: 1px solid #6fa824;
  border-radius: 5px;

  position: relative;

  a {
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  strong {
    margin-right: 5px;
  }

  &:nth-child(2) {
    margin-right: 24px;
    margin-left: 24px;
  }

  &:nth-child(4) {
    margin-left: 24px;
  }

  @media screen and (max-width: 1300px) {
    &:nth-child(4) {
      margin-left: 0;
    }

    &:hover {
      transform: none;
    }
  }
`;

export const ScoreCardMove = styled.div`
  width: 275px;
  height: 492px;
  text-decoration: none;
  opacity: 1;
  margin-top: 24px;
  margin-bottom: 0px;
  cursor: pointer;

  /*background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%236FA824FF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;*/

  border: 2px dashed #6fa824;
  border-radius: 5px;

  &:nth-child(2) {
    margin-right: 24px;
    margin-left: 24px;
  }

  &:nth-child(4) {
    margin-left: 24px;
  }

  @media screen and (max-width: 1300px) {
    width: 90%;

    &:nth-child(4) {
      margin-left: 0;
    }

    &:hover {
      transform: none;
    }
  }
`;

export const ScoreCardEconomic = styled.div`
  width: 275px;
  height: 492px;
  text-decoration: none;
  opacity: 1;
  cursor: default;
  margin: 24px 24px 0 24px;

  border: 2px solid #808080;

  border-radius: 5px;

  @media screen and (max-width: 1300px) {
    //width: 50%;

    &:hover {
      transform: none;
    }
  }
`;

export const ScoreCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;

  padding-bottom: 0px;
  margin-bottom: 0px;
  align-items: center;
  text-align: left;
`;

export const ScoreCardIcon = styled.div`
  margin: 24px 0;
`;

export const ScoreCardContainer = styled.div`
  padding: 25px;
  padding-bottom: 0;
  width: 100%;
`;

export const ScoreCardPlan = styled.h3`
  margin-bottom: 5px;
  font-size: 20px;
  color: #666666;
  text-align: left;
  font-weight: bold;
  width: 100%;
  font-family: "Roboto", sans-serif;
`;

export const ScoreCardCost = styled.h4`
  font-size: 30px;
  color: #4b4b4b;
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
`;

export const ScoreCardLength = styled.p`
  font-size: 14px;
  margin-bottom: 24px;
`;

export const ScoreCardFeatures = styled.ul`
  margin: 16px 0 32px;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #333333;
  width: 208px;
  height: 171px;
  width: 100%;
`;

export const ScoreCardFeature = styled.li`
  margin-bottom: 10px;
  width: 208px;
  height: 171px;
  font-family: "Roboto", sans-serif;
  font-size: ${({ fontBig }) => (fontBig ? "19px" : "16px")};
  font-weight: 400;
`;

export const ImageIcon = styled.img``;

export const Container = styled.div``;

export const ScoreCardSome = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    margin-bottom: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  text-decoration: underline;
  color: #666666;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
`;
