import React from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import { User } from '../StepForm/User';
import { Company } from '../StepForm/Company';
import { CompanyInfo } from '../StepForm/CompanyInfo';
import { Review } from '../StepForm/Review';
import { Submit } from '../StepForm/Submit';

const defaultData = {
  name: '',
  email: '',
  phone: '',
  office: '',
  password: '',
  companyName: '',
  cnpj: '',
  state: '',
  city: '',
  sector: '',
  segment: '',
};

const steps = [
  { id: 'user' },
  { id: 'company' },
  { id: 'companyInfo' },
  { id: 'review' },
  { id: 'submit' },
];

export const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case 'user':
      return <User {...props} />;
    case 'company':
      return <Company {...props} />;
    case 'companyInfo':
      return <CompanyInfo {...props} />;
    case 'review':
      return <Review {...props} />;
      case 'submit':
        return <Submit {...props} />;
    default:
  }

  return (
    <div>
      <h1>Multi step form</h1>
    </div>
  );
};
