import React from "react";

import {
  Title,
  ContainerTitle,
  ContainerSubtitle,
  Subtitle,
  ContainerAll,
} from "./Page404e500.elements";

function ErrorPages() {
  return (
    <div>
      <ContainerAll>
        <ContainerTitle>
          <Title>5</Title>
          <Title>
            <img
              src="https://codexremote.com.br/esgimages/legaro/ICONE_LEGARO.png"
              alt="Ícone Legaro"
            />
          </Title>
          <Title>
            <img
              src="https://codexremote.com.br/esgimages/legaro/ICONE_LEGARO.png"
              alt="Ícone Legaro"
            />
          </Title>
        </ContainerTitle>
        <ContainerSubtitle>
          <Subtitle>Erro no servidor.</Subtitle>
        </ContainerSubtitle>
      </ContainerAll>
    </div>
  );
}

export default ErrorPages;
