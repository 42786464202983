import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: column;
  align-items: center;
  width: 100%;
  border: 1px solid red;

  span {
    border-bottom: 2px solid #6fa824;
    width: 100%;
  }
`;

export const TopText = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid red;
  margin: 15px;
`;

export const CloseWindow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border: 1px solid red;
`;
