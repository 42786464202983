import React from "react";
import { Footer, Navbar } from "../../components";
import { Title, Container, Content, Text, Image } from "./style.elements";

function Plataform() {
  return (
    <Container>
      <Navbar />
      <Title>
        <strong>Uma plataforma, vários resultados</strong>
      </Title>
      <Content>
        <Image>
          <img
            src="https://codexremote.com.br/esgimages/legaro/Home2.webp"
            alt="imagem"
          />
        </Image>
        <Text>
          <h2>
            <strong>Nossa plataforma</strong>
          </h2>
          <br />
          <p className="lead">
            A Legaro compreende como o ESG está presente na sua empresa. Com a
            ferramenta, você poderá analisar a responsabilidade social,
            ambiental, de governança e econômica do seu negócio. A partir desses
            dados, você poderá controlar os riscos que estão envolvidos na sua
            operação, quais os aspectos você pode melhorar, assim como entender
            qual a posição da sua organização em relação às práticas ESG.
            <br />
            <br />
            Após a conclusão dos questionamentos que envolvem cada tema, você
            será direcionado à sua página de pontuação, com as devidas
            informações que indicam se a sua empresa possui um resultado
            satisfatório ou não quanto às métricas ESG.
            <br />
            <br />O objetivo da Legaro é informar o seu impacto no mundo, e
            fornecer informações necessárias para que o seu negócio tenha a
            sustentabilidade como ponto de partida, seja alvo de grandes
            investimentos e seja reconhecido pela responsabilidade ambiental e
            social.
          </p>
        </Text>
      </Content>
      <Footer />
    </Container>
  );
}

export default Plataform;
