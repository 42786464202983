import React from "react";
import Template from "../template/Foundation";
import Footer from "../template/Footer";
/* import SignInText from "../template/SignInText"; */
import GlobalStyle from "../globalStyles";
import Home from "../pages/HomePage/Home";
import Temas from "../pages/Temas/Temas";
import SignUp from "../pages/SignUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import Dashboard from "../pages/Dashboard/Dashboard";
import ScoreSelect from "../pages/ThemeAnswer/ThemeAnswer";
import Questionaire from "../pages/Questionaire/Questionaire";
import TransitionAmbiental from "../pages/Transition/TransitionAmbiental";
import TransitionSocial from "../pages/Transition/TransitionSocial";
import TransitionEconomico from "../pages/Transition/TransitionEconomico";
import TransitionGovernanca from "../pages/Transition/TransitionGovernanca";
import TransitionGeral from "../pages/Transition/TransitionGeral";
import AboutESG from "../pages/ContentPages/AboutEsg";
import WeAreCodex from "../pages/ContentPages/WeAreCodex";
import Partner from "../pages/ContentPages/Partner";
import Plataform from "../pages/ContentPages/Plataform";
import HelpPublic from "../pages/Help/HelpPublic";
import HelpPrivate from "../pages/Help/HelpPrivate";
import EditSignIn from "../pages/EditInformation/EditSignIn";
import EditCompany from "../pages/EditInformation/EditCompany";
import ResultScore from "../pages/ResultPages/Result";
import ResultScoreFinal from "../pages/ResultPages/FinalResult";
import UpdateQuestionaireAmbiental from "../pages/QuestionaireAtualization/UpdateQuestionaireAmbiental";
import UpdateQuestionaireAmbientalFinanceiro from "../pages/QuestionaireAtualization/UpdateQuestionaireAmbientalFinanceiro";
import UpdateQuestionaireSocial from "../pages/QuestionaireAtualization/UpdateQuestionaireSocial";
import UpdateQuestionaireGovernanca from "../pages/QuestionaireAtualization/UpdateQuestionaireGovernanca";
import UpdateQuestionaireEconomico from "../pages/QuestionaireAtualization/UpdateQuestionaireEconomico";
import SignUpCompany from "../components/StepForm/Company";
import SignUpCompanyInfos from "../components/StepForm/CompanyInfo";
import CheckPages from "../pages/CheckPages/CheckPages";
import Fases from "../pages/Fases/Fases";
import Page404 from "../pages/ErrorPages/Page404";
import Page500 from "../pages/ErrorPages/Page500";
import Export from "../pages/Export/Export";
import Share from "../pages/ShareResults/ShareFinalResult";
import PasswordRecover from "../pages/PasswordRecover/PasswordRecover";
import PasswordChange from "../pages/PasswordRecover/PasswordChange";
import PrivacyPolicy from "../pages/SignUp/PrivacyPolicy";
import AtualizaAcao from "../pages/Modulo2/AtualizaAcao/AtualizaAcao";
import CadastraAcao from "../pages/Modulo2/CadastraAcao/CadastraAcao";
import PlanoAcao from "../pages/Modulo2/PlanoAcao/PlanoAcao";
import Glossary from "../pages/Glossario/glossary";
import Glossario from "../pages/Glossario/glossarioPagination";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Route from "./PrivateRoutes";
import EmailConfirmation from "../pages/PasswordRecover/EmialConfirmation";
import EmailConfirmed from '../pages/PasswordRecover/EmialConfirmed'

function Routes() {
  return (
    <Router>
      <Template />
      <GlobalStyle />
      <Switch>
        {/** Rotas públicas para o Usuário */}
        <Route path="/" exact component={Home} />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/sign-up" component={SignUp} isPrivate />
        <Route path="/o-que-e-esg" component={AboutESG} />
        <Route path="/somos-a-codex" component={WeAreCodex} />
        <Route path="/uma-plataforma" component={Plataform} />
        <Route path="/seja-parceiro" component={Partner} />
        <Route path="/ajuda" component={HelpPublic} />
        <Route path="/tema-respondido" component={CheckPages} />
        <Route path="/404" component={Page404} />
        <Route path="/500" component={Page500} />
        <Route
          path="/compartilhe-seus-resultados/:id"
          component={Share}
          isPrivate
        />
        <Route path="/recuperar-senha" component={PasswordRecover} />
        <Route path="/redefinir-senha" component={PasswordChange} />
        <Route path="/email-confirmation" component={EmailConfirmation} />
        <Route path="/email-confirmed" component={EmailConfirmed} isPrivate/>
        <Route path="/politica-privacidade" component={PrivacyPolicy} />

        {/** Rotas privadas para o Usuário, pós login ou register */}
        <Route path="/sign-up-company" component={SignUpCompany} isPrivate />
        <Route path="/new-company" component={SignUpCompany} isPrivate />
        <Route
          path="/sign-up-company-infos"
          component={SignUpCompanyInfos}
          isPrivate
        />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/mover-temas/:stageId" component={Temas} isPrivate />
        <Route
          path="/selecionar-tema/:stageId"
          component={ScoreSelect}
          isPrivate
        />
        <Route
          path="/questionario/:id/:stageId"
          component={Questionaire}
          isPrivate
        />
        <Route
          path="/atualizar-questionario/:id/ambiental"
          component={UpdateQuestionaireAmbiental}
          isPrivate
        />
        <Route
          path="/atualizar-questionario/:id/ambiental-financeiro"
          component={UpdateQuestionaireAmbientalFinanceiro}
          isPrivate
        />
        <Route
          path="/atualizar-questionario/:id/social"
          component={UpdateQuestionaireSocial}
          isPrivate
        />
        <Route
          path="/atualizar-questionario/:id/governanca"
          component={UpdateQuestionaireGovernanca}
          isPrivate
        />
        <Route
          path="/atualizar-questionario/:id/economico"
          component={UpdateQuestionaireEconomico}
          isPrivate
        />
        <Route
          path="/transicao-ambiental/:stageId"
          component={TransitionAmbiental}
          isPrivate
        />
        <Route
          path="/transicao-social/:stageId"
          component={TransitionSocial}
          isPrivate
        />
        <Route
          path="/transicao-economico/:stageId"
          component={TransitionEconomico}
          isPrivate
        />
        <Route
          path="/transicao-governanca/:stageId"
          component={TransitionGovernanca}
          isPrivate
        />
        <Route path="/transicao-geral" component={TransitionGeral} isPrivate />
        <Route
          path="/atualizar-informacoes-usuario/:id"
          component={EditSignIn}
          isPrivate
        />
        <Route
          path="/atualizar-informacoes-empresa/:id"
          component={EditCompany}
          isPrivate
        />
        <Route path="/resultado-score" component={ResultScore} isPrivate />
        <Route
          path="/resultado-score-final"
          component={ResultScoreFinal}
          isPrivate
        />
        <Route path="/ajuda-id" component={HelpPrivate} isPrivate />
        <Route path="/escolha-da-fase" component={Fases} isPrivate />
        <Route path="/exportar-resultados" component={Export} isPrivate />
        <Route path="/atualizar-acao/:id" component={AtualizaAcao} isPrivate />
        <Route path="/cadastrar-acao" component={CadastraAcao} isPrivate />
        <Route path="/plano-acao/:companyId" component={PlanoAcao} isPrivate />
        <Route path="/glossario" component={Glossary} isPrivate />
        <Route path="/glossario-paginacao" component={Glossario} isPrivate />
      </Switch>
      <Footer />
    </Router>
  );
}

export default Routes;
