import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { ScrollToTop } from "..";

//import { ScrollToTop } from "../../components/ScrollToTop";
import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinksWrapperHelp,
  FooterLinkItems,
  FooterLinkItemsHelp,
  FooterLinkTitle,
  FooterLinkSubTitle,
  FooterLinkSubSubTitle,
  FooterLinkText,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterInformation,
  FooterLinkOut,
  FooterLinkEmail,
} from "./Footer.elements";

function Footer() {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkSubSubTitle>LEGARO</FooterLinkSubSubTitle>
            <FooterLink aria-label="O que é LEGARO" to="o-que-e-esg">
              o que é LEGARO?
            </FooterLink>

            <FooterLink aria-label="quem somos" to="somos-a-codex">
              quem somos
            </FooterLink>
            <FooterLink aria-label="a plataforma" to="uma-plataforma">
              a plataforma
            </FooterLink>

            <FooterLink aria-label="seja parceiro" to="seja-parceiro">
              seja parceiro
            </FooterLink>
            <FooterLink aria-label="entrar" to="/sign-in">
              entrar
            </FooterLink>
            <FooterLink aria-label="inscrever-se" to="/sign-up">
              inscrever-se
            </FooterLink>
            {/*<FooterLink to="/" aria-label="metodologia">
              metodologia
  </FooterLink>*/}
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkSubSubTitle>CODEX</FooterLinkSubSubTitle>
            <FooterLinkOut
              to={{ pathname: "https://codexremote.com.br/home/" }}
              target="_blank"
              aria-label="Site"
            >
              Site
            </FooterLinkOut>
            <FooterLinkOut
              to={{ pathname: "https://www.codexremote.com.br/blogcodex/" }}
              target="_blank"
              aria-label="Blog"
            >
              Blog
            </FooterLinkOut>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkSubSubTitle>PARCEIROS</FooterLinkSubSubTitle>
            <FooterLinkOut
              to={{ pathname: "https://www.fdc.org.br/" }}
              target="_blank"
              aria-label="Fundação Dom Cabral"
            >
              Fundação Dom Cabral
            </FooterLinkOut>
          </FooterLinkItems>
        </FooterLinksWrapper>
        <FooterLinksWrapperHelp>
          <FooterLinkItemsHelp>
            <FooterLinkTitle>
              Alguma dúvida? <br /> A gente ajuda você!
            </FooterLinkTitle>
            <ScrollToTop />
            <FooterLink to="/ajuda" aria-label="Help page">
              <br />
              Acesse a nossa <strong>Página de ajuda</strong>
              <br />
              <br />
            </FooterLink>
            <FooterLinkSubTitle>
              Ou entre em contato conosco:
            </FooterLinkSubTitle>
            <FooterLinkText>
              +55 51 3209-4722 | +55 51 3206-0379 |
            </FooterLinkText>
            <FooterLinkEmail href="mailto:contato@legaroesg.com.br">
              contato@legaroesg.com.br
            </FooterLinkEmail>
            <FooterLinkText>
              Av. Borges de Medeiros, 659/5º Andar, Centro Histórico
            </FooterLinkText>
            <FooterLinkText>Porto Alegre / RS - CEP 90.020-023</FooterLinkText>
          </FooterLinkItemsHelp>
        </FooterLinksWrapperHelp>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to="/">
            <img
              src={
                "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_SEM_SLOGAN.webp"
              }
              alt="LEGARO"
              width="200px"
            />
          </SocialLogo>
          <SocialIcons>
            <SocialIconLink
              to={{ pathname: "https://www.facebook.com/CodexCorporacao" }}
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink
              to={{ pathname: "https://www.instagram.com/codexcorporacao/" }}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              to={{
                pathname:
                  "https://www.youtube.com/channel/UCaPqUiAZpbeXPQbpJPJrvdQ",
              }}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Youtube"
            >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink
              to={{ pathname: "https://twitter.com/codexremote" }}
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink
              to={{ pathname: "https://br.linkedin.com/company/codexremote" }}
              target="_blank"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
          <WebsiteRights>LEGARO © 2021</WebsiteRights>
        </SocialMediaWrap>
      </SocialMedia>
      <FooterInformation>
        <hr color="#666666" />
        <p>
          A LEGARO é um produto independente e pertencente à Codex. <br />
          Toda comunicação através da rede mundial de computadores está sujeita
          a interrupções ou atrasos, podendo impedir ou prejudicar o envio ou a
          recepção de informações atualizadas.
        </p>
      </FooterInformation>
    </FooterContainer>
  );
}

export default Footer;
