/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import ChartParcial from "./ChartParcial";
import _ from "lodash";
import "./Result.css";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-scroll";
import { FooterAfterLogin } from "../../components";
import {
  AccordionParcialResultsAmbiental,
  AccordionParcialResultsSocial,
  AccordionParcialResultsEconomico,
  AccordionParcialResultsGovernanca,
} from "../../components";
import sortArray from "sort-array";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#346fb1",
  },
}))(LinearProgress);

function Result() {
  const [data, setData] = React.useState({});
  const [newData, setNewData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  //load data of companies by ID
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const IDcompany = localStorage.getItem("@legaro:companyID");
      const response = await api.get(`companies/${IDcompany}`);

      const {
        ambientalWeight,
        governancaWeight,
        socialWeight,
        economicoWeight,
      } = response.data.company;

      setNewData([
        {
          id: "ambiental",
          weight: ambientalWeight,
        },
        {
          id: "governanca",
          weight: governancaWeight,
        },
        {
          id: "social",
          weight: socialWeight,
        },
        {
          id: "economico",
          weight: 0,
        },
      ]);
      setLoading(false);
      setData(response.data);
    }
    loadData();
  }, []);

  /*useEffect(() => {
    async function loadAtualizacao() {
      // setLoading(true)
      const response = await api.get(`/companies/check/${themeEnd}`);
      setData(response.data);
      // setLoading(false)
    }
    loadAtualizacao();
  }, [themeEnd]);*/

  const MinAmbiental = 0;
  const MaxAmbiental = data.positionAmbientalWeight;
  const normalizeAmbiental = (value) =>
    ((value - MinAmbiental) * 100) / (MaxAmbiental - MinAmbiental);

  const MinSocial = 0;
  const MaxSocial = data.positionSocialWeight;
  const normalizeSocial = (value) =>
    ((value - MinSocial) * 100) / (MaxSocial - MinSocial);

  const MinGovernanca = 0;
  const MaxGovernanca = data.positionGovernancaWeight;
  const normalizeGovernanca = (value) =>
    ((value - MinGovernanca) * 100) / (MaxGovernanca - MinGovernanca);

  const MinEconomico = 0;
  const MaxEconomico = data.positionEconomicoWeight;
  const normalizeEconomico = (value) =>
    ((value - MinEconomico) * 100) / (MaxEconomico - MinEconomico);

  //console.log("PESOS", MaxAmbiental, MaxSocial, MaxGovernanca, MinEconomico);

  return (
    <>
      <HeaderAfterLogin />
      <div className="result-container">
        {!loading && (
          <div className="session-score">
            <div className="score-graph">
              {data.company && <ChartParcial data={data} id="ExportChart" />}
            </div>
            <br />
            <br />
            <div className="score-description">
              <div className="score-title">
                Atenção: sua pontuação ESG ainda é parcial
              </div>
              <div className="score-text">
                Para obter sua pontuação ESG final é necessário responder todas
                as questões a respeito dos 4 temas.
              </div>

              <div className="continuarRespondendo">
                <a
                  href="/escolha-da-fase"
                  className="btn-score"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                >
                  continuar a obtenção do Score
                  <i
                    style={{ marginLeft: "10px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            <span style={{ marginLeft: 5 }}>
              Buscando os temas e os indicadores 🕐
            </span>
          </div>
        )}
        {data && (
          <div className="session-themes">
            {sortArray(newData, {
              by: "weight",
              order: "desc",
            }).map((tema, index) => {
              if (tema.id === "ambiental") {
                return Ambiental(data, normalizeAmbiental, index);
              }
              if (tema.id === "social") {
                return Social(data, normalizeSocial, index);
              }
              if (tema.id === "governanca") {
                return Governanca(data, normalizeGovernanca, index);
              }
              if (tema.id === "economico") {
                return Economico(data, normalizeEconomico, index);
              }
            })}
          </div>
        )}

        <div className="session-themes-score">
          {sortArray(newData, {
            by: "weight",
            order: "desc",
          }).map((tema, index) => {
            if (tema.id === "ambiental") {
              return AmbientalIndicator(
                index,
                data?.performancesIndicadores?.ambiental,
                data
              );
            }
            if (tema.id === "social") {
              return SocialIndicator(
                index,
                data?.performancesIndicadores?.social,
                data
              );
            }
            if (tema.id === "governanca") {
              return GovernancaIndicator(
                index,
                data?.performancesIndicadores?.governanca,
                data
              );
            }
            if (tema.id === "economico") {
              return EconomicoIndicator(
                index,
                data?.performancesIndicadores?.economico,
                data
              );
            }
          })}
        </div>
      </div>
      <FooterAfterLogin />
    </>
  );
}

export default Result;

// TEMAS
function Ambiental(data, normalizeAmbiental, index, IDcompany) {
  return (
    <div className="theme-card">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon06.png"
          height="55px"
          alt="Ambiental"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Ambiental</div>
      <BorderLinearProgress
        id="borderLinearProgress"
        variant="determinate"
        value={normalizeAmbiental(data.ambientalScore)}
      />
      <br />
      {data.ambientalScore !== 0 ? (
        <a
          className="theme-link"
          href={`/atualizar-questionario/${data?.company?._id}/ambiental`}
        >
          Atualizar <i className="fas fa-arrow-right"></i>
        </a>
      ) : (
        <a className="theme-link" href="/escolha-da-fase">
          Responder <i className="fas fa-arrow-right"></i>
        </a>
      )}
      <hr className="hr-theme-card" />
      <Link to="sectionAmbientalParcial" offset={-120} smooth={true}>
        <div className="theme-details">
          {" "}
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Social(data, normalizeSocial, index, IDcompany) {
  return (
    <div className="theme-card">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon03.png"
          height="55px"
          alt="Social"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Social</div>
      <BorderLinearProgress
        id="borderLinearProgress"
        variant="determinate"
        value={normalizeSocial(data.socialScore)}
      />
      <br />
      {data.socialScore !== 0 ? (
        <a
          className="theme-link"
          href={`/atualizar-questionario/${data?.company?._id}/social`}
        >
          Atualizar <i className="fas fa-arrow-right"></i>
        </a>
      ) : (
        <a className="theme-link" href="/escolha-da-fase">
          Responder <i className="fas fa-arrow-right"></i>
        </a>
      )}
      <hr className="hr-theme-card" />
      <Link to="sectionSocialParcial" offset={-120} smooth={true}>
        <div className="theme-details">
          {" "}
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Governanca(data, normalizeGovernanca, index, IDcompany) {
  return (
    <div className="theme-card">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon12.png"
          height="55px"
          alt="Governança"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Governança</div>
      <BorderLinearProgress
        id="borderLinearProgress"
        variant="determinate"
        value={normalizeGovernanca(data.governancaScore)}
      />
      <br />
      {data.governancaScore !== 0 ? (
        <a
          className="theme-link"
          href={`/atualizar-questionario/${data?.company?._id}/governanca`}
        >
          Atualizar <i className="fas fa-arrow-right"></i>
        </a>
      ) : (
        <a className="theme-link" href="/escolha-da-fase">
          Responder <i className="fas fa-arrow-right"></i>
        </a>
      )}
      <hr className="hr-theme-card" />
      <Link to="sectionGovernancaParcial" offset={-120} smooth={true}>
        <div className="theme-details">
          {" "}
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Economico(data, normalizeEconomico, index, IDcompany) {
  return (
    <div className="theme-card">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon09.png"
          height="55px"
          alt="Econômico"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Econômico</div>
      <BorderLinearProgress
        id="borderLinearProgress"
        variant="determinate"
        value={normalizeEconomico(data.economicoScore)}
      />
      <br />
      {data.economicoScore !== 0 ? (
        <a
          className="theme-link"
          href={`/atualizar-questionario/${data?.company?._id}/economico`}
        >
          Atualizar <i className="fas fa-arrow-right"></i>
        </a>
      ) : (
        <a className="theme-link" href="/escolha-da-fase">
          Responder <i className="fas fa-arrow-right"></i>
        </a>
      )}
      <hr className="hr-theme-card" />
      <Link to="sectionEconomicoParcial" offset={-120} smooth={true}>
        <div className="theme-details">
          {" "}
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

// INDICADORES
function AmbientalIndicator(index, indicadores, data, IDcompany) {
  const names = _.keys(indicadores);

  const indicadoresAmbiental = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionAmbientalParcial">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Ambiental</div>
        {/*{indicadores && <div>{loadIndicators()}</div>}*/}
        {data.ambientalScore === 0 ? (
          <a className="theme-link" href="/escolha-da-fase">
            Responder <i className="fas fa-arrow-right"></i>
          </a>
        ) : (
          <a
            className="theme-link"
            href={`/atualizar-questionario/${data?.company?._id}/ambiental`}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>
        )}
      </div>
      <div className="theme-score-container">
        <AccordionParcialResultsAmbiental indicadores={indicadoresAmbiental} />
      </div>
    </div>
  );
}

function SocialIndicator(index, indicadores, data, IDcompany) {
  const names = _.keys(indicadores);

  const indicadoresSocial = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionSocialParcial">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Social</div>
        {/*<div className="theme-item-link">Ver respostas</div> */}
        {data.socialScore === 0 ? (
          <a className="theme-link" href="/escolha-da-fase">
            Responder <i className="fas fa-arrow-right"></i>
          </a>
        ) : (
          <a
            className="theme-link"
            href={`/atualizar-questionario/${data?.company?._id}/social`}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>
        )}
      </div>
      <div className="theme-score-container">
        <AccordionParcialResultsSocial indicadores={indicadoresSocial} />
      </div>
    </div>
  );
}

function EconomicoIndicator(index, indicadores, data, IDcompany) {
  const names = _.keys(indicadores);

  const indicadoresEconomico = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionEconomicoParcial">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Econômico</div>
        {/*<div className="theme-item-link">Ver respostas</div>*/}
        {data.economicoScore === 0 ? (
          <a className="theme-link" href="/escolha-da-fase">
            Responder <i className="fas fa-arrow-right"></i>
          </a>
        ) : (
          <a
            className="theme-link"
            href={`/atualizar-questionario/${data?.company?._id}/economico`}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>
        )}
      </div>
      <div className="theme-score-container">
        <AccordionParcialResultsEconomico indicadores={indicadoresEconomico} />
      </div>
    </div>
  );
}

function GovernancaIndicator(index, indicadores, data, IDcompany) {
  const names = _.keys(indicadores);

  const indicadoresGovernanca = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionGovernancaParcial">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Governança</div>
        {/*{AnswerOrAtualize()}*/}
        {data.governancaScore === 0 ? (
          <a className="theme-link" href="/escolha-da-fase">
            Responder <i className="fas fa-arrow-right"></i>
          </a>
        ) : (
          <a
            className="theme-link"
            href={`/atualizar-questionario/${data?.company?._id}/governanca`}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>
        )}
      </div>
      <div className="theme-score-container">
        <AccordionParcialResultsGovernanca
          indicadores={indicadoresGovernanca}
        />
      </div>
    </div>
  );
}
