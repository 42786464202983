import styled from "styled-components";

export const ContainerData = styled.div`
  font-family: "Roboto", sans-serif;

  h1 {
    color: #6fa824;
    display: flex;
    justify-content: center;
    vertical-align: text-top;

    background-color: #e8e8e8;
    border-radius: 20px 20px 0 0;
    //border: 1px solid red;
    //border-bottom: 5px solid #e8e8e8;
  }

  .glossario-termo {
    font-weight: bold;
    display: flex;
    vertical-align: top;
    //color: red;
    text-transform: uppercase;
  }

  .glossario-explicacao {
    //color: red;
    padding-top: 0;
    text-align: justify;
  }

  .glossario-fonte {
    padding-top: 0;
    font-size: 12px;
    text-align: justify;
  }
`;
export const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin: 3% 0;
  margin-top: 5%;
`;
export const LettersContainer = styled.div`
  //border: 1px solid red;
  margin: 5% 0;

  ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(26, 3.85%);

    li {
      //border: 1px solid red;
      margin: 10%;
      padding: 10%;
      display: flex;
      justify-content: center;
      background-color: #6fa824;
      color: #fff;
      cursor: pointer;
      border-radius: 50%;
      height: 100%;
      align-items: center;
    }
    .dontExist {
      background-color: #e8e8e8;
      cursor: default;
    }
  }
`;
export const DataContainer = styled.div`
  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  }
`;

export const Letter = styled.div`
  //border: 1px solid red;
  color: #6FA824;
  font-family: "Roboto", sans-serif;
  font-size: 50px;
   font-weight: bold;
   margin-left: 1%;
  }
`;
