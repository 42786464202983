import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";
import { FaMagento } from "react-icons/fa";

export const ExportResults = styled.div``;
export const Header = styled.div``;

export const Nav = styled.nav`
  background-color: #fff;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
`;
export const MenuItem = styled(Link)``;
export const Row = styled(Link)``;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #444444;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: start;
  font-weight: bold;

  img {
    @media screen and (max-width: 1170px) {
      width: 150px;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1170px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
    //background: #101522;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1170px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-200%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: white;
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 1170px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled.div`
  color: #444444;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: white !important;

  @media screen and (max-width: 1170px) {
    text-align: center;
    padding: 5% 1rem;
    width: 100%;
    display: table;
    &:hover {
      color: #6fa824;
      transition: all 0.3s ease;
    }
  }
`;

export const PersonName = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;

export const Company = styled.div`
  text-transform: lowercase;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  cursor: auto;

  color: #666666;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bold;
  background-color: white !important;
`;

export const MenuContainer = styled.div``;

export const FooterContainer = styled.div`
  background-color: #1e2022;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`;

export const FooterSubHeading = styled.p`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 24px;
  font-size: 24px;
`;

export const FooterSubText = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 85%;
  justify-content: space-around;
  border: 1px solid red;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  width: 20%;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinksWrapperHelp = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 10%;
  }
`;
export const FooterLinksWrapperHelpExport = styled.div`
  display: flex;
  width: 85%;
  justify-content: center;
  color: white;
  margin-bottom: 10px;
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    width: 100%;
    padding-left: 20px;
    margin-bottom: 10%;
  }

  @media screen and (max-width: 820px) {
    margin: 20px;
  }
`;

export const FooterLinkItemsHelp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  color: #fff;

  @media screen and (max-width: 420px) {
    width: 100%;
    padding-left: 20px;
    margin-bottom: 10%;
  }

  @media screen and (max-width: 820px) {
    margin: 0;
  }
`;

export const FooterLinkTitle = styled.h2`
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
`;

export const FooterLinkSubTitle = styled.h3`
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
`;

export const FooterLinkSubSubTitle = styled.h4`
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const FooterLinkText = styled.p`
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterLinkEmail = styled.a`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterLinkOut = styled(Link)`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterInformation = styled.div`
  color: #666666;
  border-color: #666666;
  margin-bottom: 2%;
  width: 85%;
  font-size: 14px;

  & p {
    padding-top: 2%;
  }
`;

export const SocialMedia = styled.section`
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 80%;
  margin: auto auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  img {
    width: 160px;
  }
`;

export const SocialIcon = styled(FaMagento)`
  margin-right: 10px;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  font-family: "Montserrat", sans-serif;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 24px;
`;

export const AccordionOpen = styled.div`
  color: #999999;
  font-family: "Roboto";
  font-size: 12px;
  width: 100%;
  border: 1px solid #cccccc;
  height: 12rem;
  border-radius: 15px;
  background-color: #fcfcfc;
`;
export const AccordionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  margin-left: 1rem;
  margin-top: 1rem;

  h1 {
    color: #333333;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }
`;
export const AccordionIndicators = styled.div`
  margin-top: 1rem;
`;

export const AccordionIndicatorsColumn = styled.div`
  color: #666666;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  margin-top: 2.4rem;
  margin-bottom: 1rem;
`;
export const IndicatorsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
`;

export const Indicators = styled.div`
  font-family: "Montserrat", sans-serif;
  margin-right: 5rem;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;

export const IndiscatorsWeight = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;
