import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

const actionID = localStorage.getItem("@legaro:actionID");

export default function DialogRemoveAction(
  { open, handleClosedDialog },
  { id }
) {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const IDcompany = localStorage.getItem("@legaro:companyID");
  const perfomancesIndicatores = history.location.state;

  const handleDeleteAction = async () => {
    try {
      await api.delete(`action-plans/${id}`);

      toast.success("Ação removida com sucesso!");
      window.location.reload();
    } catch (error) {
      toast.error("Não foi possível remover esta ação!");
    }
  };

  return (
    <>
      {loading && (
        <div>
          <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClosedDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Você tem certeza que deseja remover esta ação da lista? "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "#333" }}>
            Por favor, confirme abaixo.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClosedDialog} color="primary">
            Não
          </Button>
          <Button onClick={handleDeleteAction} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
