import React from "react";
import { TransitionSocial } from "../../components";
import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';

function TransitionSocialPage() {
  return (
    <>
      <HeaderAfterLogin />
      <TransitionSocial />
    </>
  );
}

export default TransitionSocialPage;
