import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
//import { useAuth } from "../../hooks/auth";
import {
  Container,
  Background,
  ModalImg,
  CloseWindow,
} from "../../pages/SignUp/Style.Elements";
import { Title, ContentCompanyInfos } from "./Style.Elements";
import api from "../../services/api";
import axios from "axios";
import { useAuth } from "../../hooks/auth";

export const CompanyInfo = ({ navigation }) => {
  const [colaborador, setColaborador] = useState("");
  const [faturamento, setFaturamento] = useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = useState(false);
  const { signOut, user } = useAuth();

  const history = useHistory();
  const token = history.location.search.split("=")[1];

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      // console.log('DATA -> ', actionData.data)

      setEmail(user.email);
      //console.log(user.email);
      setLoading(false);
    }
    loadData();
  }, [user.email]);

  async function handleSubmit({ email }) {
    if (!colaborador || !faturamento) {
      return toast.error("Não foi possível enviar seus dados");
    }

    try {
      const companyId = localStorage.getItem("@legaro:company");

      const companyUserData = history.location.state;

      const { data } = await api.put(`/companies/${companyId}`, {
        numberColabs: colaborador,
        annualBilling: faturamento,
      });

      const rdData = {
        ...companyUserData,
      };

      const {
        cargo,
        city,
        cnpj,
        companyname,
        email,
        isFinancial,
        name,
        phone,
        sector,
        segment,
        state,
        federationId,
      } = rdData;

      const apiKey = "b660005aa0b10559fdafb88735f12881";

      if (process.env.NODE_ENV === "production") {
        axios.post(
          `https://api.rd.services/platform/conversions?api_key=${apiKey}`,
          {
            event_type: "CONVERSION",
            event_family: "CDP",
            payload: {
              conversion_identifier: "Legaro API",
              name,
              email,
              job_title: cargo,
              state,
              city,
              personal_phone: phone,
              company_name: companyname,
              cf_cnpj: cnpj,
              cf_setor_legaro: sector,
              cf_segmentos_legaro: segment,
              cf_numero_de_funcionarios: colaborador,
              cf_faturamento: faturamento,
              /* tags: [
                `CNPJ: ${cnpj}`,
                `Setor: ${sector}`,
                `Segmento: ${segment}`,
                `Nº de Colaboradores: ${colaborador}`,
                `Faturamento Anual: ${faturamento}`,
              ], */
              legal_bases: [
                {
                  category: "communications",
                  type: "consent",
                  status: "granted",
                },
              ],
            },
          }
        );
      }

      //console.log({ rdData });

      //console.log("COMPANY FULL DATA", data);
      localStorage.setItem("@legaro:companyID", data._id);
      history.push("/escolha-da-fase");
    } catch (error) {
      return toast.error("Não foi possível enviar seus dados");
    }
  }

  function handleChangeColaborador(event) {
    setColaborador(event.target.value);
  }

  function handleChangeFaturamento(event) {
    setFaturamento(event.target.value);
  }

  /*const handlePrevious = async () => {
    if (history.location.pathname === "/new-company-infos") {
      history.push("/dashboard");
      return;
    }

    await api.delete(`/users/${user._id}`);
    signOut();
    history.push("/");
  };
  }*/

  return (
    <Container>
      <CloseWindow>
        <Link to="/">x</Link>
      </CloseWindow>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          alt="someone"
        />
      </Background>
      <ContentCompanyInfos>
        <Title>Agora, precisamos que identifique a empresa.</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            name="email"
            value={email}
            type="email"
            id="email"
            style={{ display: "none" }}
          />
          <p>
            Selecione o número de colaboradores que atuam na empresa
            <span style={{ color: "#f00" }}> *</span>
          </p>
          <input
            type="radio"
            id="colaborador1"
            name="fav_language"
            value="De 1 a 19"
            onChange={handleChangeColaborador}
          />
          <label for="colaborador1">De 1 a 19</label>
          <input
            type="radio"
            id="colaborador2"
            name="fav_language"
            value="De 20 a 99"
            onChange={handleChangeColaborador}
          />
          <label for="colaborador2">De 20 a 99</label>
          <input
            type="radio"
            id="colaborador3"
            name="fav_language"
            value="De 100 a 499"
            onChange={handleChangeColaborador}
          />
          <label for="colaborador3">De 100 a 499</label>
          <input
            type="radio"
            id="colaborador4"
            name="fav_language"
            value="Mais de 500"
            onChange={handleChangeColaborador}
          />
          <label for="colaborador4">Mais de 500</label>
          <p>
            Selecione o faturamento anual da empresa
            <span style={{ color: "#f00" }}> *</span>
          </p>
          <input
            type="radio"
            id="faturamento1"
            name="age"
            value="Até R$ 360 mil"
            onChange={handleChangeFaturamento}
          />
          <label for="faturamento1">Até R$ 360 mil</label>
          <input
            type="radio"
            id="faturamento2"
            name="age"
            value="De R$ 360 mil até R$ 4,8 milhões"
            onChange={handleChangeFaturamento}
          />
          <label for="faturamento2">De R$ 360 mil até R$ 4,8 milhões</label>
          <input
            type="radio"
            id="faturamento3"
            name="age"
            value="De R$ 4,8 milhões até R$ 300 milhões"
            onChange={handleChangeFaturamento}
          />
          <label for="faturamento3">De R$ 4,8 milhões até R$ 300 milhões</label>
          <input
            type="radio"
            id="faturamento4"
            name="age"
            value="Mais que R$ 300 milhões"
            onChange={handleChangeFaturamento}
          />
          <label for="faturamento4">Mais que R$ 300 milhões</label>
          <div style={{ width: "100%", display: "flex", marginTop: 30 }}>
            <button
              type="button"
              style={{
                background: "#EEEEEE",
                color: "#666666",
                marginRight: "1rem",
                width: "50%",
              }}
              onClick={() => navigation.previous()}
            >
              Voltar
            </button>
            <button type="submit" style={{ width: "50%" }}>
              Concluir
            </button>
          </div>
        </Form>
      </ContentCompanyInfos>
    </Container>
  );
};

export default CompanyInfo;
