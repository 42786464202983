import { parseISO, format, isBefore } from "date-fns";
import locale from "date-fns/locale/pt";
import { zonedTimeToUtc } from "date-fns-tz";

export function formateDate(date) {
  const parsedDate = parseISO(date);
  const formatDate = new Intl.DateTimeFormat("pt-BR", {
    timeZone: "UTC",
  }).format(parsedDate);

  return formatDate;
}

export function verifyLimit(date) {
  const currentDate = new Date().getTime();
  const formatDate = new Date(date).getTime();
  return formatDate < currentDate;
}
