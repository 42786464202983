import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { createTheme } from "@material-ui/core/styles";
import { Container } from "./menu.elements";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { Link } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#666666",
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, signOut } = useAuth();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(`companies/author/${user._id}`);
      setData(response.data.docs);
    }
    loadData();
  }, [user._id]);

  return (
    <div>
      <Container>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{
            color: "#666666",
            backgroundColor: "white",
            border: "unset",
            boxShadow: "unset",
            textTransform: "lowercase",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <PersonIcon
            style={{
              color: "#6FA824",
              marginRight: "15px",
            }}
          />
          {user.name}
          <KeyboardArrowDownIcon
            style={{
              color: "#6FA824",
              cursor: "pointer",
            }}
          />
        </Button>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <ListItemIcon>
              <i className="fas fa-edit"></i>
            </ListItemIcon>
            <Link
              to={`/atualizar-informacoes-usuario/${user._id}`}
              style={{ textDecoration: "none", color: "#666666" }}
            >
              <ListItemText primary="editar perfil" />
            </Link>
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemIcon>
              <i className="fas fa-sign-out-alt"></i>
            </ListItemIcon>
            <ListItemText
              primary="sair"
              onClick={signOut}
              style={{ color: "#666666" }}
            />
          </StyledMenuItem>
        </StyledMenu>
      </Container>
    </div>
  );
}
