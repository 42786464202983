import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "../../services/api";
import "./AccordionParcialResults.css";
import {
  ButtonKnowMore,
  Indicators,
  IndicatorsList,
  IndicatorsMoreDetails,
  IndiscatorsWeight,
  TypographyColumnContainer,
  TypographyContainer,
  TypographyText,
} from "./AccordionParcialResults.elements";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AccordionParcialResultsGovernanca({ indicadores }) {
  const classes = useStyles();
  const [data, setData] = React.useState([]);

  //load data of companies by ID
  React.useEffect(() => {
    async function loadData() {
      const IDcompany = localStorage.getItem("@legaro:companyID");
      const response = await api.get(`companies/${IDcompany}`);
      setData(response.data);
    }
    loadData();
  }, []);

  function loadIndicatorsCelebrate() {
    //Indicador 0
    if (indicadores[0]?.value == null && indicadores[1]?.value == null) {
      return (
        <TypographyContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>-</Indicators>
              <IndiscatorsWeight></IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <IndicatorsMoreDetails>
            Você precisa responder as questões para obter os resultados
          </IndicatorsMoreDetails>
          <ButtonKnowMore to="/escolha-da-fase">
            <p>Responder</p>
            <i
              style={{ marginLeft: "12px" }}
              className="fas fa-arrow-right"
            ></i>
          </ButtonKnowMore>
        </TypographyContainer>
      );
    }
    if (indicadores[0]?.value !== null && indicadores[1]?.value !== null) {
      return (
        <TypographyContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>{indicadores && indicadores[0]?.title}</Indicators>
              <IndiscatorsWeight>
                {indicadores && indicadores[0]?.value} %
              </IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>{indicadores && indicadores[1]?.title}</Indicators>
              <IndiscatorsWeight>
                {indicadores && indicadores[1]?.value} %
              </IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <ButtonKnowMore
            to={`/atualizar-questionario/${data?.company?._id}/governanca`}
          >
            <p>Atualizar</p>
            <i
              style={{ marginLeft: "12px" }}
              className="fas fa-arrow-right"
            ></i>
          </ButtonKnowMore>
        </TypographyContainer>
      );
    }
  }
  function loadIndicatorsToImprove() {
    //Indicador 0
    if (
      indicadores[indicadores.length - 1]?.value == null &&
      indicadores[indicadores.length - 2]?.value == null
    ) {
      return (
        <TypographyContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>-</Indicators>
              <IndiscatorsWeight></IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <IndicatorsMoreDetails>
            Você precisa responder as questões para obter os resultados
          </IndicatorsMoreDetails>
          <ButtonKnowMore to="/escolha-da-fase">
            <p>Responder</p>
            <i
              style={{ marginLeft: "12px" }}
              className="fas fa-arrow-right"
            ></i>
          </ButtonKnowMore>
        </TypographyContainer>
      );
    }
    if (
      indicadores[indicadores.length - 1]?.value !== null &&
      indicadores[indicadores.length - 2]?.value !== null
    ) {
      return (
        <TypographyContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>
                {indicadores && indicadores[indicadores.length - 1]?.title}
              </Indicators>
              <IndiscatorsWeight>
                {indicadores && indicadores[indicadores.length - 1]?.value} %
              </IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <TypographyColumnContainer>
            <IndicatorsList style={{ marginRight: "300px" }}>
              <Indicators>
                {indicadores && indicadores[indicadores.length - 2]?.title}
              </Indicators>
              <IndiscatorsWeight>
                {indicadores && indicadores[indicadores.length - 2]?.value} %
              </IndiscatorsWeight>
            </IndicatorsList>
          </TypographyColumnContainer>
          <ButtonKnowMore
            to={`/atualizar-questionario/${data?.company?._id}/governanca`}
          >
            <p>Atualizar</p>
            <i
              style={{ marginLeft: "12px" }}
              className="fas fa-arrow-right"
            ></i>
          </ButtonKnowMore>
        </TypographyContainer>
      );
    }
  }

  return (
    <div className={classes.root} style={{ width: "100%" }}>
      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "#FCFCFC",
          border: "1px solid #CCCCCC",
          justifyContent: "center",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#666666", width: "20px" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyText>
            <Typography
              className={classes.heading}
              style={{
                color: "#333333",
                fontSize: "15px",
                fontFamily: "Montserrat",
              }}
            >
              <i
                className="fas fa-arrow-up"
                style={{
                  color: "#6FA824",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              ></i>{" "}
              <strong>Indicadores para comemorar</strong>
            </Typography>
          </TypographyText>
        </AccordionSummary>
        <AccordionDetails
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ width: "100%", padding: "0 !important" }}>
            <TypographyContainer>
              {loadIndicatorsCelebrate()}
            </TypographyContainer>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "#FCFCFC",
          border: "1px solid #CCCCCC",
          justifyContent: "center",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#666666", width: "20px" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyText>
            <Typography
              className={classes.heading}
              style={{
                color: "#333333",
                fontSize: "15px",
                fontFamily: "Montserrat",
              }}
            >
              <i
                className="fas fa-arrow-down"
                style={{
                  color: "#F52D29",
                  marginBottom: "10px",
                  marginRight: "12px",
                }}
              ></i>
              <strong>Indicadores a melhorar</strong>
            </Typography>
          </TypographyText>
        </AccordionSummary>
        <AccordionDetails
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ width: "100%", padding: "0 !important" }}>
            <TypographyContainer>
              {loadIndicatorsToImprove()}
            </TypographyContainer>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
