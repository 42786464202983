import styled from "styled-components";
import { FaMagento } from "react-icons/fa";
import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
  background-color: #1e2022;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FooterSubscription = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
`;

export const FooterSubHeading = styled.p`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-bottom: 24px;
  font-size: 24px;
`;

export const FooterSubText = styled.p`
  margin-bottom: 24px;
  font-size: 20px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLinksContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 85%;
  justify-content: space-around;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  width: 20%;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinksWrapperHelp = styled.div`
  display: flex;
  width: 40%;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 10%;
  }
`;
export const FooterLinksWrapperHelpExport = styled.div`
  display: flex;
  width: 85%;
  justify-content: center;
  color: white;
  margin-bottom: 20px;
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    width: 100%;
    padding-left: 20px;
    margin-bottom: 10%;
  }

  @media screen and (max-width: 820px) {
    margin: 20px;
  }
`;

export const FooterLinkItemsHelp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 374px;
  margin-left: 30%;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    width: 100%;
    padding-left: 20px;
    margin-bottom: 10%;
  }

  @media screen and (max-width: 820px) {
    margin: 0;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
`;

export const FooterLinkSubTitle = styled.h2`
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 16px;
`;

export const FooterLinkSubSubTitle = styled.h1`
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const FooterLinkText = styled.p`
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-bottom: 0.5rem;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  padding: 0.7rem 0.7rem 0.7rem 0;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterLinkEmail = styled.a`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterLinkOut = styled(Link)`
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 13px;
  text-decoration: none;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.7rem 0.7rem 0.7rem 0;

  .help_page {
    font-family: "Montserrat", sans-serif;
  }

  &:hover {
    color: #999999;
  }
`;

export const FooterInformation = styled.div`
  color: #666666;
  border-color: #666666;
  margin-bottom: 2%;
  width: 85%;
  font-size: 14px;

  & p {
    padding-top: 2%;
  }
`;

export const SocialMedia = styled.section`
  width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 80%;
  margin: auto auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  img {
    width: 160px;
  }
`;

export const SocialIcon = styled(FaMagento)`
  margin-right: 10px;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
  font-family: "Montserrat", sans-serif;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled(Link)`
  color: #fff;
  font-size: 24px;
`;
