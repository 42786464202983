import styled from "styled-components";
import { FaMagento } from "react-icons/fa";
import { Link } from "react-router-dom";
//import { Link } from "react-scroll";
import { Container } from "../../globalStyles";

export const Nav = styled.nav`
  background: #f2f2f2;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;
  max-width: 80%;

  ${Container}

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    max-width: 100%;
  }
`;

export const NavLogo = styled(Link)`
  color: #444444;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  padding-left: 0;
  margin-left: 0;

  @media screen and (max-width: 400px) {
    img {
      height: 70%;
      margin-top: 5%;
    }
  }
`;

export const NavIcon = styled(FaMagento)`
  margin-right: 0.5rem;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1290px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    padding-right: 0.5rem;

    //background: #101522;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1290px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 90px;
    left: ${({ click }) => (click ? 0 : "-150%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #f2f2f2;
  }
`;

export const NavItem = styled.li`
  height: 65px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #6fa824;
  }

  @media screen and (max-width: 1290px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

export const NavOptions = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1290px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease;
    background: #f2f2f2;
  }
`;

export const NavItemBtn = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (max-width: 1290px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90px;
    top: 300px;
    position: relative;
  }

  @media screen and (max-width: 960px) {
    padding: 0;
    margin: 0;
    position: relative;
  }
`;

export const SignInAndOut = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
    top: 10px;
  }
`;

export const NavLinks = styled(Link)`
  color: #444444;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bold;

  @media screen and (max-width: 960px) {
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 1290px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #6fa824;
      transition: all 0.3s ease;
    }
  }

  Link {
    &:clicked {
      text-decoration: none;
      color: green !important;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 4px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
