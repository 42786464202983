import React, { useState, useEffect } from "react";
import {
  LockContainer,
  LockImage,
  Image,
  LockTitle,
  LockText,
  LockCardContainer,
  LockSubtitle,
  LockPageContainer,
  ButtonResult,
  LockContainerBlocked,
  ButtonResultBlocked,
  LockImageBlocked,
  LockImageContainer,
  LockResponsiveContainer,
  FaseHeader,
  FaseTitle,
  Container,
} from "./FaseCard.elements";
import { useHistory } from "react-router-dom";
import api from "../../services/api";

function getImageStage(stage, disable = false) {
  if (stage === "Basic-on")
    return "https://codexremote.com.br/esgimages/legaro/basic-verde.png";
  if (stage === "Standard-on" && disable)
    return "https://codexremote.com.br/esgimages/legaro/standard-verde.png";
  if (stage === "Standard-off" && !disable)
    return "https://codexremote.com.br/esgimages/legaro/standard-cinza.png";
  if (stage === "Premium-on" && disable)
    return "https://codexremote.com.br/esgimages/legaro/premium-verde.png";
  if (stage === "Premium-off" && !disable)
    return "https://codexremote.com.br/esgimages/legaro/premium-cinza.png";

  return "https://codexremote.com.br/esgimages/legaro/basic-verde.png";
}

function FaseCard({ stages = [] }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const takeID = localStorage.getItem("@legaro:companyID");

  const handleMoveTema = (stage, status) => {
    // if (status || stage.title === 'Standard' || stage.title === 'Premium') {
    //   history.push(`/selecionar-tema/${stage._id}`);

    // } else {
    history.push(`/mover-temas/${stage._id}`);
    // }
  };

  const handleAtualizacao = () => {
    history.push("/selecionar-tema");
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      const response = await api.get(`/companies/check/${takeID}`);
      setData(response.data);

      setLoading(false);
    }
    loadData();
  }, [takeID]);

  return (
    <div>
      <Container>
        <FaseHeader>
          <FaseTitle>Como obter seu score ESG?</FaseTitle>
        </FaseHeader>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Preparando as fases 🕓</span>
          </div>
        ) : (
          <LockPageContainer>
            {stages.map((stage) => (
              <LockCardContainer key={stage._id}>
                <LockImageContainer>
                  {(data.stage?.title === "Basic" &&
                    stage?.title === "Basic") ||
                  (data.stage?.title === "Basic" &&
                    data["Ambiental"] &&
                    data["Governanca"] &&
                    data["Social"] &&
                    data["Economico"] &&
                    stage?.title === "Standard") ||
                  (data.stage?.title === "Standard" &&
                    data["Ambiental"] &&
                    data["Governanca"] &&
                    data["Social"] &&
                    data["Economico"] &&
                    stage?.title === "Premium") ||
                  (data.stage?.title === "Standard" &&
                    (stage?.title === "Basic" ||
                      stage?.title === "Standard")) ||
                  (data.stage?.title === "Premium" &&
                    (stage?.title === "Basic" ||
                      stage?.title === "Standard" ||
                      stage?.title === "Premium")) ? (
                    <LockImage isActive>
                      <i class="fas fa-lock-open"></i>
                    </LockImage>
                  ) : (
                    <LockImage
                      isActive={
                        (data.stage?.title === "Basic" &&
                          stage?.title === "Basic") ||
                        (data.stage?.title === "Standard" &&
                          (stage?.title === "Basic" ||
                            stage?.title === "Standard")) ||
                        (data.stage?.title === "Premium" &&
                          (stage?.title === "Basic" ||
                            stage?.title === "Standard" ||
                            stage?.title === "Premium"))
                      }
                    >
                      <i class="fas fa-lock"></i>
                    </LockImage>
                  )}
                </LockImageContainer>
                <LockResponsiveContainer>
                  <LockContainer
                    isActive={
                      (data.stage?.title === "Basic" &&
                        stage?.title === "Basic") ||
                      (data.stage?.title === "Basic" &&
                        data["Ambiental"] &&
                        data["Governanca"] &&
                        data["Social"] &&
                        data["Economico"] &&
                        stage?.title === "Standard") ||
                      (data.stage?.title === "Standard" &&
                        data["Ambiental"] &&
                        data["Governanca"] &&
                        data["Social"] &&
                        data["Economico"] &&
                        stage?.title === "Premium") ||
                      (data.stage?.title === "Standard" &&
                        (stage?.title === "Basic" ||
                          stage?.title === "Standard")) ||
                      (data.stage?.title === "Premium" &&
                        (stage?.title === "Basic" ||
                          stage?.title === "Standard" ||
                          stage?.title === "Premium"))
                    }
                  >
                    <Image>
                      <img
                        src={getImageStage(
                          (data.stage?.title === "Basic" &&
                            stage?.title === "Basic") ||
                            (data.stage?.title === "Basic" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"] &&
                              stage?.title === "Standard") ||
                            (data.stage?.title === "Standard" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"] &&
                              stage?.title === "Premium") ||
                            (data.stage?.title === "Standard" &&
                              (stage?.title === "Basic" ||
                                stage?.title === "Standard")) ||
                            (data.stage?.title === "Premium" &&
                              (stage?.title === "Basic" ||
                                stage?.title === "Standard" ||
                                stage?.title === "Premium"))
                            ? `${stage.title}-on`
                            : `${stage.title}-off`,

                          (data.stage?.title === "Basic" &&
                            stage?.title === "Basic") ||
                            (data.stage?.title === "Basic" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"] &&
                              stage?.title === "Standard") ||
                            (data.stage?.title === "Standard" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"] &&
                              stage?.title === "Premium") ||
                            (data.stage?.title === "Standard" &&
                              (stage?.title === "Basic" ||
                                stage?.title === "Standard")) ||
                            (data.stage?.title === "Premium" &&
                              (stage?.title === "Basic" ||
                                stage?.title === "Standard" ||
                                stage?.title === "Premium"))
                            ? true
                            : false
                        )}
                        alt="LEGARO"
                        width="80px"
                      />
                    </Image>
                    <LockSubtitle>Score</LockSubtitle>
                    <LockTitle>{stage.title}</LockTitle>
                    <LockText>{stage.description}</LockText>
                  </LockContainer>
                  {(data.stage?.title === "Basic" &&
                    stage?.title === "Basic") ||
                  (data.stage?.title === "Basic" &&
                    data["Ambiental"] &&
                    data["Governanca"] &&
                    data["Social"] &&
                    data["Economico"] &&
                    stage?.title === "Standard") ||
                  (data.stage?.title === "Standard" &&
                    data["Ambiental"] &&
                    data["Governanca"] &&
                    data["Social"] &&
                    data["Economico"] &&
                    stage?.title === "Premium") ||
                  (data.stage?.title === "Standard" &&
                    (stage?.title === "Basic" ||
                      stage?.title === "Standard")) ||
                  (data.stage?.title === "Premium" &&
                    (stage?.title === "Basic" ||
                      stage?.title === "Standard" ||
                      stage?.title === "Premium")) ? (
                    <ButtonResult
                      onClick={() =>
                        handleMoveTema(
                          stage,
                          //Verificar se atualiza ou responde
                          (data.stage?.title === "Standard" &&
                            stage?.title === "Basic") ||
                            (data.stage?.title === "Premium" &&
                              (stage?.title === "Basic" ||
                                stage?.title === "Standard")) ||
                            (data.stage?.title === "Basic" &&
                              stage?.title !== "Standard" &&
                              stage?.title !== "Premium" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"]) ||
                            (data.stage?.title === "Standard" &&
                              stage?.title !== "Premium" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"]) ||
                            (data.stage?.title === "Premium" &&
                              data["Ambiental"] &&
                              data["Governanca"] &&
                              data["Social"] &&
                              data["Economico"])
                        )
                      }
                    >
                      {(data.stage?.title === "Standard" &&
                        stage?.title === "Basic") ||
                      (data.stage?.title === "Premium" &&
                        (stage?.title === "Basic" ||
                          stage?.title === "Standard")) ||
                      (data.stage?.title === "Basic" &&
                        stage?.title !== "Standard" &&
                        stage?.title !== "Premium" &&
                        data["Ambiental"] &&
                        data["Governanca"] &&
                        data["Social"] &&
                        data["Economico"]) ||
                      (data.stage?.title === "Standard" &&
                        stage?.title !== "Premium" &&
                        data["Ambiental"] &&
                        data["Governanca"] &&
                        data["Social"] &&
                        data["Economico"]) ||
                      (data.stage?.title === "Premium" &&
                        data["Ambiental"] &&
                        data["Governanca"] &&
                        data["Social"] &&
                        data["Economico"])
                        ? "Atualizar"
                        : "Responder"}
                      <i className="fas fa-arrow-right"></i>
                    </ButtonResult>
                  ) : (
                    <ButtonResult
                      style={{ backgroundColor: "#C1C1C1", cursor: "unset" }}
                    >
                      <i class="fas fa-lock"></i>
                    </ButtonResult>
                  )}
                </LockResponsiveContainer>
              </LockCardContainer>
            ))}
          </LockPageContainer>
        )}
      </Container>
    </div>
  );
}

export default FaseCard;
