import styled, { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
 }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  font-family: "Montserrat", sans-serif;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
    background: ${({ isDisable }) => (isDisable ? "#999" : "#6FA824")};
  }

  background: ${({ isDisable }) => (isDisable ? "#999" : "#6FA824")};
  color: "#FFF";

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

export const ButtonScore = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "#6FA824" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "20px 50px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin-top: 5%;
  white-space: normal;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#6FA824" : "#4B59F7")};
  }

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const ButtonLogin = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "none" : "#0467FB")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #6fa824;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: 1px solid #6fa824;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const ButtonMoveAndAnswer = styled.button`
  font-family: "Montserrat", sans-serif;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "14px")};
  color: #fff;

  border-radius: 0 0 5px 5px;
  width: 275px;
  height: 51px;
  background-color: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  font-weight: bold;
  outline: none;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;

  border: 1px solid #6fa824;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

export const ButtonMoveAndAnswerEconomic = styled.button`
  font-family: "Montserrat", sans-serif;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "14px")};
  color: #fff;

  border-radius: 0 0 5px 5px;
  width: 275px;
  height: 51px;
  background-color: ${({ primary }) => (primary ? "#808080" : "#808080")};
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
  padding-bottom: 0;
  border: 1px solid #808080;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
`;

export const Closewindow = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  cursor: pointer;
`;

export default GlobalStyle;
