import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1290px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 60%;

  @media screen and (max-width: 1290px) {
    width: 75%;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const PerguntasRespostas = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #fff;
  padding: 4% 0;
  padding-top: 6%;
`;

export const TextoLateral = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333333;
  width: 30%;
  margin-right: 10%;

  @media screen and (max-width: 1290px) {
    width: 40%;
  }

  @media screen and (max-width: 960px) {
    margin-left: 6%;
    width: 100%;
  }

  h2 {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 3%;
    font-family: "Montserrat", sans-serif;

    @media screen and (max-width: 1290px) {
      font-size: 35px;
    }
  }
`;

export const Texto = styled.div`
  color: #333333;

  @media screen and (max-width: 1290px) {
    margin-bottom: 5%;
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 5%;
  }
`;

export const Questoes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 145px 0 0;
  color: #333333;
`;

export const NavAccordion = styled(Link)`
  background-color: #f2f4f0;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin: 25px 0 0 0px;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    background-color: #ccc;
  }
`;

export const Panel = styled.div`
  padding: 0 18px;
  background-color: #f2f4f0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-radius: 0 0 20px 20px;
`;

export const FaleComAGente = styled.div`
  display: flex;
  margin-top: 30px;
  padding-bottom: 50px;
  align-items: start;
  width: 60%;

  @media screen and (max-width: 1290px) {
    align-items: flex-start;
    padding-left: 5%;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }
`;

export const HelpButtons = styled.div`
  display: flex;
  margin-top: 30px;
  padding-bottom: 50px;
  text-align: center;
  justify-content: center;
  width: 100%;

  h2 {
    color: #333333;
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    width: 80%;
    font-weight: 500;
    margin-bottom: 3%;
  }

  @media screen and (max-width: 1290px) {
    align-items: flex-start;
    padding-left: 5%;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    display: flex;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  button span {
    color: #4b4b4b;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    background-color: none;
    border: none !important;
    color: #707070;
    text-decoration: underline;
    text-align: center;

    @media screen and (max-width: 490px) {
      width: 80%;
    }
  }
`;

export const Buttons = styled.div``;

export const FormTitulo = styled.div`
  color: #333333;
  width: 90%;

  @media screen and (max-width: 1290px) {
    width: 60%;
    align-items: left;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: left;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    width: 80%;
    font-weight: 500;
    margin-bottom: 3%;

    @media screen and (max-width: 1290px) {
      font-size: 30px;
      width: 80%;
      align-items: left;
      margin-left: 15%;
    }

    @media screen and (max-width: 960px) {
      font-size: 30px;
      width: 80%;
      align-items: left;
      margin-left: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 15px;
    font-family: "Montserrat", sans-serif;
    width: 75%;

    @media screen and (max-width: 1290px) {
      margin-bottom: 3%;
      margin-left: 15%;
      width: 70%;
    }

    @media screen and (max-width: 960px) {
      margin-bottom: 3%;
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const Formulario = styled.div`
  color: #333333;
  width: 100%;

  @media screen and (max-width: 1290px) {
    width: 100%;
    align-items: center;
    margin-right: 3%;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: left;
    margin-right: 10%;
  }
`;

export const Legend = styled.div`
  color: #333333;
  margin-bottom: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
`;

export const DivForm = styled.div``;

export const LabelFormulario = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333333;
`;

export const InputFormulario = styled.div`
  input,
  select,
  textarea {
    padding-left: 15px;
    color: #707070;
    height: 35px;
    width: 100%;
    margin-bottom: 25px;
  }

  textarea {
    height: 100px;
    padding-top: 15px;
    resize: vertical;
  }
`;

// Search Area

export const SearchArea = styled.div`
  width: 100%;
  height: 30vh;
  background-color: #f6f6f6;
`;
export const TextIntro = styled.div`
  color: #333333;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: center;
  padding-top: 3%;
`;
export const InputArea = styled.div`
  display: flex;
  justify-content: center;

  input {
    width: 968px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50px;
    border: none;
    align-items: center;
    margin-top: 3%;
    padding-left: 2%;
    color: #a1a1a1;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
  }

  input:focus {
    outline: none;
  }
`;
export const DoubtSpace = styled.div`
  color: red;
`;
