import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import { Container, ButtonMoveAndAnswer } from "../../globalStyles";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import {
  ScoreSection,
  ScoreWrapper,
  ScoreHeading,
  ScoreContainer,
  ScoreCardInfo,
  ScoreCardIcon,
  ScoreCardPlan,
  ScoreCardCost,
  ScoreCardFeatures,
  ScoreCardFeature,
  ScoreCardAnswer,
  Checked,
  ButtonArea,
  ScoreCardContainer,
} from "./ScoreSelect.elements";
import { GoCheck } from "react-icons/go";
import sortArray from "sort-array";
import api from "../../services/api";

//import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";

const modifyTitlte = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governança",
  Economico: "Econômico",
};

const modifyTitlte2 = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governanca",
  Economico: "Economico",
};

function Score() {
  const params = useParams();

  const questionItems = JSON.parse(
    localStorage.getItem("@legaro:categories")
  ).map((item) => {
    if (item._id === "614ce5e4a9ed10f8b82ed487") {
      return {
        ...item,
        weight: 1,
      };
    }
    return item;
  });

  const takeID = localStorage.getItem("@legaro:companyID");
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState('')

  const [data, setData] = useState({});

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`/companies/check/${takeID}`);
      setData(response.data);

      const {data: myStage} = await api.get(`stages/${params.stageId}`)
      setStage(myStage.title)
      setLoading(false);
    }
    loadData();
  }, [params.stageId, takeID]);

  //console.log('teste', addValue)

  const history = useHistory();

  /* const handleBack = () => {
    history.push("/dashboard");
  }; */

  const handleBackMoveCard = () => {
    history.push(`/mover-temas/${params.stageId}`);
  };

  const takeIdtoNext = (something) => {
    localStorage.setItem("@legaro:priority", something);
  };

  //const NewValue = localStorage.getItem("@legaro:theme")

  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <Container>
        <HeaderAfterLogin />
        {/*<CloseHeading>
          <ArrowBackIcon
            onClick={() => handleBackMoveCard()}
            style={{ cursor: "pointer" }}
          />
          <Closewindow onClick={() => handleBack()}>
            <strong>X</strong>
          </Closewindow>
        </CloseHeading>*/}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Verificando os temas...</span>
          </div>
        )}
        <ScoreHeading>
          Selecione um <br />
          tema abaixo:
        </ScoreHeading>
        <ScoreSection>
          <ScoreWrapper>
            <ScoreContainer>
              {sortArray(questionItems, {
                by: "weight",
                order: "desc",
              }).map(
                (
                  { _id, title, label, priority, image, description },
                  index
                ) => (
                  <ScoreCardAnswer key={index}>
                    {title === modifyTitlte2[title] && data[title] &&  (data.stage.title === stage ||
                    (data.stage.title === 'Standard' && stage==='Basic') ||
                    (data.stage.title === 'Premium' && (stage==='Basic' || stage==='Premium')) ||
                    (data.stage.title === 'Premium' &&  data["Ambiental"] && data["Governanca"] && data["Social"] && data["Economico"])
                    ) && (
                      <Checked>
                        <GoCheck color="#ffffff" size={20} />
                      </Checked>
                    )}
                    <ScoreCardInfo>
                      <ScoreCardContainer>
                        <ScoreCardIcon>
                          <img src={image} alt="Ambiental" />
                        </ScoreCardIcon>
                        <ScoreCardPlan>Tema {index + 1}</ScoreCardPlan>
                        <ScoreCardCost>{modifyTitlte[title]}</ScoreCardCost>
                        <ScoreCardFeatures>
                          <ScoreCardFeature>{description}</ScoreCardFeature>
                        </ScoreCardFeatures>
                      </ScoreCardContainer>
                      {!loading && (
                        <ButtonMoveAndAnswer
                          onClick={() => takeIdtoNext(priority)}
                        >
                          {(title === modifyTitlte2[title] && data[title] && (data.stage.title === stage))   ? (
                            <Link to={
                              title === modifyTitlte2[title] && data[title] && (data.stage.title === stage ||
                                (data.stage.title === 'Standard' && stage==='Basic') ||
                                (data.stage.title === 'Premium' && (stage==='Basic' || stage==='Premium')) ||
                                (data.stage.title === 'Premium' &&  data["Ambiental"] && data["Governanca"] && data["Social"] && data["Economico"])    
                              ) ? 
                              `/atualizar-questionario/${takeID}/${modifyTitlte2[title]}` : 
                              `/questionario/${takeID}/${params.stageId}`}>
                              <div>
                                {title === modifyTitlte2[title] &&
                                data[title] && (data.stage.title === stage) ? (
                                  <strong>atualizar</strong>
                                ) : (
                                  <strong>responder</strong>
                                )}
                                <i className="fas fa-arrow-right"></i>
                              </div>
                            </Link>
                          ) : (
                            <Link to={
                              title === modifyTitlte2[title] && data[title] && (data.stage.title === stage ||
                                (data.stage.title === 'Standard' && stage==='Basic') ||
                                (data.stage.title === 'Premium' && (stage==='Basic' || stage==='Premium')) ||
                                (data.stage.title === 'Premium' &&  data["Ambiental"] && data["Governanca"] && data["Social"] && data["Economico"])    
                              ) ? 
                              `/atualizar-questionario/${takeID}/${modifyTitlte2[title]}` : 
                              `/questionario/${takeID}/${params.stageId}`}>
                              {title === modifyTitlte2[title] && data[title] && (data.stage.title === stage ||
                                (data.stage.title === 'Standard' && stage==='Basic') ||
                                (data.stage.title === 'Premium' && (stage==='Basic' || stage==='Premium')) ||
                                (data.stage.title === 'Premium' &&  data["Ambiental"] && data["Governanca"] && data["Social"] && data["Economico"])    
                              )  ? (
                                <strong>atualizar</strong>
                              ) : (
                                <strong>responder</strong>
                              )}
                              <i className="fas fa-arrow-right"></i>
                            </Link>
                          )}
                        </ButtonMoveAndAnswer>
                      )}
                    </ScoreCardInfo>
                  </ScoreCardAnswer>
                )
              )}
            </ScoreContainer>
          </ScoreWrapper>
        </ScoreSection>
        <ButtonArea onClick={() => handleBackMoveCard()}>
          reordenar temas
        </ButtonArea>
      </Container>
    </IconContext.Provider>
  );
}
export default Score;
