import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const ImagemConclusaoTema = styled.div`
  height: 332px;

  h2 {
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #333333;
    margin-top: 5%;
  }
`;
export const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  background-color: #eeeeee;
  height: 62px;

  hr {
    color: #6fa824;
  }
`;
