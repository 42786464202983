export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "",
  headline: "Implemente o ESG na sua empresa de forma simples e ágil",
  description: "Implemente o ESG na sua empresa de forma simples e ágil",
  buttonLabel: "QUERO OBTER MEU DIAGNÓSTICO ESG",
  buttonLink: "/",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  className: "lazy",
  alt: "Inicio",
  start: "",
};

export const homeObjTwo = {
  secondary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Olá, somos a Codex!",
  description:
    "Desenvolvemos soluções tecnológicas especializadas que combinam sistemas de informação, consultoria setorial, especialistas em geotecnologia e as melhores práticas de gestão para apoiar nossos clientes de forma integrada, atendendo os mais diversos processos de negócios.",
  buttonLabel: "saiba mais",
  buttonLink: "/somos-a-codex",
  imgStart: "",
  img: require("../../images/Home3.webp"),
  className: "lazy",
  alt: "Olá, somos a Codex!",
  start: "",
};

export const homeObjThree = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Entenda o que é ESG e quais os benefícios para sua empresa.",
  description:
    "ESG é o termo em inglês que significa Environmental, Social and Governance e que corresponde às práticas ambientais, sociais e de governança de uma organização, cidade, estado ou federação. Atuar de acordo com os padrões ESG amplia a competitividade do setor empresarial, seja no mercado interno, seja no exterior. As práticas ESG ainda são consideradas indicação de solidez, custos mais baixos, melhor reputação de sua marca e maior resiliência em meio às incertezas e vulnerabilidades.",
  buttonLabel: "saiba mais",
  buttonLink: "/o-que-e-esg",
  imgStart: "start",
  img: require("../../images/Home1.webp"),
  className: "lazy",
  alt: "Entenda o que é ESG e quais os benefícios para sua empresa.",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Uma plataforma, inúmeros resultados.",
  description:
    "Na Legaro você pode realizar o diagnóstico de responsabilidade socioambiental e de governança da sua empresa por meio da pontuação ESG, que possibilita o controle e o monitoramento dos indicadores ambientais, sociais, econômicos e de governança corporativa. Além disso, com os dados obtidos, é possível antecipar e mitigar riscos, o que resulta na captura de grandes oportunidades que agregam valor e competitividade ao seu negócio.",
  buttonLabel: "saiba mais",
  buttonLink: "/uma-plataforma",
  imgStart: "start",
  img: require("../../images/Home2.webp"),
  className: "lazy",
  alt: "Uma plataforma, inúmeros resultados.",
  start: "true",
};

export const homeObjFive = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Seja nosso parceiro!",
  description:
    "Quer ser parte de um projeto que visa fazer a diferença no mundo? Seu lugar é ao nosso lado! Buscamos instituições que queiram construir uma metodologia ESG e que apoiem a divulgação da Legaro. O intuito da nossa parceria é capacitar pessoas quanto às práticas ESG e fornecer assistência e direcionamento sobre a importância das mesmas. Saiba mais sobre a nossa parceria!!",
  buttonLabel: "saiba mais",
  buttonLink: "/seja-parceiro",
  imgStart: "",
  img: require("../../images/Home0.webp"),
  className: "lazy",
  alt: "Seja nosso parceiro!",
  start: "",
};
