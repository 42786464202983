import styled from "styled-components";

export const ContainerQuestion = styled.div`
  margin-bottom: 3%;
  height: 85vh;

  /* border: 1px solid red; */
`;
export const Container = styled.div`
  /* width: 100%; */
`;
export const ButtonQuestion = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 11%;
  padding-left: 9.5%;
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: white;

  button {
    border-radius: 30px;
    white-space: nowrap;
    padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
    font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    height: 51px;
    margin-top: 30px;
    margin-bottom: 30px;

    /* @media screen and (max-width: 960px) {
      width: 100%;
    } */
  }

  .btnVoltar {
    background: ${({ primary }) => (primary ? "none" : "transparent")};
    color: #cccccc;
    border: 1px solid #cccccc;
    width: 208px;
  }

  .btnPularPergunta {
    background: ${({ primary }) => (primary ? "none" : "transparent")};
    color: #6fa824;
    border: 1px solid #6fa824;
    width: 258px;
  }

  .btnAvancar {
    background: ${({ primary }) => (primary ? "none" : "#6FA824")};
    color: #ffffff;
    border: 1px solid #6fa824;
    width: 208px;
  }
`;
