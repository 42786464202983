import React from "react";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
  homeObjFive,
} from "./Data";
import { InfoSection, Navbar, Footer, Indicaters } from "../../components";
import StartSection from "../../components/StartSection/StartSection";

function Home() {
  return (
    <div className="container">
      <Navbar />
      <StartSection {...homeObjOne} />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjFour} />
      <InfoSection {...homeObjFive} />
      <Indicaters />
      <Footer />
    </div>
  );
}

export default Home;
