import React from "react";
import { Link } from "react-router-dom";
import { Container, ButtonScore } from "../../globalStyles";
import { ScrollToTop } from "..";
import {
  InfoStart,
  StartColumn,
  SectionContent,
  TextContent,
  Title,
} from "./StartSection.elements";

function StartSection({ primary, lightBg, lightText, headline, buttonLabel }) {
  return (
    <>
      <InfoStart lightBg={lightBg}>
        <Container>
          <SectionContent>
            <StartColumn>
              <TextContent style={{ flexShrink: 1 }}>
                <Title lightText={lightText}>{headline}</Title>
                <Link to="/sign-up">
                  <ButtonScore fontBig primary={primary}>
                    <ScrollToTop />
                    <strong>{buttonLabel}</strong>{" "}
                    <i className="fas fa-angle-double-right"></i>
                  </ButtonScore>
                </Link>
              </TextContent>
            </StartColumn>
          </SectionContent>
        </Container>
      </InfoStart>
    </>
  );
}

export default StartSection;
