import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types";
import {useSelector, useDispatch} from 'react-redux'
import {addBooleanAnswers, deleteBooleanAnswer} from '../../store/answerReducers'

export const OptionRadioColumLine = ({id, idQuestion,indicatorId,  slug, categoryId, index, answerList, answer, currentId}) => {

  const booleanAnswers = useSelector((state) => state.booleanAnswers)
  const dispatch = useDispatch()
  const [answeredList, setAnsewedList] = useState([])

  useEffect(() => {
    const isAnswered = booleanAnswers.find(answer => answer.idQuestion === idQuestion )
    
    if (isAnswered) {
      const {answerList: answered} = isAnswered
  
      setAnsewedList(answered)
    }
  }, [booleanAnswers, idQuestion])

  const handleSelect = () => {

    const isAnswered = booleanAnswers.find(answer => answer.idQuestion === idQuestion )
    
    if (isAnswered) {
      const {answerList: answered} = isAnswered
      setAnsewedList(answered)

      dispatch(deleteBooleanAnswer({
        idQuestion
      }))
    }

    dispatch(addBooleanAnswers({
      idQuestion,
      indicatorId,
      categoryId,
      currentId: currentId,
      answerList
    }))
  }

  // fazer um if que compara se esta opcao de resposta esta na resposta escolhida, em caso verdadeiro
  // retorna um option com checked

  return (
    <label
      // id={`ambiental_question_${index}`}
      htmlFor={`${slug}_${id}`}
    >
      <input
        id={`${slug}_${id}`}
        name={`${slug}_${idQuestion}`}
        type="radio"
        // value={`ambiental_question_${id}`}
        checked={ answeredList[0] && answeredList[0]._id === answerList._id ? true : false}
        onChange={handleSelect}
      />
    <span>{answer}</span>
  </label>
  )
}

OptionRadioColumLine.propTypes = {
  id: PropTypes.string.isRequired,  
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  idQuestion: PropTypes.string.isRequired, 
  indicatorId: PropTypes.string,
  categoryId: PropTypes.string.isRequired, 
  answerList: PropTypes.any.isRequired, 
  answer: PropTypes.string.isRequired,
  currentId: PropTypes.string.isRequired
}