import React from "react";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import Chart from "./Chart";
import html2canvas from "html2canvas";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavLinks,
  Results,
  Footer,
  Left,
  Right,
  FooterContainer,
  AccordionOpen,
  AccordionTitle,
  AccordionIndicators,
  IndicatorsList,
  Indicators,
  IndiscatorsWeight,
  AccordionIndicatorsColumn,
  Page,
} from "./Export.elements";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";

import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

import { jsPDF } from "jspdf";

let a4WidthMm = 210,
  a4HeightMm = 297,
  scale = 1.25,
  elemId = "onePage";

const pxToMm = (px) => {
  return Math.floor(
    (px / document.getElementById("divToPrint").offsetHeight) * scale
  );
};

const mmToPx = (mm) => {
  return document.getElementById("divToPrint").offsetHeight * mm;
};

function ExportPage() {
  const { user, signOut } = useAuth();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(`companies/author/${user._id}`);
      setData(response.data.docs);
    }
    loadData();
  }, [user._id]);

  function downloads() {
    setTimeout(function () {
      downloadPagePDF();
    }, 400);
    /*setTimeout(function () {
      window.location.href = "./resultado-score-final";
    }, 3000);*/
  }

  const putHeaderFooter = (
    destCtx,
    origCtx,
    headHeight,
    footHeight,
    origWidth,
    origHeight,
    paperHeight
  ) => {
    destCtx.fillStyle = "white";
    destCtx.fillRect(0, 0, origWidth, origHeight);
    destCtx.putImageData(
      origCtx.getImageData(0, 0, origWidth, headHeight),
      0,
      0
    );
    destCtx.putImageData(
      origCtx.getImageData(0, 0, origWidth, footHeight),
      0,
      0
    );
  };

  const downloadPagePDF = () => {
    //const obj = window.onload,
    const input = document.getElementById(elemId),
      header = document.getElementById("docHeader"),
      footer = document.getElementById("docFooter");

    //obj.style.display = "none";

    const inputWidthPx = input.offsetWidth;
    const inputHeightPx = input.offsetHeight;
    const inputHeightMm = pxToMm(inputHeightPx);
    const a4HeightPx = mmToPx(a4HeightMm);
    const numPages =
      inputHeightMm <= a4HeightMm
        ? 1
        : Math.floor(inputHeightMm / a4HeightMm) + 1;

    let pdf = new jsPDF("p", "mm", "a4"),
      cutCanvas = document.createElement("canvas"),
      cutCtx = cutCanvas.getContext("2d");
    cutCanvas.width = inputWidthPx;
    cutCanvas.height = a4HeightPx;

    html2canvas(input).then((canvas) => {
      const ctx = canvas.getContext("2d");
      let page = 0,
        offset = 0,
        headH = header.clientHeight + 15,
        footH = footer.clientHeight + 15,
        HeadFoot = headH + footH,
        newH = a4HeightPx - HeadFoot,
        height = inputHeightPx < a4HeightPx ? inputHeightPx - footH : newH;

      putHeaderFooter(
        cutCtx,
        ctx,
        header.clientHeight,
        footer.clientHeight,
        inputWidthPx,
        inputHeightPx,
        a4HeightPx
      );
      cutCtx.putImageData(
        ctx.getImageData(0, headH, inputWidthPx, height),
        0,
        headH
      );

      //document.body.appendChild(cutCanvas)

      pdf.addImage(
        cutCanvas.toDataURL("image/jpeg", 1),
        "JPEG",
        0,
        0,
        a4WidthMm + 30,
        a4HeightMm
      );
      page++;

      while (page < numPages) {
        //console.log(page);

        offset = page * newH;
        height =
          inputHeightPx - HeadFoot < (page + 1) * newH
            ? inputHeightPx - (page * newH + HeadFoot)
            : newH;
        if (height > 0) {
          pdf.addPage("p", "mm", "a4");

          putHeaderFooter(
            cutCtx,
            ctx,
            header.clientHeight,
            footer.clientHeight,
            inputWidthPx,
            inputHeightPx,
            a4HeightPx
          );

          cutCtx.putImageData(
            ctx.getImageData(0, headH + offset, inputWidthPx, height),
            0,
            headH
          );

          pdf.addImage(
            cutCanvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0,
            3,
            a4WidthMm + 30,
            0
          );
        }
        page++;
      }

      pdf.save("legaro.pdf");
      //obj.style.display = "inherit";
    });
  };

  return (
    <div>
      <div id="divToPrint" style={{ height: "2mm" }} />
      <Page singleMode={false} id="onePage" onLoad={downloads}>
        <div className="print" id="elemId">
          <IconContext.Provider value={{ color: "#101522" }}>
            <Nav id="docHeader">
              <NavbarContainer>
                <NavLogo>
                  <img
                    src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.png"
                    alt="LEGARO"
                    style={{
                      width: "200px",
                    }}
                    data-html2canvas-ignore={false}
                  />
                </NavLogo>

                <NavMenu>
                  {data[0] && (
                    <NavLinks style={{ cursor: "default" }}>
                      <Link
                        to="#"
                        style={{
                          color: "#666666",
                          fontWeight: "bold",
                          textDecoration: "none",
                          cursor: "default",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BusinessIcon
                          style={{
                            color: "#6FA824",
                            marginRight: "15px",
                            cursor: "default",
                          }}
                        />
                        {data[0].name}
                      </Link>
                    </NavLinks>
                  )}
                  <NavLinks style={{ cursor: "default" }}>
                    <Link
                      to="#"
                      style={{
                        color: "#666666",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "default",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PersonIcon
                        style={{
                          color: "#6FA824",
                          marginRight: "15px",
                        }}
                      />
                      {user.name}
                    </Link>
                  </NavLinks>
                  <NavLinks style={{ cursor: "default" }}>
                    <Link
                      to="#"
                      style={{
                        color: "#666666",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "default",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        className="far fa-calendar-alt"
                        style={{
                          color: "#6FA824",
                          marginRight: "15px",
                        }}
                      ></i>
                      21/10/2021 às 14:45
                    </Link>
                  </NavLinks>
                </NavMenu>
              </NavbarContainer>
            </Nav>
          </IconContext.Provider>
          <Results>
            <div className="containerAllResults">
              <div className="result-container">
                <div className="session-score">
                  <div className="score-graph">
                    <Chart />
                  </div>
                  <div className="score-description">
                    <div className="score-title">
                      <div className="score-subtitle-alta">
                        <span className="text-score-subtitle">Resultado:</span>{" "}
                        alta conformidade ESG
                      </div>
                    </div>
                    {/*<button
                      data-html2canvas-ignore={true}
                      className="close pdf"
                      title="Baixar PDF"
                      onClick={downloadPagePDF}
                    >
                      baixar
                    </button>*/}
                    <div className="score-text">
                      A faixa do Score ESG obtida indica que a empresa aplica
                      políticas de ESG condizentes com definições e normas
                      nacionais e internacionais.
                    </div>
                    <br />
                  </div>
                </div>

                {data && (
                  <div className="session-themes">
                    <div className="theme-card" style={{ boxShadow: "none" }}>
                      <div className="theme-icon">
                        <img
                          src="https://codexremote.com.br/esgimages/legaro/icon06.png"
                          height="55px"
                          alt="Ambiental"
                        />
                      </div>
                      <div className="theme-number">Tema 1</div>
                      <div className="theme-title">Ambiental</div>
                      <div className="mostraScore">
                        <span className="score-theme-high">XX</span>
                        <span className="scoreTotal-high">de XX</span>
                      </div>
                      <br />
                    </div>
                    <div className="theme-card" style={{ boxShadow: "none" }}>
                      <div className="theme-icon">
                        <img
                          src="https://codexremote.com.br/esgimages/legaro/icon03.png"
                          height="55px"
                          alt="Social"
                        />
                      </div>
                      <div className="theme-number">Tema 2</div>
                      <div className="theme-title">Social</div>
                      <div className="mostraScore">
                        <span className="score-theme-high"> XX</span>
                        <span className="scoreTotal-high">de XX</span>
                      </div>
                      <br />
                    </div>
                    <div className="theme-card" style={{ boxShadow: "none" }}>
                      <div className="theme-icon">
                        <img
                          src="https://codexremote.com.br/esgimages/legaro/icon12.png"
                          height="55px"
                          alt="Governança"
                        />
                      </div>
                      <div className="theme-number">Tema 3</div>
                      <div className="theme-title">Governança</div>
                      <div className="mostraScore">
                        <span className="score-theme-high">XX</span>
                        <span className="scoreTotal-high">de XX</span>
                      </div>
                      <br />
                    </div>
                    <div className="theme-card" style={{ boxShadow: "none" }}>
                      <div className="theme-icon">
                        <img
                          src="https://codexremote.com.br/esgimages/legaro/icon09.png"
                          height="55px"
                          alt="Econômico"
                        />
                      </div>
                      <div className="theme-number">Tema 4</div>
                      <div className="theme-title">Econômico</div>
                      <div className="mostraScore">
                        <span className="score-theme-high">XX</span>
                        <span className="scoreTotal-high">de XX</span>
                      </div>
                      <br />
                    </div>
                  </div>
                )}

                <div className="session-themes-score">
                  <div className="theme-item" id="sectionAmbiental">
                    <div className="theme-actions">
                      <div className="theme-item-number">Tema 1</div>
                      <div className="theme-item-title">Ambiental</div>
                      <div className="theme-container-links"></div>
                    </div>
                    <div className="theme-score-container">
                      <AccordionOpen style={{ marginBottom: "2rem" }}>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-up fa-lg"
                            style={{
                              color: "#6FA824",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores para comemorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                      <AccordionOpen>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-down fa-lg"
                            style={{
                              color: "#F52D29",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores a melhorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                    </div>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #CCCCCC",
                    }}
                  />
                  <div className="theme-item" id="sectionSocial">
                    <div className="theme-actions">
                      <div className="theme-item-number">Tema 2</div>
                      <div className="theme-item-title">Social</div>
                      <div className="theme-container-links"></div>
                    </div>
                    <div className="theme-score-container">
                      <AccordionOpen style={{ marginBottom: "2rem" }}>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-up fa-lg"
                            style={{
                              color: "#6FA824",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores para comemorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                      <AccordionOpen>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-down fa-lg"
                            style={{
                              color: "#F52D29",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores a melhorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                    </div>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #CCCCCC",
                    }}
                  />
                  <br />
                  <br />
                  <div className="theme-item" id="sectionGovernanca">
                    <div className="theme-actions">
                      <div className="theme-item-number">Tema 3</div>
                      <div className="theme-item-title">Governança</div>
                      <div className="theme-container-links"></div>
                    </div>
                    <div className="theme-score-container">
                      <AccordionOpen style={{ marginBottom: "2rem" }}>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-up fa-lg"
                            style={{
                              color: "#6FA824",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores para comemorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                      <AccordionOpen>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-down fa-lg"
                            style={{
                              color: "#F52D29",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores a melhorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                    </div>
                  </div>
                  <hr
                    style={{
                      border: "none",
                      borderTop: "1px solid #CCCCCC",
                    }}
                  />
                  <div className="theme-item" id="sectionEconomico">
                    <div className="theme-actions">
                      <div className="theme-item-number">Tema 4</div>
                      <div className="theme-item-title">Econômico</div>
                      <div className="theme-container-links"></div>
                    </div>
                    <div className="theme-score-container">
                      <AccordionOpen style={{ marginBottom: "2rem" }}>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-up fa-lg"
                            style={{
                              color: "#6FA824",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores para comemorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                      <AccordionOpen>
                        <AccordionTitle>
                          <i
                            className="fas fa-arrow-down fa-lg"
                            style={{
                              color: "#F52D29",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          ></i>
                          <h1>Indicadores a melhorar</h1>
                        </AccordionTitle>
                        <AccordionIndicators>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 1</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                          <AccordionIndicatorsColumn>
                            <IndicatorsList style={{ marginRight: "300px" }}>
                              <Indicators>Indicador 2</Indicators>
                              <IndiscatorsWeight>XX pt</IndiscatorsWeight>
                            </IndicatorsList>
                          </AccordionIndicatorsColumn>
                        </AccordionIndicators>
                      </AccordionOpen>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Results>
          <FooterContainer id="docFooter">
            <Footer>
              <Left>
                <img
                  src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.png"
                  alt="Legaro"
                  width="200px"
                />
              </Left>
              <Right>&copy; 2021 CODEX</Right>
            </Footer>
          </FooterContainer>
        </div>
      </Page>
    </div>
  );
}

export default ExportPage;
