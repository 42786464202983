//import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerGeral = styled.div`
  padding-top: 2%;
  margin: auto;
  width: 90%;
  margin-bottom: 30px;

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    text-align: center;
    color: #6fa824;
    margin-top: 15px;

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    text-align: center;
    color: #666666;
    margin-top: 15px;
    width: 100%;

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
`;

export const QuestionCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  @media screen and (max-width: 500px) {
    width: 98%;
  }
`;

export const QuestionBox = styled.div`
  background-color: #fff;
  border: 1px solid #0e0d0d17;
  border-left: 3px solid #6fa824;
  border-right: 3px solid #6fa824;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #0000001a;
  padding: 2%;
  margin-bottom: 1rem;
  padding-bottom: 0;
  width: 100%;
`;

export const QuestionBoxContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionNumber = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  padding-right: 1%;
  font-weight: bold;
`;

export const QuestionText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
`;

export const AnswerListContainer = styled.div`
  margin: 2rem 0 2rem 0;

  span {
    margin-left: 30px;
    color: #333333;
    line-height: 80%;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    display: flex;
    line-height: 25px;
    flex-wrap: wrap;

    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
  }
`;

export const AnswerList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  width: 100%;

  input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
  }

  input[type="checkbox"]:before {
    content: "";
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #e9e9e9;
  }

  input[type="checkbox"]:checked:before {
    content: "";
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #6fa824;
  }
  input[type="checkbox"]:checked:after {
    content: "";
    display: flex;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 7px;
  }

  input[type="radio"] {
    position: absolute;
    cursor: pointer;
  }

  input[type="radio"]:before {
    content: "";
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -1px;
    left: -6px;
    background-color: #e9e9e9;
    border-radius: 50px;
  }

  input[type="radio"]:checked:before {
    content: "";
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    left: -6px;
    background-color: #6fa824;
  }
  input[type="radio"]:checked:after {
    content: "";
    display: flex;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 3px;
  }
`;

export const ButtonQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 3%;
  width: 80%;
  text-decoration: none;

  button {
    width: 45%;
    padding: 15px;
    height: 51px;
    outline: none;
    background: #6fa824;
    border: 1px solid #6fa824;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: #fff;
    border-radius: 50px;
    font-size: 16px;
  }

  .returnbutton {
    background-color: transparent;
    color: #999999;
    border: 1px solid #999999;
  }
`;
