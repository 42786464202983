import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0 0 40px 0;

  span {
    border: 1px solid #dddddd;
    width: 60%;
    color: #dddddd;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 625px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 60%;

  @media screen and (max-width: 370px) {
    button {
      font-size: 12px;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
    }
  }
`;

export const NavbarQuestoes = styled.div`
  color: red;
`;

export const ImagemConclusaoTema = styled.div`
  width: 194px;
  height: 194px;
`;

export const TextoConclusao = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  h2 {
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: ${({ fontBig }) => (fontBig ? "30px" : "40px")};
    text-align: center;
    padding: 50px 0 30px 0;

    @media screen and (max-width: 965px) {
      font-size: 30px;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    @media screen and (max-width: 625px) {
      font-size: 30px;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
  }

  p {
    color: #666666;
    font-family: "Roboto", sans-serif;
    font-size: 27px;
    text-align: center;
    padding-bottom: 80px;

    @media screen and (max-width: 900px) {
      font-size: 22px;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    @media screen and (max-width: 625px) {
      font-size: 22px;
      justify-content: center;
      align-items: center;
      width: 70%;
    }
  }
`;

export const DetalhamentoScoreContainer = styled.div`
  width: 100%;
`;

export const DetalhamentoScore = styled.div`
  background-color: #eeeeee;
  height: 62px;
  color: #666666;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TopText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px;
`;

export const CloseWindow = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1%;
  margin-right: 14px;
  text-decoration: none;
  color: #333333;
`;

export const Progress = styled.div`
  background-color: #6fa824;
  width: 100%;
  height: 10px;
  margin-bottom: 150px;
`;
