import {createSlice} from '@reduxjs/toolkit'

const INITIAL_STATE= {
  booleanAnswers: [],
  multipleAnswers: []
}

const answerSlice = createSlice({
  name: 'answerLegaro',
  initialState: INITIAL_STATE,
  reducers: {
    addBooleanAnswers: (state, action) => {
      // state.booleanAnswers = action.payload
      return {
        ...state,
        booleanAnswers: [
          ...state.booleanAnswers,
          {
            idQuestion: action.payload.idQuestion,
            indicatorId: action.payload.indicatorId,
            categoryId: action.payload.categoryId,
            currentId: action.payload.currentId || '',
            answerList: [action.payload.answerList]
          }
        ]
      }
    },
    addMultipleAnswers: (state, action) => {
      return {
        ...state,
        multipleAnswers: [
          ...state.multipleAnswers,
          {
            idQuestion: action.payload.idQuestion,
            indicatorId: action.payload.indicatorId,
            categoryId: action.payload.categoryId,
            answerList: [action.payload.answerList]
          }
        ]
      }
    },
    addUpdateMultipleAnswers: (state, action) => {
      return {
        ...state,
        multipleAnswers: [
          ...state.multipleAnswers,
          {
            idQuestion: action.payload.idQuestion,
            indicatorId: action.payload.indicatorId,
            categoryId: action.payload.categoryId,
            answerList: action.payload.answerList
          }
        ]
      }
    },
    updateMultipleAnswers: (state, action) => {
      return {
        ...state,
        multipleAnswers: state.multipleAnswers.map(mult => {
          if (mult.idQuestion === action.payload.idQuestion) {
            return {
              idQuestion: action.payload.idQuestion,
              indicatorId: action.payload.indicatorId,
              categoryId: action.payload.categoryId,
              answerList: [...mult.answerList.filter(ans => ans.cantSelect === true),  action.payload.answerList]
            }
          }
          return mult
        })
      }
    },
    updateMultRestrAnswers: (state, action) => {
      return {
        ...state,
        multipleAnswers: state.multipleAnswers.map(mult => {
          if (mult.idQuestion === action.payload.idQuestion) {
            return {
              idQuestion: action.payload.idQuestion,
              indicatorId: action.payload.indicatorId,
              categoryId: action.payload.categoryId,
              answerList: [action.payload.answerList]
            }
          }
          return mult
        })
      }
    },
    deleteUpdateMultipleAnswer: (state, action) => {
      return {
        ...state,
        multipleAnswers: state.multipleAnswers.map(mult => {
          return {
            idQuestion: mult.idQuestion,
            indicatorId: mult.indicatorId,
            categoryId: mult.categoryId,
            answerList: mult.answerList.filter(answer => answer.answer !== action.payload.answer)
          }
        })
      }
    },
    deleteBooleanAnswer: (state, action ) => {
      return {
        ...state,
        booleanAnswers: state.booleanAnswers.filter(answer => answer.idQuestion !== action.payload.idQuestion)
      }
    },
    deleteMultipleAnswer: (state, action ) => {
      return {
        ...state,
        multipleAnswers: state.multipleAnswers.filter(answer => answer.idQuestion !== action.payload.idQuestion)
      }
    },
    deleteAllAnswers: (state, action) => {
      return {
        booleanAnswers: [],
        multipleAnswers: []
      }
    }
  }
})

export const {addBooleanAnswers, deleteBooleanAnswer, addMultipleAnswers, deleteAllAnswers, updateMultipleAnswers, deleteMultipleAnswer, deleteUpdateMultipleAnswer, updateMultRestrAnswers, addUpdateMultipleAnswers} = answerSlice.actions
export default answerSlice.reducer 