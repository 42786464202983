import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  ImagemConclusaoTema,
  TextoConclusao,
  ButtonContainer,
  DetalhamentoScore,
  TopText,
  CloseWindow,
  DetalhamentoScoreContainer,
  Progress,
} from "./Transition.elements";
//import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';
import { Button } from "../../globalStyles";
import api from "../../services/api";

function TransitionAmbiental() {
  const [data, setData] = useState({});
  const takeID = localStorage.getItem("@legaro:companyID");
  const [loading, setLoading] = useState(true);
  const params = useParams()

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`/companies/check/${takeID}`);
      // console.log(response.data)
      setData(response.data);
      setLoading(false);
    }
    loadData();
  }, [takeID]);

  return (
    <>
      {/*<HeaderAfterLogin />*/}
      <Container>
        <DetalhamentoScoreContainer>
          <DetalhamentoScore>
            <TopText>Detalhamento do Score » Tema Ambiental</TopText>
            <CloseWindow to="/dashboard">
              <strong>x</strong>
            </CloseWindow>
          </DetalhamentoScore>
          <Progress></Progress>
        </DetalhamentoScoreContainer>
        <ImagemConclusaoTema>
          <img
            src="https://codexremote.com.br/esgimages/legaro/icon13.png"
            alt="Ambiental"
          />
        </ImagemConclusaoTema>
        <TextoConclusao>
          <h2>Você concluiu o tema Ambiental!</h2>
          <p>
            Suas respostas estão salvas, e você tem a pontuação parcial.
            <br /> Para obter a pontuação final do ESG, responda os demais
            temas.
          </p>
          <span></span>
        </TextoConclusao>
        {!loading && (
          <ButtonContainer>
            {data["Ambiental"] &&
            data["Social"] &&
            data["Governanca"] &&
            data["Economico"] ? (
              <Link to="/resultado-score-final">
                <Button>
                  <strong>Ver resultado final</strong>{" "}
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </Link>
            ) : (
              <Link to={`/selecionar-tema/${params.stageId}`}>
                <Button>
                  <strong>responder outro tema</strong>{" "}
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </Link>
            )}
          </ButtonContainer>
        )}
      </Container>
    </>
  );
}
export default TransitionAmbiental;
