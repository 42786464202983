import React from "react";
import { Score } from "../../components";


function Temas() {
  return (
    <>
      <Score />
    </>
  );
}

export default Temas;
