import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetail from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import { Content, Title } from "./Style.Elements";
import { Form } from "@rocketseat/unform";

export const Review = ({ formData, navigation }) => {
  const { go } = navigation;
  const {
    name,
    email,
    phone,
    office,
    companyName,
    cnpj,
    state,
    city,
    sector,
    segment,
  } = formData;

  return (
    <>
      <Content>
        <Title>Confira os seus dados</Title>
        <RenderAccordion
          summary="User"
          go={go}
          details={[
            { "Nome Completo": name },
            { "E-mail": email },
            { Telefone: phone },
            { Cargo: office },
          ]}
        />
        <RenderAccordion
          summary="Company"
          go={go}
          details={[
            { "Nome da Empresa": companyName },
            { CNPJ: cnpj },
            { Estado: state },
            { Cidade: city },
            { Setor: sector },
            { Segment: segment },
          ]}
        />
        <RenderAccordion
          maxWidth="sm"
          summary="CompanyInfo"
          go={go}
          details={[{ Colaboradores: phone }, { Fatura: email }]}
        />
        <Form>
          <button type="submit" onClick={() => go("submit")}>
            ENVIAR
          </button>
        </Form>
      </Content>
    </>
  );
};

export const RenderAccordion = ({ summary, details, go }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetail>
      <div>
        {details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          const objValue = data[Object.keys(data)[0]];

          return (
            <ListItemText key={index}>{`${objKey}: ${objValue}`}</ListItemText>
          );
        })}
        <IconButton
          color="primary"
          component="span"
          onClick={() => go(`${summary.toLowerCase()}`)}
        >
          <EditIcon />
        </IconButton>
      </div>
    </AccordionDetail>
  </Accordion>
);
