import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Background,
  ModalImg,
  CloseWindow,
  Content,
  Title,
  Text,
} from "./Style.Elements";

export const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <CloseWindow>
          <Link to="/">x</Link>
        </CloseWindow>
        <Background>
          <ModalImg
            src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.png"
            alt="someone"
            className="lazy"
          />
        </Background>
        <Content>
          <Title className="privacyPolicy">Política de Privacidade</Title>
          <Text>
            <h1>1. Informações Gerais</h1>
            <p>
              A presente Política de Privacidade contém informações a respeito
              do modo como a Codex trata, total ou parcialmente, de forma
              automatizada ou não, os dados pessoais que porventura sejam
              necessários para os serviços realizados ou contratados. O objetivo
              é esclarecer aos interessados acerca dos tipos de dados que são
              coletados, dos motivos da coleta e da forma como o titular do dado
              poderá consultar, atualizar ou requerer a exclusão dessas
              informações. Essa Política de Privacidade foi elaborada em
              conformidade com a{" "}
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2014/lei/l12965.htm"
                target="blank"
              >
                Lei Federal n. 12.965
              </a>{" "}
              de 23 de abril de 2014 (Marco Civil da Internet) e com a{" "}
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
                target="blank"
              >
                Lei Federal n. 13.709{" "}
              </a>
              , de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais),
              doravante denominadas simplesmente como “legislação aplicável”.
              Essa Política de Privacidade poderá ser atualizada em decorrência
              de eventual atualização normativa, razão pela qual se convida o
              titular do dado a consultar periodicamente esse documento. A
              presente Política de Privacidade contém informações a respeito do
              modo como a Codex trata, total ou parcialmente, de forma
              automatizada ou não, os dados pessoais que porventura sejam
              necessários para os serviços realizados ou contratados. O objetivo
              é esclarecer aos interessados acerca dos tipos de dados que são
              coletados, dos motivos da coleta e da forma como o titular do dado
              poderá consultar, atualizar ou requerer a exclusão dessas
              informações. Essa Política de Privacidade foi elaborada em
              conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014
              (Marco Civil da Internet) e com a Lei Federal n. 13.709, de 14 de
              agosto de 2018 (Lei de Proteção de Dados Pessoais), doravante
              denominadas simplesmente como “legislação aplicável”. Essa
              Política de Privacidade poderá ser atualizada em decorrência de
              eventual atualização normativa, razão pela qual se convida o
              titular do dado a consultar periodicamente esse documento. O
              objetivo é esclarecer aos interessados acerca dos tipos de dados
              que são coletados, dos motivos da coleta e da forma como o titular
              do dado poderá consultar, atualizar ou requerer a exclusão dessas
              informações. O objetivo é esclarecer aos interessados acerca dos
              tipos de dados que são coletados, dos motivos da coleta e da forma
              como o titular do dado poderá consultar, atualizar ou requerer a
              exclusão dessas informações. Essa Política de Privacidade foi
              elaborada em conformidade com a Lei Federal n. 12.965 de 23 de
              abril de 2014 (Marco Civil da Internet) e com a Lei Federal n.
              13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados
              Pessoais), doravante denominadas simplesmente como “legislação
              aplicável”. Essa Política de Privacidade poderá ser atualizada em
              decorrência de eventual atualização normativa, razão pela qual se
              convida o titular do dado a consultar periodicamente esse
              documento. Essa Política de Privacidade foi elaborada em
              conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014
              (Marco Civil da Internet) e com a Lei Federal n. 13.709, de 14 de
              agosto de 2018 (Lei de Proteção de Dados Pessoais), doravante
              denominadas simplesmente como “legislação aplicável”. Essa
              Política de Privacidade poderá ser atualizada em decorrência de
              eventual atualização normativa, razão pela qual se convida o
              titular do dado a consultar periodicamente esse documento.
            </p>
            <h1>2. Princípios</h1>
            <p>
              A Codex, assim como seus Fornecedores, Prestadores de Serviços e
              Terceiros envolvidos se comprometem a cumprir as exigências
              previstas na legislação aplicável, em respeito aos seguintes
              princípios:
              <li>
                Os dados pessoais serão coletados, de forma adequada e limitada
                para finalidades determinadas, explícitas legítimas, não podendo
                ser tratados posteriormente de forma incompatível com essas
                finalidades (finalidade, adequação e necessidade);
              </li>
              <li>
                Os titulares de dados pessoais terão garantia de acesso,
                atualizações e informações sobre a realização do tratamento de
                seus dados pessoais (livre acesso, qualidade e transparência);
              </li>{" "}
              <li>
                Os dados pessoais serão tratados de forma segura, protegidos do
                tratamento não autorizado ou ilícito e contra a sua perda,
                destruição ou danificação acidental, adotando as medidas de
                segurança de dados adequadas (Segurança e prevenção).
              </li>
            </p>
            <h1>3. Tratamento de Dados Pessoais</h1>
            <p>
              A coleta de dados pessoais dependerá do consentimento do titular,
              sendo esse dispensável nas hipóteses abaixo:
              <li>
                Para o cumprimento de obrigação legal ou regulatória pelo
                controlador;{" "}
              </li>
              <li>
                Para a realização de estudos por órgão de pesquisa, garantida,
                sempre que possível, a anonimização dos dados pessoais;{" "}
              </li>
              <li>
                Quando necessário para a execução de contrato ou de
                procedimentos preliminares relacionados a contrato do qual seja
                parte o usuário, a pedido do titular dos dados;{" "}
              </li>
              <li>
                Para o exercício regular de direitos em processo judicial,
                administrativo ou arbitral;
              </li>{" "}
              <li>
                {" "}
                Para a tutela da saúde, em procedimento realizado por
                profissionais da área da saúde ou por entidades sanitárias;
              </li>{" "}
              <li>
                Quando necessário para atender aos interesses legítimos do
                controlador ou de terceiros, exceto no caso de prevalecerem
                direitos e liberdades fundamentais do titular dos dados que
                exijam a proteção dos dados pessoais.
              </li>
            </p>
            <h2>3.1 Dados Coletados</h2>
            <p>
              Dentre os vários titulares de dados com os quais a Codex se
              relaciona (acionistas, clientes, colaboradores, fornecedores e
              parceiros), podem ser coletados os dados pessoais conforme
              descritos abaixo:
              <li>Nome;</li>
              <li>Data de nascimento;</li>{" "}
              <li>Biometria (restrito a colaboradores);</li>
              <li>Reconhecimento facial (restrito a colaboradores);</li>{" "}
              <li>Endereço de e-mail;</li>
              <li>Endereço postal;</li>
              <li>Número de telefone;</li>
              <li>Número de CPF;</li>
              <li>Número de RG;</li>
              <li>Empresa, cargo/função;</li>{" "}
              <li>Dados relativos à sua localização.</li>
              Eventualmente, outros tipos de dados não previstos expressamente
              nesta Política de Privacidade poderão ser coletados.
            </p>
            <h3>3.1.1 Dados Coletados</h3>
            <p>
              Cuidados adicionais serão adotados para tratamentos de dado
              pessoal classificado como sensível, conforme estabelece a
              legislação vigente. São considerados dados sensíveis aqueles
              fornecidos pelos clientes da Codex, incluindo-se as bases de dados
              utilizadas em avaliações de oportunidades de negócio – desprovidas
              de instrumentos contratuais de prestação de serviços, tanto quanto
              as bases de dados utilizadas para prestação de serviços, mesmo que
              indiretamente, como por exemplo base de ativos de distribuidoras
              de energia elétrica que contenham ativos elétricos, não elétricos
              e de consumidores. Os dados sensíveis fornecidos pelos clientes
              podem incluir dados pessoais de terceiros, como por exemplo,
              consumidores de energia elétrica. Os dados sensíveis fornecidos
              pelos clientes não são dados coletados, mas sim disponibilizados e
              expostos exclusivamente para uso de acordo com o objeto social de
              cada unidade de negócio/empresa da Codex.
            </p>
            <h2>3.2 Meios de Coleta e Finalidades</h2>
            <h3>3.2.1 Dados informados de contato</h3>
            <p>
              Quando um cliente, visitante ou um prospect preenche um formulário
              online, visita o nosso site ou outras mídias sociais, ou nos
              contata por e-mail, telefone ou por mensagens, coletamos e
              armazenamos os dados fornecidos, dentre estes:
              <li>O nome e informações de contato;</li>
              <li>As informações da empresa, se houver;</li>{" "}
              <li>O caráter da comunicação;</li>{" "}
              <li>
                O intuito da interação e a ação que nós tomamos em resposta à
                solicitação;
              </li>{" "}
              <li>
                Qualquer ação que o contato toma (por exemplo, se ele se
                registra para participar de um evento oferecido, ou se solicita
                recebimento de documentações).
              </li>{" "}
              Nota: Os dados do cliente, visitante ou prospects poderão ser
              complementados através de coletas em mídias sociais ou em outras
              fontes digitais para análises e formação da base de prospecção. Os
              dados coletados são exclusivos para a finalidade de interação com
              o requisitante para fins comerciais e de promoção dos nossos
              produtos e serviços.
            </p>
            <h3>3.2.2 Dados relacionados à contratos firmados</h3>
            <p>
              Para o cumprimento de contratos de trabalho (colaboradores), de
              fornecimento, ou de prestação de serviços entre a Codex e
              terceiros, serão coletados e armazenados dados pessoais
              relacionados ou necessários à sua execução. Ficam comprometidas as
              partes envolvidas em tais formalizações, inclusive colaboradores,
              quanto à obediência integral e irrestrita à legislação aplicável e
              à presente Política, sob pena de quebra de contrato sem prejuízo
              de aplicação das penalidades legais cabíveis.
            </p>
            <h2>3.3 Finalidade do tratamento dos dados pessoais</h2>
            <p>
              Os dados pessoais coletados terão sempre por finalidade atender ao
              disposto no objeto das respectivas contratações, devendo
              facilitar, agilizar e cumprir os compromissos estabelecidos entre
              as Partes. O tratamento de dados pessoais para finalidades não
              previstas nesta Política de Privacidade somente ocorrerá mediante
              consentimento do titular do dado ou premissas estabelecidas no
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm"
                target="blank"
              >
                art. 7º da Lei Federal 13709/2018.
              </a>
            </p>
            <h2>3.4 Prazo de Conservação dos Dados Pessoais</h2>
            <p>
              Os dados pessoais coletados serão conservados conforme prazos
              definidos, exceto nos casos de retenção legal compulsória. É
              assegurado ao titular o direito de supressão dos dados antes do
              final do prazo acordado.
            </p>
            <h2>3.5 Compartilhamento dos Dados Pessoais</h2>
            <p>
              Os dados pessoais, por ocasião de qualquer relacionamento, sejam
              de trabalho ou de negócio, só serão compartilhados com terceiros a
              partir do consentimento do titular.
            </p>
            <h1>4. Direitos do titular</h1>
            <p>
              <li style={{ listStyleType: "none" }}>
                a) Não fornecer ou retirar o consentimento: Não fornecer ou
                retirar seu consentimento a qualquer momento, não comprometendo
                a licitude do tratamento de seus dados pessoais antes da
                retirada. A retirada do consentimento poderá ser feita pelo
                e-mail:{" "}
                <a type="email" href="mailto:dpo@img.com.br">
                  dpo@img.com.br
                </a>
                , ou por correio enviado ao seguinte endereço:{" "}
                <a
                  href="https://www.google.com.br/maps/search/Rua+Itaja%C3%AD,+80,+Sala+803+%E2%80%93+Condom%C3%ADnio+Centro+Empresarial+Taquari+%E2%80%93+CEP:+12.246-858./@-23.2109602,-45.9025151,17z/data=!3m1!4b1"
                  target="blank"
                >
                  Rua Itajaí, 80, Sala 803 – Condomínio Centro Empresarial
                  Taquari – CEP: 12.246-858.
                </a>
              </li>{" "}
              <li style={{ listStyleType: "none" }}>
                b) Transparência: Confirmar a existência do tratamento de seus
                dados pessoais, acessá-los e obter informações sobre as
                entidades públicas ou privadas com as quais os dados tenham sido
                compartilhados.
              </li>{" "}
              <li style={{ listStyleType: "none" }}>
                {" "}
                c) Correção: Solicitar que seus dados pessoais sejam completados
                e/ou corrigidos, conforme julgar necessário
              </li>{" "}
              <li style={{ listStyleType: "none" }}>
                d) Eliminação: Solicitar a eliminação de seus dados pessoais.
                Por exemplo, caso não seja mais necessário para os fins que
                foram originalmente coletados.
              </li>{" "}
              <li style={{ listStyleType: "none" }}>
                e) Portabilidade de dados: Em certas circunstâncias, pode
                solicitar a portabilidade dos dados para uma outra entidade,
                quando tecnicamente factível
              </li>{" "}
              <li style={{ listStyleType: "none" }}>
                f) Manifestar reclamações: Submeter reclamações à encarregada de
                dados (DPO) ou à autoridade supervisora (ANPD), caso suspeite
                que o tratamento de seus dados pessoais esteja em desacordo com
                a legislação vigente.
              </li>
            </p>
            <h1>
              5. Papéis e responsabilidades no tratamento dos dados pessoais
            </h1>
            <h2>5.1. Responsável pelo Tratamento dos Dados</h2>
            <p>
              O controlador (Codex) determina as finalidades, os mecanismos e
              assume a responsabilidade final pelos dados pessoais tratados na
              organização. Em algumas situações, a Codex atua como operador,
              tratando dados pessoais fornecidos por controladores externos
              (clientes), assumindo a responsabilidade de proteção e privacidade
              em atendimento às obrigações legais.
            </p>
            <h2>5.2. Encarregado de dados (DPO)</h2>
            <p>
              O profissional designado como encarregado de proteção de dados
              (DPO) tem a responsabilidade de informar, aconselhar e difundir a
              cultura de privacidade, assim como aceitar reclamações e
              comunicação de titulares, prestar esclarecimentos, adotar
              providências e interagir com a autoridade nacional. Na Codex a
              encarregada de proteção de dados é Ivone Mariko Masago, que poderá
              ser contatada pelo e-mail:{" "}
              <a type="email" href="mailto:dpo@img.com.br">
                dpo@img.com.br
              </a>
              . Sempre que implementado ou utilizado um mecanismo de coleta de
              dados conforme disposto nesta Política de Privacidade, deverá a
              empresa Codex informar ao DPO sobre o uso, funcionamento e
              vigência de tal mecanismo.
            </p>
            <h1>6. Segurança da Informação</h1>
            <p>
              A Codex, adota medidas de segurança técnicas e administrativas
              aptas a proteger os dados pessoais de acessos não autorizados e de
              situações acidentais de destruição, perda, alteração, comunicação
              ou qualquer forma de tratamento inadequado ou ilícito. Seguimos os
              padrões em gerenciamento de segurança da informação para proteger
              informações confidenciais, tais como informações financeiras,
              propriedade intelectual e qualquer outro dado pessoal. As
              comunicações com nossos servidores, são executadas com a
              tecnologia SSL (Secure Socket Layer), garantindo que os dados
              estejam protegidos contra acessos indevidos. Nossas assinaturas
              digitais, análise da integridade de um arquivo oriundo da
              internet, chaves de servidores de internet, conexões de VPN’s e
              servidores clientes, utiliza autenticação de função hash é um
              algoritmo matemático para a criptografia. Os sistemas corporativos
              que estão na nuvem e servidor de dados tem controle por grupos e
              autenticação por domínio. Nossos servidores WEB possuem
              dispositivos contra invasão tais como IPS (Intrusion Prevention
              System) e Firewalls de Rede. A Codex se compromete, ainda, a
              comunicar ao titular e à autoridade nacional, em prazo razoável,
              os casos de violações que tenham potenciais de causar risco ou
              dano relevantes.
            </p>
            <h1>7. Fundamento Jurídico Para o Tratamento dos Dados Pessoais</h1>
            <p>
              Ao “Aceitar os Termos de Condições” da presente Política, o
              colaborador ou parceiro de negócio (clientes, fornecedores,
              prestadores de serviços) está consentindo com a presente Política
              de Privacidade.
            </p>
            <h1>8. Do direito aplicável e do foro</h1>
            <p>
              Para a solução das controvérsias decorrentes do presente
              instrumento, será aplicado integralmente o Direito brasileiro. Os
              eventuais litígios deverão ser apresentados no foro da comarca de
              São José dos Campos, sede do grupo.
            </p>
          </Text>
          <Link to="/sign-up" className="privacyPolicyButton">
            <button>VOLTAR</button>
          </Link>
        </Content>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
