import styled from "styled-components";
//import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: #233746;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 900px) {
    display: flex;
    height: 40%;
    align-items: center;
  }
`;

export const ModalImg = styled.img`
  height: 50%;

  @media screen and (max-width: 900px) {
    height: 85%;
  }
`;

export const CloseWindow = styled.div`
  display: flex;
  position: absolute;
  margin: 20px 30px;
  cursor: pointer;
  text-decoration: none;

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (max-width: 900px) {
    display: flex;
    position: absolute !important;
    margin-left: -90%;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    position: absolute !important;
    margin-left: -85%;
  }

  @media screen and (max-width: 500px) {
    display: flex;
    position: absolute !important;
    margin-left: -80%;
  }
`;

export const Title = styled.h3`
  color: #333333;
  font-size: 25px;
  font-family: normal normal bold 25px/30px Roboto;
  font-weight: bold;
  width: 55%;
  text-align: left;

  @media screen and (max-width: 972px) {
    font-size: 20px;
  }

  @media screen and (max-width: 900px) {
    padding-top: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  background: #ffffff;

  .privacyPolicyButton {
    width: 80%;

    button {
      width: 100%;
    }
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #6fa824;
    font-weight: bold;
    color: #fff;
    border: 0;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    transition: background 0.2s;
    cursor: pointer;
  }

  .privacyPolicy {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const Text = styled.div`
  height: 75%;
  width: 80%;
  overflow: auto;
  margin-bottom: 30px;

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
    text-align: justify;
    margin-right: 10px;
    line-height: 1.5;

    li {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      margin-bottom: 10px;
      text-align: justify;
      margin-left: 20px;
      margin-top: 10px;
      line-height: 1.5;
    }
  }
  a {
    color: blue;
  }
`;
