import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import {
  Container,
  ButtonMoveAndAnswer,
  Closewindow,
} from "../../globalStyles";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import {
  ScoreSection,
  ScoreWrapper,
  ScoreHeading,
  ScoreContainer,
  ScoreCardInfo,
  ScoreCardIcon,
  ScoreCardPlan,
  ScoreCardCost,
  ScoreCardFeatures,
  ScoreCardFeature,
  ScoreCardAnswer,
  CloseHeading,
  Checked,
  ButtonArea,
  ScoreCardContainer,
} from "./ScoreSelect.elements";
import { GoCheck } from "react-icons/go";
import sortArray from "sort-array";
import api from "../../services/api";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const modifyTitlte = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governança",
  Economico: "Econômico",
};

const modifyTitlte2 = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governanca",
  Economico: "Economico",
};

function Score() {
  const questionItems = JSON.parse(
    localStorage.getItem("@legaro:categories")
  ).map((item) => {
    if (item._id === "614ce5e4a9ed10f8b82ed487") {
      return {
        ...item,
        weight: 1,
      };
    }
    return item;
  });


  const takeID = localStorage.getItem("@legaro:companyID");
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`/companies/check/${takeID}`);
      //console.log(response.data);
      setData(response.data);
      setLoading(false);
    }
    loadData();
  }, [takeID]);

  //console.log('teste', addValue)

  const history = useHistory();

  const handleBack = () => {
    history.push("/dashboard");
  };

  const handleBackMoveCard = () => {
    history.push("/mover-temas");
  };

  const takeIdtoNext = (something) => {
    localStorage.setItem("@legaro:priority", something);
  };

  //const NewValue = localStorage.getItem("@legaro:theme")

  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <Container>
        <HeaderAfterLogin />
        {/*<CloseHeading>
          <ArrowBackIcon
            onClick={() => handleBackMoveCard()}
            style={{ cursor: "pointer" }}
          />
          <Closewindow onClick={() => handleBack()}>
            <strong>X</strong>
          </Closewindow>
        </CloseHeading>*/}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Verificando os temas...</span>
          </div>
        )}
        <ScoreHeading>
          Selecione um <br />
          tema abaixo:
        </ScoreHeading>
        <ScoreSection>
          <ScoreWrapper>
            <ScoreContainer>
              {sortArray(questionItems, {
                by: "weight",
                order: "desc",
              }).map(
                (
                  { _id, title, label, priority, image, description },
                  index
                ) => (
                  <ScoreCardAnswer key={index}>
                    {title === modifyTitlte2[title] && data[title] && (
                      <Checked>
                        <GoCheck color="#ffffff" size={20} />
                      </Checked>
                    )}
                    <ScoreCardInfo>
                      <ScoreCardContainer>
                        <ScoreCardIcon>
                          <img src={image} alt="Ambiental" />
                        </ScoreCardIcon>
                        <ScoreCardPlan>Tema {index + 1}</ScoreCardPlan>
                        <ScoreCardCost>{modifyTitlte[title]}</ScoreCardCost>
                        <ScoreCardFeatures>
                          <ScoreCardFeature>{description}</ScoreCardFeature>
                        </ScoreCardFeatures>
                      </ScoreCardContainer>
                      {!loading && (
                        <ButtonMoveAndAnswer
                          onClick={() => takeIdtoNext(priority)}
                        >
                          {title === modifyTitlte2[title] && data[title] ? (
                            <Link
                              to={`/atualizar-questionario/${takeID}/${modifyTitlte2[title]}`}
                            >
                              <div>
                                {title === modifyTitlte2[title] &&
                                data[title] ? (
                                  <strong>atualizar</strong>
                                ) : (
                                  <strong>responder</strong>
                                )}
                                <i className="fas fa-arrow-right"></i>
                              </div>
                            </Link>
                          ) : (
                            <Link to={`/questionario/${takeID}`}>
                              {title === modifyTitlte2[title] && data[title] ? (
                                <strong>atualizar</strong>
                              ) : (
                                <strong>responder</strong>
                              )}
                              <i className="fas fa-arrow-right"></i>
                            </Link>
                          )}
                        </ButtonMoveAndAnswer>
                      )}
                    </ScoreCardInfo>
                  </ScoreCardAnswer>
                )
              )}

              {/* <ScoreCardAnswer>
                <ScoreCardInfo>
                  <ScoreCardIcon>
                    <img
                      src="https://codexremote.com.br/esgimages/legaro/icon08.png"
                      alt="Econômico"
                    />
                  </ScoreCardIcon>
                  <ScoreCardPlan>Tema 4</ScoreCardPlan>
                  <ScoreCardCost>Econômico</ScoreCardCost>
                  <ScoreCardFeatures>
                    <ScoreCardFeature>
                        Apresenta o desempenho econômico e financeiro da empresa,
                      considerando riscos e oportunidades, assim como a
                      organização de finanças e a disponibilização de
                      investimentos alinhados às práticas de gestão.
                    </ScoreCardFeature>
                  </ScoreCardFeatures>
                    <ButtonMoveAndAnswer onClick={() => takeIdtoNext(4)} >
                        <Link to={`/questionario/${takeID}`} >
                        <strong>responder</strong>
                        <i className="fas fa-arrow-right"></i>
                      </Link>
                    </ButtonMoveAndAnswer>
                </ScoreCardInfo>
              </ScoreCardAnswer> */}
            </ScoreContainer>
          </ScoreWrapper>
        </ScoreSection>
        <ButtonArea onClick={() => handleBackMoveCard()}>
          reordenar temas
        </ButtonArea>
      </Container>
    </IconContext.Provider>
  );
}
export default Score;
