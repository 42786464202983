import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
import { Link, useHistory } from "react-router-dom";
//import * as Yup from "yup";
import { Content, Title, Validation, PrivacyPolicy } from "./Style.Elements";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import jwt from "jsonwebtoken";
import { isValidPhone } from '@brazilian-utils/brazilian-utils'

// Validation
//const schema = Yup.object().shape({
//name: Yup.string().required("Nome é obrigatório"),
//email: Yup.string().email("Email Inválido").required("E-mail é obrigatório"),
//phone: Yup.string().required("Telefone é obrigatório"),
//office: Yup.string().required("Cargo é obrigatório"),
//password: Yup.string()
//  .min(6, "mínimo 6 caracteres")
//  .required("Senha é obrigatória"),
//});

// Offices
const CargoOptions = [
  { value: "", name: "Selecione um cargo" },
  { value: "Estudante", name: "Estudante" },
  { value: "Analista", name: "Analista" },
  { value: "Gerente", name: "Gerente" },
  { value: "Diretor", name: "Diretor" },
  { value: "Outro", name: "Outro" },
];

const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const User = ({ navigation }) => {
  const [office, setOffice] = useState("");
  const { signIn, signOut } = useAuth();
  const { user } = useAuth();
  const history = useHistory();
  const token = history.location.search.split("=")[1];
  const [federation, setFederation] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const loadFederation = async () => {
    try {
      setLoading(true);

      const decoded = token
        ? await jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
        : null;

      const federationId = decoded?.federationId || user?.federationId;
      const { data } = await api.get(`/federations/${federationId}`);
      setFederation(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadFederation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit({ username, email, phone, password }) {
    setIsLoading(true)
    if (!username || !email || !phone || !password || !isValidPhone(phone)) {
      return toast.error(
        "Não foi possível cadastrar usuário. Preencha  todos os dados!"
      );
    }
    try {
      const decoded = token
        ? await jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
        : null;

      await api.post(
        "/users",
        {
          name: username,
          email,
          phone,
          cargo: office,
          password,
          federationId: decoded?.federationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      signOut();
      toast.success("Usuário cadastrado com sucesso");

      const responseCheck = await api.post('users/check', {
        email
      })

      setIsLoading(false)

      if (responseCheck?.data.emailConfirmed) {
        await signIn({
          email,
          password,
          method: "signup",
        });
        history.push({
          pathname: "/sign-up-company",
          state: {
            name: username,
            email,
            phone,
            cargo: office,
            federationId: decoded?.federationId,
          },
        });

        navigation.next();
      } else {
        history.push({
          pathname: "/email-confirmation",
          state: {
            name: username,
            email,
            phone,
            password,
            cargo: office,
            federationId: decoded?.federationId,
          },
        });

        navigation.next();
      }
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      //console.log("ERRO", err);
      return toast.error("Não foi possível cadastrar. Este usuário já existe!");
    }
  }

  function handleOffice(event) {
    setOffice(event.target.value);
  }

  const [phone, setPhone] = useState("");

  return (
    <>
      <Content>
        <Title>Primeiro, precisamos que você se identifique.</Title>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="username">
            Nome<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input name="username" type="text" required minLength="8" />
          <Validation id="hidden_validation">Digite seu nome</Validation>
          <label htmlFor="email" required>
            E-mail<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input
            name="email"
            type="email"
            id="email"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
          <label htmlFor="phone">
            Telefone<span style={{ color: "#f00" }}> *</span> <span style={{ color: "#f00", fontSize: 12 }}>{phone && !isValidPhone(phone) && 'Celular inválido!'}</span>
          </label>
          <Input
            type="phone"
            id="phone"
            name="phone"
            minlength="14"
            maxlength="15"
            required
            value={phone}
            onChange={(e) => setPhone(maskPhone(e.target.value))}
          />
          <label htmlFor="office">
            Cargo<span style={{ color: "#f00" }}> *</span>
          </label>
          <select onChange={handleOffice} name="office" required>
            {CargoOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <label htmlFor="password">
            Senha<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input name="password" type="password" required minlength="6" />

          <PrivacyPolicy>
            <Input
              name="privacyPolicy"
              type="radio"
              id="privacy"
              classname="privacyPolicy"
              required
              style={{
                height: "20px",
                marginTop: "15px",
                marginBottom: "20px",
              }}
            />
            <label for="checkbox">
              Eu li e aceito os{" "}
              <a href="/politica-privacidade">Termos de Uso e Privacidade</a>
            </label>
          </PrivacyPolicy>

          <button type="submit">
            {isLoading ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "AVANÇAR"
            )}
          </button>
          {/* <Link to="/sign-in">Já tenho Conta</Link> */}

          {!loading && federation ? (
            <Link to={`/sign-in?query=${token}`}>Já tenho Conta</Link>
          ) : (
            <div style={{ display: "flex" }}>
              <Link
                to="/sign-in"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px auto",
                  textDecoration: "none",
                }}
              >
                Já tenho Conta
              </Link>
            </div>
          )}
        </Form>
      </Content>
    </>
  );
};

export default User;
