import React from "react";
import Container from '@material-ui/core/Container';

export const Submit = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '4rem' }}>
      <h3>Obrigado por se inscrever ao LEGARO</h3>
    </Container>
  );
};
