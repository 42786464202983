import React from "react";
import { Footer, Navbar } from "../../components";
import { Title, Container, Content, Text, Image } from "./style.elements";

function WeAreCodex() {
  return (
    <Container>
      <Navbar />
      <Title>
        <strong>Olá, somos a CODEX</strong>
      </Title>
      <Content>
        <Image>
          <img
            src="https://codexremote.com.br/esgimages/legaro/Home3.webp"
            alt="imagem"
          />
        </Image>
        <Text>
          <h2>
            <strong>Conheça a Codex</strong>
          </h2>{" "}
          <br />
          <p className="lead">
            A Codex oferece serviços e ferramentas tecnológicas a fim de
            promover a evolução de seus clientes. Mas além de sua excelência no
            mercado e de ter grandes órgãos públicos como parceiros e clientes,
            como a SEMA-RS e CETESB, por exemplo, a Codex é engajada com as suas
            ações. Ela é uma empresa baseada na sustentabilidade, que trabalha
            em prol da eficiência governamental e ainda entrega um trabalho
            consultivo.
            <br />
            <br />
            Dentro dos seus propósitos e objetivos, ser uma organização
            sustentável e que fomenta a sustentabilidade no ramo empresarial e
            no mundo como um todo, é o que torna a Codex uma empresa engajada e
            que faz a diferença no mercado.
            <br />
            <br />
            Desde 2005, a Codex atua no ramo de geotecnologia com soluções que
            visam a transformação digital para o desenvolvimento sustentável. A
            empresa, parceira da Maxar - empresa líder no mercado de satélites
            de alta resolução -, recebeu no ano de 2020 o prêmio “High
            Potential” da Esri - organização criadora da tecnologia ArcGIS e
            líder no mercado global em SIG. Além disso, a Codex obteve o
            reconhecimento ArcGIS Urban Specialty Partner, tornando-se uma das
            sete empresas do mundo que alcançaram esta condecoração.
          </p>
        </Text>
      </Content>
      <Footer />
    </Container>
  );
}

export default WeAreCodex;
