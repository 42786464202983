import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, animateScroll as scroll } from "react-scroll";

import { itens } from "./data";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import { FooterAfterLogin } from "../../components";
import {
  DataContainer,
  LettersContainer,
  Title,
  ContainerData,
} from "./glossario.elements";
import { Container } from "../../globalStyles";

export default function CustomPaginationActionsTable() {
  return (
    <>
      <HeaderAfterLogin />
      <Container>
        <Title>Glossário</Title>
        <LettersContainer>
          <ul>
            <Link
              to="letraA"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>A</li>
            </Link>
            <Link
              to="letraB"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>B</li>
            </Link>
            <Link
              to="letraC"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>C</li>
            </Link>
            <Link
              to="letraD"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>D</li>
            </Link>
            <Link
              to="letraE"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>E</li>
            </Link>
            <Link
              to="letraF"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>F</li>
            </Link>
            <Link
              to="letraG"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>G</li>
            </Link>
            <li className="dontExist">H</li>
            <Link
              to="letraI"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>I</li>
            </Link>
            <Link
              to="letraJ"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>J</li>
            </Link>
            <li className="dontExist">K</li>
            <Link
              to="letraL"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>L</li>
            </Link>
            <Link
              to="letraM"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>M</li>
            </Link>
            <Link
              to="letraN"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>N</li>
            </Link>
            <Link
              to="letraO"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>O</li>
            </Link>
            <Link
              to="letraP"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>P</li>
            </Link>
            <li className="dontExist">Q</li>
            <Link
              to="letraR"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>R</li>
            </Link>
            <Link
              to="letraS"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>S</li>
            </Link>
            <Link
              to="letraT"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>T</li>
            </Link>
            <Link
              to="letraU"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>U</li>
            </Link>
            <Link
              to="letraV"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>V</li>
            </Link>
            <Link
              to="letraW"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>W</li>
            </Link>
            <li className="dontExist">X</li>
            <li className="dontExist">Y</li>
            <Link
              to="letraZ"
              offset={-100}
              smooth={true}
              data-html2canvas-ignore={true}
            >
              <li>Z</li>
            </Link>
          </ul>
        </LettersContainer>
        <TableContainer
          component={Paper}
          style={{ border: "none", width: "100%" }}
        >
          <Table style={{ border: "none" }}>
            <ContainerData>
              <TableBody>
                <h1 id="letraA" style={{ padding: "20px" }}>
                  A
                </h1>
                {itens
                  .filter((item) => item.letra === "A")
                  .map((item) => (
                    <TableRow key={item.name}>
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraB" style={{ padding: "20px" }}>
                  B
                </h1>
                {itens
                  .filter((item) => item.letra === "B")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraC" style={{ padding: "20px" }}>
                  C
                </h1>
                {itens
                  .filter((item) => item.letra === "C")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraD" style={{ padding: "20px" }}>
                  D
                </h1>
                {itens
                  .filter((item) => item.letra === "D")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraE" style={{ padding: "20px" }}>
                  E
                </h1>
                {itens
                  .filter((item) => item.letra === "E")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraF" style={{ padding: "20px" }}>
                  F
                </h1>
                {itens
                  .filter((item) => item.letra === "F")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraG" style={{ padding: "20px" }}>
                  G
                </h1>
                {itens
                  .filter((item) => item.letra === "G")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              {/* <TableBody>
              <h1 id="letraH">H</h1>
              {itens
                .filter((item) => item.letra === "H")
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}
            </TableBody> */}
              <TableBody>
                <h1 id="letraI" style={{ padding: "20px" }}>
                  I
                </h1>
                {itens
                  .filter((item) => item.letra === "I")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraJ" style={{ padding: "20px" }}>
                  J
                </h1>
                {itens
                  .filter((item) => item.letra === "J")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              {/* <TableBody>
              <h1 id="letraK">K</h1>
              {itens
                .filter((item) => item.letra === "K")
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}
            </TableBody> */}
              <TableBody>
                <h1 id="letraL" style={{ padding: "20px" }}>
                  L
                </h1>
                {itens
                  .filter((item) => item.letra === "L")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraM" style={{ padding: "20px" }}>
                  M
                </h1>
                {itens
                  .filter((item) => item.letra === "M")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraN" style={{ padding: "20px" }}>
                  N
                </h1>
                {itens
                  .filter((item) => item.letra === "N")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraO" style={{ padding: "20px" }}>
                  O
                </h1>
                {itens
                  .filter((item) => item.letra === "O")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraP" style={{ padding: "20px" }}>
                  P
                </h1>
                {itens
                  .filter((item) => item.letra === "P")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              {/* <TableBody>
              <h1 id="letraQ">Q</h1>
              {itens
                .filter((item) => item.letra === "Q")
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}
            </TableBody> */}
              <TableBody>
                <h1 id="letraR" style={{ padding: "20px" }}>
                  R
                </h1>
                {itens
                  .filter((item) => item.letra === "R")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraS" style={{ padding: "20px" }}>
                  S
                </h1>
                {itens
                  .filter((item) => item.letra === "S")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraT" style={{ padding: "20px" }}>
                  T
                </h1>
                {itens
                  .filter((item) => item.letra === "T")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraU" style={{ padding: "20px" }}>
                  U
                </h1>
                {itens
                  .filter((item) => item.letra === "U")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraV" style={{ padding: "20px" }}>
                  V
                </h1>
                {itens
                  .filter((item) => item.letra === "V")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              <TableBody>
                <h1 id="letraW" style={{ padding: "20px" }}>
                  W
                </h1>
                {itens
                  .filter((item) => item.letra === "W")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
              {/* <TableBody>
              <h1 id="letraX">X</h1>
              {itens
                .filter((item) => item.letra === "X")
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}
            </TableBody>
            <TableBody>
              <h1 id="letraY">Y</h1>
              {itens
                .filter((item) => item.letra === "Y")
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}
            </TableBody> */}
              <TableBody>
                <h1 id="letraZ" style={{ padding: "20px" }}>
                  Z
                </h1>
                {itens
                  .filter((item) => item.letra === "Z")
                  .map((item) => (
                    <TableRow
                      key={item.name}
                      style={{
                        border: "none",
                        color: "#6fa824",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                      className="glossario-titulo"
                    >
                      <TableCell
                        style={{ border: "none" }}
                        className="glossario-termo"
                      >
                        {item.termo}
                      </TableCell>
                      <DataContainer>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-explicacao"
                        >
                          {item.explicacao}
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className="glossario-fonte"
                        >
                          {item.fonte}
                        </TableCell>
                      </DataContainer>
                    </TableRow>
                  ))}
              </TableBody>
            </ContainerData>
          </Table>
        </TableContainer>
        <TableFooter></TableFooter>
      </Container>
      <FooterAfterLogin />
    </>
  );
}
