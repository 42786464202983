import React, { useState } from "react";
import Ambiental from "../AllQuestionaires/Ambiental";
import Social from "../AllQuestionaires/Social";
import Governanca from "../AllQuestionaires/Governanca";
import Economico from "../AllQuestionaires/Economico";
import "./style.css";

const modifyTitlte = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governança",
  Economico: "Econômico",
};

const Tab = () => {
  const categoryItems = JSON.parse(localStorage.getItem("@legaro:categories"));

  const priority = parseInt(localStorage.getItem("@legaro:priority"));

  const [ToggleState, setToggleState] = useState(priority);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  return (
    <div className="container">
      <ul className="tab-list">
        {categoryItems.map(({ title, priority, image }, i) => (
          <li
            key={i}
            className={`tabs ${getActiveClass(priority, "active-tabs")}`}
            onClick={() => toggleTab(priority)}
          >
            <img src={image} alt="Ambiental" />
            {modifyTitlte[title]}
          </li>
        ))}
      </ul>
      <div className="content-container">
        <div className={`content ${getActiveClass(1, "active-content")}`}>
          <div className="datailScore">
            <h2>Detalhamento do Score » Tema Ambiental</h2>
          </div>
          <div className="componentQuestion">
            <Ambiental />
          </div>
        </div>
        <div className={`content ${getActiveClass(2, "active-content")}`}>
          <div className="datailScore">
            <h2>Detalhamento do Score » Tema Social</h2>
          </div>
          <div className="componentQuestion">
            <Social />
          </div>
        </div>
        <div className={`content ${getActiveClass(3, "active-content")}`}>
          <div className="datailScore">
            <h2>Detalhamento do Score » Tema Governança</h2>
          </div>
          <div className="componentQuestion">
            <Governanca />
          </div>
        </div>
        <div className={`content ${getActiveClass(4, "active-content")}`}>
          <div className="datailScore">
            <h2>Detalhamento do Score » Tema Econômico</h2>
          </div>
          <div className="componentQuestion">
            <Economico />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
