import styled from "styled-components";
import { Link } from "react-router-dom";

export const Page = styled.div``;

export const exportContainer = styled.div`
  width: 2480px;
  min-height: 3508px;
  margin-left: auto;
  margin-right: auto;
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 7rem;
  font-size: 1rem;
  top: 0;
  z-index: 10;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
`;

export const NavLogo = styled.div`
  text-decoration: none;
  display: flex;
`;

export const NavMenu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  text-align: center;
`;

export const MenuItem = styled(Link)``;

export const Row = styled(Link)``;

export const MobileIcon = styled.div`
  display: none;
`;

export const NavItemBtn = styled.li``;

export const NavLinks = styled.div`
  color: #444444;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: white !important;
  cursor: pointer;
`;

export const PersonName = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;

export const Company = styled.div`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  cursor: auto;
  color: #666666;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bold;
  background-color: white !important;
`;

export const MenuContainer = styled.div``;
export const Results = styled.div``;
export const FooterContainer = styled.div`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`;
export const Left = styled.div``;
export const Right = styled.div`
  color: #999999;
  font-family: "Roboto";
  font-size: 12px;
`;
export const AccordionOpen = styled.div`
  color: #999999;
  font-family: "Roboto";
  font-size: 12px;
  width: 100%;
  border: 1px solid #cccccc;
  height: 12rem;
  border-radius: 15px;
  background-color: #fcfcfc;
`;
export const AccordionTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  margin-left: 1rem;
  margin-top: 1rem;

  h1 {
    color: #333333;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
  }
`;
export const AccordionIndicators = styled.div`
  margin-top: 1rem;
`;

export const AccordionIndicatorsColumn = styled.div`
  color: #666666;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  margin-top: 2.4rem;
  margin-bottom: 1rem;
`;
export const IndicatorsList = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
`;

export const Indicators = styled.div`
  font-family: "Montserrat", sans-serif;
  margin-right: 5rem;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;

export const IndiscatorsWeight = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #333333;
  font-weight: bold;
  font-size: 16px;
`;
