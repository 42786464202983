import { Link } from "react-router-dom";
import styled from "styled-components";
//import { Link } from "react-router-dom";

export const FasesContainer = styled.div``;

export const FasesConsteudo = styled.div`
  margin: auto;
`;

export const FaseSubtitle = styled.div`
  color: #333333;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-top: 0.5%;
  margin-bottom: 2.5%;

  @media screen and (max-width: 420px) {
    text-align: center;
  }
`;

export const FooterText = styled.div`
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-right: 10%;
  text-decoration: none;
  color: #333333;
  font-size: 18px;
`;
