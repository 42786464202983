import React from "react";
import {
  ContainerHeader,
  TopText,
  CloseWindow,
} from "./HeaderTransitionAmbiental.elements";

// import { Container } from './styles';

function HeaderTransitionAmbiental() {
  return (
    <ContainerHeader>
      <TopText>Detalhamento do Score » Tema 1: Ambiental</TopText>
      <CloseWindow to="/">
        <strong>x</strong>
      </CloseWindow>
      <span></span>
    </ContainerHeader>
  );
}

export default HeaderTransitionAmbiental;
