import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./hooks/auth";
import CookieConsent from "react-cookie-consent";
import { CookieContainer } from "./pages/HomePage/Home.elements";
import GlobalStyle from "./globalStyles";

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

ReactGA.initialize("UA-218449773-1");
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

document.addEventListener("DOMContentLoaded", function () {
  var lazyloadImages = document.querySelectorAll("img.lazy");
  var lazyloadThrottleTimeout;

  function lazyload() {
    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      var scrollTop = window.pageYOffset;
      lazyloadImages.forEach(function (img) {
        if (img.offsetTop < window.innerHeight + scrollTop) {
          img.src = img.dataset.src;
          img.classList.remove("lazy");
        }
      });
      if (lazyloadImages.length === 0) {
        document.removeEventListener("scroll", lazyload);
        window.removeEventListener("resize", lazyload);
        window.removeEventListener("orientationChange", lazyload);
      }
    }, 20);
  }

  document.addEventListener("scroll", lazyload);
  window.addEventListener("resize", lazyload);
  window.addEventListener("orientationChange", lazyload);
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  ReactGA.initialize("UA-97076428-1");

  const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  };

  return (
    <Router history={history} onUpdate={logPageView}>
      <AuthProvider>
        <Routes />
        <CookieConsent
          className="cookieConsent"
          style={{
            backgroundColor: "white",
            border: "1px solid #6FA824",
            borderRadius: "20px",
          }}
          buttonStyle={{
            backgroundColor: "#6FA824",
            fontSize: "20px",
            fontFamily: "Roboto",
            color: "white",
            fontWeight: "bold",
            padding: "10px 30px",
            borderRadius: "15px",
          }}
          buttonText="Aceitar"
        >
          <CookieContainer>
            <h1>
              <i
                className="fas fa-cookie-bite"
                style={{ marginRight: "15px" }}
              ></i>
              Esse site usa cookies
            </h1>
            <p>
              Nós armazenamos dados temporariamente para melhorar a sua
              experiência de navegação e recomendar conteúdo de seu interesse.
              Ao utilizar nossos serviços, você concorda com tal monitoramento.
            </p>
            <a href="/politica-privacidade" target="blank">
              Política de Privacidade
            </a>
          </CookieContainer>
        </CookieConsent>
      </AuthProvider>
      <ToastContainer autoClose={3000} limit={2} />
      <GlobalStyle />
    </Router>
  );
}

//export default withRouter(App);
export default App;
