/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";
//import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import {
  Container,
  Background,
  ModalImg,
  CloseWindow,
} from "../../pages/SignUp/Style.Elements";

import { Content, Title } from "./Style.Elements";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import axios from "axios";
import { isValidCNPJ } from '@brazilian-utils/brazilian-utils'

// Sectors
const SectorOptions = [
  { value: "", name: "Selecione o setor" },
  { value: "Instituições financeiras", name: "Instituições financeiras" },
  {
    value: "Instituições não financeiras",
    name: "Instituições não financeiras",
  },
];

const maskCNPJ = (value) => {
  return value
    .replace(/\D/g, "") //Remove tudo o que não é dígito
    .replace(/^(\d{2})(\d)/, "$1.$2") //Coloca ponto entre o segundo e o terceiro dígitos
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    .replace(/\.(\d{3})(\d)/, ".$1/$2") //Coloca uma barra entre o oitavo e o nono dígitos
    .replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
};

// Segments
const SegmentOptions = [
  { value: "", name: "Selecione o segmento" },
  { value: "Acessórios", name: "Acessórios" },
  { value: "Açúcar e alcool", name: "Açúcar e alcool" },
  {
    value: "Agricultura e pecuária (atividades produtivas)",
    name: "Agricultura e pecuária (atividades produtivas)",
  },
  { value: "Água e saneamento", name: "Água e saneamento" },
  {
    value: "Alimentos diversos (ativ. processamento e beneficiamento)",
    name: "Alimentos diversos (ativ. processamento e beneficiamento)",
  },
  { value: "Aluguel de carros", name: "Aluguel de carros" },
  { value: "Armas e munições", name: "Armas e munições" },
  { value: "Artefatos de cobre", name: "Artefatos de cobre" },
  { value: "Automóveis e motocicletas", name: "Automóveis e motocicletas" },
  { value: "Bancos", name: "Bancos" },
  { value: "Brinquedos e jogos", name: "Brinquedos e jogos" },
  { value: "Calçados", name: "Calçados" },
  { value: "Cervejas e refrigerantes", name: "Cervejas e refrigerantes" },
  { value: "Cigarros e fumo", name: "Cigarros e fumo" },
  {
    value: "Comercialização de medicamentos",
    name: "Comercialização de medicamentos",
  },
  {
    value: "Comércio de alimentos, livrarias e papelarias",
    name: "Comércio de alimentos, livrarias e papelarias",
  },
  {
    value: "Comércio de máquinas e equipamentos",
    name: "Comércio de máquinas e equipamentos",
  },
  {
    value: "Comércio de material de transporte",
    name: "Comércio de material de transporte",
  },
  {
    value: "Comércio de tecidos, vestuário e calçados",
    name: "Comércio de tecidos, vestuário e calçados",
  },
  {
    value: "Computadores e equipamentos",
    name: "Computadores e equipamentos",
  },
  {
    value: "Construção civil",
    name: "Construção civil",
  },
  {
    value: "Construção pesada",
    name: "Construção pesada",
  },
  {
    value: "Couro (processamento e beneficiamento)",
    name: "Couro (processamento e beneficiamento)",
  },
  {
    value: "Defensivos",
    name: "Defensivos",
  },
  {
    value: "Distribuição de combustíveis e gás",
    name: "Distribuição de combustíveis e gás",
  },
  {
    value: "Distribuição de energia elétrica",
    name: "Distribuição de energia elétrica",
  },
  {
    value: "Eletrodomésticos",
    name: "Eletrodomésticos",
  },
  {
    value: "Embalagens",
    name: "Embalagens",
  },
  {
    value: "Energia elétrica (geração e transmissão)",
    name: "Energia elétrica (geração e transmissão)",
  },
  {
    value: "Engenharia consultiva",
    name: "Engenharia consultiva",
  },
  {
    value: "Equipamentos elétricos",
    name: "Equipamentos elétricos",
  },
  {
    value: "Exploração de imóveis",
    name: "Exploração de imóveis",
  },
  {
    value: "Exploração de rodovias",
    name: "Exploração de rodovias",
  },
  {
    value: "Ferro e aço",
    name: "Ferro e aço",
  },
  {
    value: "Fertilizantes",
    name: "Fertilizantes",
  },
  {
    value: "Fios e tecidos",
    name: "Fios e tecidos",
  },
  {
    value: "Holdings diversificadas",
    name: "Holdings diversificadas",
  },
  {
    value: "Hotelaria",
    name: "Hotelaria",
  },
  {
    value: "Intermediação imobiliária",
    name: "Intermediação imobiliária",
  },
  {
    value: "Jornais, livros e revistas",
    name: "Jornais, livros e revistas",
  },
  {
    value: "Laticínios",
    name: "Laticínios",
  },
  {
    value: "Madeira, papel e celulose",
    name: "Madeira, papel e celulose",
  },
  {
    value: "Máquinas e equipamentos agrícolas e de transporte",
    name: "Máquinas e equipamentos agrícolas e de transporte",
  },
  {
    value: "Máquinas e equipamentos hospitalares",
    name: "Máquinas e equipamentos hospitalares",
  },
  {
    value: "Máquinas e equipamentos industriais",
    name: "Máquinas e equipamentos industriais",
  },
  {
    value: "Materiais de construção",
    name: "Materiais de construção",
  },
  {
    value: "Materiais diversos",
    name: "Materiais diversos",
  },
  {
    value: "Material aeronáutico",
    name: "Material aeronáutico",
  },
  {
    value: "Material ferroviário",
    name: "Material ferroviário",
  },
  {
    value: "Material rodoviário",
    name: "Material rodoviário",
  },
  {
    value: "Minerais metálicos",
    name: "Minerais metálicos",
  },
  {
    value: "Montadora de bicicletas",
    name: "Montadora de bicicletas",
  },
  {
    value: "Motores, compressores e outros",
    name: "Motores, compressores e outros",
  },
  {
    value: "Parques de diversão",
    name: "Parques de diversão",
  },
  {
    value: "Petróleo e gás (exploração e/ou refino)",
    name: "Petróleo e gás (exploração e/ou refino)",
  },
  {
    value: "Petroquímicos",
    name: "Petroquímicos",
  },
  {
    value: "Produção de medicamentos",
    name: "Produção de medicamentos",
  },
  {
    value: "Produtos de limpeza",
    name: "Produtos de limpeza",
  },
  {
    value: "Produtos de uso pessoal",
    name: "Produtos de uso pessoal",
  },
  {
    value: "Produtos diversos",
    name: "Produtos diversos",
  },
  {
    value: "Ração animal",
    name: "Ração animal",
  },
  {
    value: "Seguradoras",
    name: "Seguradoras",
  },
  {
    value: "Serviços de apoio e armazenagem",
    name: "Serviços de apoio e armazenagem",
  },
  {
    value: "Serviços diversos",
    name: "Serviços diversos",
  },
  {
    value: "Serviços educacionais",
    name: "Serviços educacionais",
  },
  {
    value: "Serviços financeiros diversos",
    name: "Serviços financeiros diversos",
  },
  {
    value: "Serviços médicos (hospitalar, análises e diagnósticos)",
    name: "Serviços médicos (hospitalar, análises e diagnósticos)",
  },
  {
    value: "Siderurgia",
    name: "Siderurgia",
  },
  {
    value: "Soc. crédito e financiamento",
    name: "Soc. crédito e financiamento",
  },
  {
    value: "Tecnologia da informação",
    name: "Tecnologia da informação",
  },
  {
    value: "Telefonia fixa",
    name: "Telefonia fixa",
  },
  {
    value: "Telefonia móvel",
    name: "Telefonia móvel",
  },
  {
    value: "Televisão por assinatura",
    name: "Televisão por assinatura",
  },
  {
    value: "Transporte aéreo",
    name: "Transporte aéreo",
  },
  {
    value: "Transporte ferroviário",
    name: "Transporte ferroviário",
  },
  {
    value: "Transporte hidroviário",
    name: "Transporte hidroviário",
  },
  {
    value: "Transporte rodoviário",
    name: "Transporte rodoviário",
  },
  {
    value: "Utensílios domésticos",
    name: "Utensílios domésticos",
  },
  {
    value: "Vestuário",
    name: "Vestuário",
  },
  {
    value: "Outros",
    name: "Outros",
  },
];

export const Company = ({ formData, setForm, navigation }) => {
  const [uf, setUf] = React.useState("AC");
  const [listUf, setListUf] = React.useState([]);
  const [city, setCity] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [segment, setSegment] = React.useState("");
  const [listCity, setListCity] = React.useState([]);
  const { signOut, user } = useAuth();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [cnpj, setCnpj] = useState("");
  const [validacnpj, setvalidacnpj] = useState("");
  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      // console.log('DATA -> ', actionData.data)

      setEmail(user.email);
      //console.log(user.email);
      setLoading(false);
    }
    loadData();
  }, [user.email]);

  function loadUf() {
    let url = "https://servicodados.ibge.gov.br/";
    url += "api/v1/localidades/estados";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.nome.localeCompare(b.nome));
        setListUf([{ sigla: "", nome: "Selecione o estado" }, ...data]);
      })
      .catch(() => console.error("Erro ao carregar os estados"));
  }
  function loadCity(id) {
    let url = "https://servicodados.ibge.gov.br/api/v1/";
    url += `localidades/estados/${id}/municipios`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.nome.localeCompare(b.nome));
        setListCity([{ sigla: "", nome: "Selecione o município" }, ...data]);
      })
      .catch(() => console.error("Erro ao carregar as cidades"));
  }
  React.useEffect(() => {
    loadUf();
  }, []);
  React.useEffect(() => {
    if (uf) {
      loadCity(uf);
    }
  }, [uf]);

  //const { name, cnpj, state, sector, segment } = formData;

  async function handleSubmit({ companyname, cnpj, email }) {
    const userData = history.location.state;
    const estado = listUf.find((std) => std.id === Number(uf));
    const data = JSON.parse(localStorage.getItem("@legaro:user"));

    if (!companyname || !cnpj || !estado || !city || !sector || !segment || !isValidCNPJ(cnpj)) {
      return toast.error("Não foi possível cadastrar esta empresa");
    }

    try {
      const response = await api.post("/companies", {
        name: companyname,
        cnpj,
        state: estado.nome,
        city,
        sector,
        segment,
        federationId: userData?.federationId || data?.federationId || null,
        isFinancial: sector === "Instituições financeiras" ? true : false,
      });

      localStorage.setItem("@legaro:company", response.data._id);

      toast.success("Empresa cadastrada com sucesso");

      history.push({
        pathname: "/sign-up-company-infos",
        state: {
          ...userData,
          companyname,
          cnpj,
          state: estado.nome,
          city,
          sector,
          segment,
          isFinancial: sector === "Instituições financeiras" ? true : false,
        },
      });

      // navigation.next();
    } catch (error) {
      //console.log("ERRO", error);
      return toast.error("Empresa já existe");
    }
  }

  /* function validadorCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj === "") return false;

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    )
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== digitos.charAt(1)) return false;

    return true;
  } */

  const handlePrevious = async () => {
    if (history.location.pathname === "/new-company") {
      history.push("/dashboard");
      return;
    }

    await api.delete(`/users/${user._id}`);
    signOut();
    history.push("/sign-up");
  };

  return (
    <Container>
      <CloseWindow>
        <a onClick={() => handlePrevious()}>x</a>
      </CloseWindow>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          alt="someone"
          className="lazy"
        />
      </Background>
      <Content>
        <Title>Agora, precisamos que identifique a empresa.</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            name="email"
            value={email}
            type="email"
            id="email"
            style={{ display: "none" }}
          />
          <label htmlFor="name">
            Nome da empresa<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input name="companyname" type="text" required minLength="8" />
          <label htmlFor="cnpj">
            CNPJ<span style={{ color: "#f00" }}> *</span> <span style={{ color: "#f00", fontSize: 12 }}>{cnpj && !isValidCNPJ(cnpj) && 'CNPJ Inválido!'}</span>
          </label>
          <Input
            name="cnpj"
            type="text"
            required
            maxlength="18"
            value={cnpj}
            onChange={(e) => setCnpj(maskCNPJ(e.target.value))}
          />
          <label htmlFor="uf">
            Estado sede<span style={{ color: "#f00" }}> *</span>
          </label>
          <select name="uf" value={uf} onChange={(e) => setUf(e.target.value)}>
            {listUf.map((a, index) => (
              <option key={index} name={uf} value={a.id}>
                {a.sigla} {a.nome}
              </option>
            ))}
          </select>
          <label htmlFor="city">
            Município sede<span style={{ color: "#f00" }}> *</span>
          </label>
          <select
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          >
            {listCity.map((a) => (
              <option value={a.sigla}>{a.nome}</option>
            ))}
          </select>
          <label htmlFor="sector">
            Setor<span style={{ color: "#f00" }}> *</span>
          </label>
          <select name="sector" onChange={(e) => setSector(e.target.value)}>
            {SectorOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <label htmlFor="segment">
            Segmento<span style={{ color: "#f00" }}> *</span>
          </label>
          <select name="segment" onChange={(e) => setSegment(e.target.value)}>
            {SegmentOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <div style={{ width: "100%", display: "flex" }}>
            <button
              type="button"
              style={{
                background: "#EEEEEE",
                color: "#666666",
                marginRight: "1rem",
                width: "50%",
              }}
              onClick={() => handlePrevious()}
            >
              Voltar
            </button>
            <button
              type="submit"
              style={{ width: "50%" }}
            // onClick={() => handleNext()}
            >
              Próximo
            </button>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default Company;
