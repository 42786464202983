import styled from "styled-components";

export const ContainerAll = styled.div`
  background-color: #6fa824;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
`;
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
`;
export const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 20rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  vertical-align: middle;

  img {
    width: 250px;
  }
`;
export const ContainerSubtitle = styled.div`
  display: flex;
  justify-content: center;
`;
export const Subtitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
`;
