import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";

export const Nav = styled.nav`
  background-color: #fff;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
`;
export const MenuItem = styled(Link)``;
export const Row = styled(Link)``;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #444444;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: start;
  font-weight: bold;

  img {
    @media screen and (max-width: 1170px) {
      width: 150px;
    }
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1170px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
    //background: #101522;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1170px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-200%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: white;
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 1170px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`;

export const NavLinks = styled.div`
  color: #444444;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  background-color: white !important;
  cursor: pointer;

  @media screen and (max-width: 1170px) {
    text-align: center;
    padding: 5% 1rem;
    width: 100%;
    display: table;
    &:hover {
      color: #6fa824;
      transition: all 0.3s ease;
    }
  }
`;

export const PersonName = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;

export const Company = styled.div`
  text-transform: lowercase;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  cursor: auto;

  color: #666666;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bold;
  background-color: white !important;
`;

export const MenuContainer = styled.div``;
