import React, { useState } from "react";
import { useParams } from "react-router-dom";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import { useAuth } from "../../hooks/auth";
//import { useHistory } from "react-router";
import api from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { compareArray } from "compare-object-array";
import { useHistory } from "react-router";
import {
  ContainerGeral,
  QuestionCard,
  QuestionBox,
  Question,
  QuestionText,
  Container,
  AnswerListContainer,
  ButtonQuestion,
  AnswerList,
  QuestionBoxContainer,
} from "./QuestionaireAtualization.elements";
import { LineColumn } from "../../components/LineColumn";
import { OptionRadio } from "../../components/OptionRadio";
import { OptionCheckbox } from "../../components/OptionCheckbox";
import {
  addBooleanAnswers,
  addMultipleAnswers,
  addUpdateMultipleAnswers,
  deleteAllAnswers,
} from "../../store/answerReducers";

function QuestionaireAtualization() {
  const [dataQuestion, setDataQuestion] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();
  const booleanAnswers = useSelector((state) => state.booleanAnswers);
  const multipleAnswers = useSelector((state) => state.multipleAnswers);
  const [loading, setLoading] = useState(false);
  const { addAnswered } = useAuth();

  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();

  React.useEffect(() => {
    async function loadData() {
      dispatch(deleteAllAnswers());
      setLoading(true);
      const response = await api.get(`companies/questions/${params.id}`);
      const categoryId = "614ce5e4a9ed10f8b82ed487";

      if (response.data) {
        response.data.answerEconomico.questionsAnswered.forEach(
          (ans, index) => {
            // console.log(ans.answerList)
            if (ans.idQuestion?.type === "boolean") {
              dispatch(
                addBooleanAnswers({
                  idQuestion: ans.idQuestion._id,
                  indicatorId: ans.indicatorId,
                  categoryId: categoryId,
                  currentId: ans.idQuestion._id,
                  answerList: ans.answerList[0],
                })
              );
            }

            if (ans.idQuestion?.type === "lineColumn") {
              ans.idQuestion.subquestion.forEach((questAns, index) => {
                dispatch(
                  addBooleanAnswers({
                    idQuestion: questAns._id,
                    indicatorId: ans.indicatorId,
                    categoryId: categoryId, 
                    currentId: ans.idQuestion._id,
                    answerList: ans.answerList[index],
                  })
                );
              })
            }

            if (ans.idQuestion?.type === "multiple") {
              dispatch(
                addUpdateMultipleAnswers({
                  idQuestion: ans.idQuestion._id,
                  indicatorId: ans.indicatorId,
                  categoryId: categoryId,
                  answerList: ans.answerList,
                })
              );
            }
          }
        );
      }

      setDataQuestion(response.data);
      setLoading(false);
    }
    loadData();
  }, [dispatch, params.id]);

  //const complete = localStorage.getItem("@legaro:question")

  const handleSubmit = async () => {
    const payload = {
      categoryId: "614ce5e4a9ed10f8b82ed487",
      questionsAnswered: [...booleanAnswers, ...multipleAnswers],
    };

    const newPayload = {
      categoryId: payload.categoryId,
      questionsAnswered: payload.questionsAnswered.map((quest) => {
        if (quest.currentId) {
          return { ...quest, idQuestion: quest.currentId, optionId: quest.idQuestion };
        }
        return quest;
      }),
    };

    try {
      await api.post(`/answers/${params.id}`, newPayload);

      toast.success("Questões atualizadas com sucesso 🙂");
    } catch (error) {
      toast.error("Não foi possível atualizar suas respostas");
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <HeaderAfterLogin />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10rem",
          }}
        >
          <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse"></i>
          <span style={{ marginLeft: 5 }}>Buscando suas respostas</span>
        </div>
      ) : (
        <ContainerGeral>
          <h1>Atualize suas respostas</h1>
          <p>Tema Econômico</p>
          <QuestionCard>
            <Container>
              {dataQuestion.answerEconomico &&
                dataQuestion.answerEconomico.questionsAnswered.map(
                  (item, index) => (
                    <QuestionBoxContainer
                      key={index}
                    >
                      {item.idQuestion && (
                        <QuestionBox key={index}>
                          <Question>
                            <QuestionText>
                              {index + 1}. {item.idQuestion?.question}
                            </QuestionText>
                            <AnswerListContainer>
                              {item.idQuestion?.type !== "lineColumn" && item.idQuestion?.answerList.map((ans, index) => (
                                <AnswerList key={ans._id}>
                                  {item.idQuestion.type === "boolean" && (
                                    <OptionRadio
                                      id={ans._id}
                                      key={index}
                                      index={index}
                                      slug={"economico_question"}
                                      idQuestion={item.idQuestion._id}
                                      indicatorId={item.idQuestion.indicatorId}
                                      categoryId="614ce5e4a9ed10f8b82ed487"
                                      answerList={ans}
                                      answer={ans.answer}
                                    />
                                  )}
                                  {item.idQuestion.type === "multiple" && (
                                    <OptionCheckbox
                                      id={ans._id}
                                      key={index}
                                      index={index}
                                      slug={"economico_question"}
                                      idQuestion={item.idQuestion._id}
                                      indicatorId={item.idQuestion.indicatorId}
                                      categoryId="614ce5e4a9ed10f8b82ed487"
                                      answerList={ans}
                                      answer={ans.answer}
                                    />
                                  )}
                                </AnswerList>
                              ))}
                              {item.idQuestion?.type === "lineColumn" &&  item.idQuestion?.subquestion.map((ans, index) => (
                                <AnswerList key={ans._id}>
                                  {item.idQuestion?.type === "lineColumn" && (
                                    <LineColumn
                                      key={ans._id}
                                      ans={ans}
                                      slug={"economico_question"}
                                      categoryId="614ce5e4a9ed10f8b82ed487"
                                      idQuestion={item.idQuestion._id}
                                      indicatorId={item.idQuestion.indicatorId}
                                    />
                                  )}
                                </AnswerList>
                              ))} 
                            </AnswerListContainer>
                          </Question>
                        </QuestionBox>
                      )}
                    </QuestionBoxContainer>
                  )
                )}
            </Container>
          </QuestionCard>
          <ButtonQuestion>
            <button className="returnbutton" onClick={handleBack}>
              voltar
            </button>
            <button type="button" onClick={handleSubmit}>
              atualizar
            </button>
          </ButtonQuestion>
        </ContainerGeral>
      )}
    </div>
  );
}

export default QuestionaireAtualization;
