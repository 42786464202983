import React, { useState, useEffect } from "react";
import { ScoreSelect } from "../../components";
import api from "../../services/api";

function ThemeAnswer() {
  const [data, setData] = useState({});
  const takeID = localStorage.getItem("@legaro:companyID");

  useEffect(() => {
    async function loadData() {
      // setLoading(true)
      const response = await api.get(`/companies/check/${takeID}`);
      setData(response.data);
      // setLoading(false)
    }
    loadData();
  }, [takeID]);

  return (
    <>
      <ScoreSelect />
    </>
  );
}

export default ThemeAnswer;
