import React, { createContext, useCallback, useState, useContext } from "react";
import { useHistory } from "react-router";
import jwt_decode from "jwt-decode";

import api from "../services/api";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [data, setData] = useState(() => {
    const token = localStorage.getItem("@legaro:token");
    const user = localStorage.getItem("@legaro:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { user: JSON.parse(user), token };
    }

    return {};
  });

  // Lógica para deslogar users com tokens expirados
  const token = data.token;

  if (token) {
    let decodedToken = jwt_decode(token);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      localStorage.clear();

      setData({});
    } else {
      console.log("Valid token");
    }
  }

  const signIn = useCallback(async ({ email, password, method }) => {
    setLoading(true);
    const response = await api.post("sessions", {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem("@legaro:token", token);
    localStorage.setItem("@legaro:user", JSON.stringify(user));
    setLoading(false);
    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ user, token });

    if (user.federationId && method !== "signup") {
      window.location.reload(false);
    }
  }, []);

  const signOut = useCallback(() => {
    const data = JSON.parse(localStorage.getItem("@legaro:user"));
    if (data?.federationId) {
      api.get(`/federations/${data?.federationId}`).then((response) => {
        const url = response.data.queryString.split("sign-up")[1];
        window.location.href = `/sign-up${url}`;
        localStorage.removeItem("@legaro:token");
        localStorage.removeItem("@legaro:user");
        localStorage.clear();
        setData({});
      });
    } else {
      localStorage.removeItem("@legaro:token");
      localStorage.removeItem("@legaro:user");
      localStorage.clear();
      setData({});
    }
  }, []);

  const addAnswered = (data) => {
    setAnsweredQuestions((oldState) => {
      return [...oldState, data];
    });
  };

  const clearAnswers = () => {
    setAnsweredQuestions((oldState) => {
      return [];
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        addAnswered,
        answeredQuestions,
        clearAnswers,
        loading,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
