import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { FooterAfterLogin } from "../../components";

import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

import { Link, useHistory } from "react-router-dom";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";

import { Container, ButtonLogin } from "../../globalStyles";
import {
  TitleDashboard,
  ButtonDashboard,
  TableDashboard,
  AcessoQuestionario,
  AcessoResultado,
} from "./style.element";

//import MenuBook from "@material-ui/icons/MenuBook";
//import Assessment from "@material-ui/icons/Assessment";
//import { Tooltip } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import PDF from "../../images/pdf.png";

import DialogRemoveCompany from "../../components/Dialog/DialogRemoveCompany";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useRowStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const takeStage = localStorage.getItem("@legaro:stages");

  const [data, setData] = useState({});
  const [dataStage, setDataStage] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`/companies/check/${row.companyId}`);
      setLoading(false);
      setData(response.data);
    }
    loadData();
  }, [row.companyId]);

  useEffect(() => {
    async function loadDataStage() {
      setLoading(true);
      const stage = await api.get(`/stages/${takeStage}`);
      setLoading(false);
      setData(stage.dataStage);
    }
    loadDataStage();
  }, [takeStage]);

  const reload = () => {
    window.location.reload();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClosedDialog = () => {
    setOpenDialog(false);
    reload();
  };

  const saveToLocalStorage = () => {
    try {
      localStorage.setItem("@legaro:companyID", row.companyId);
      localStorage.setItem("@legaro:score", row.score);
    } catch (err) {
      return undefined;
    }
  };

  const { user } = useAuth();

  return (
    <React.Fragment>
      <>
        <DialogRemoveCompany
          fullScreen={fullScreen}
          open={openDialog}
          handleClosedDialog={handleClosedDialog}
          onExit={reload}
        />
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              style={{ color: "#6FA824" }}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="left">{row.score}</TableCell>
          <TableCell align="left">{row.modificationDate}</TableCell>
          <TableCell align="left">{row.status}</TableCell>

          <TableCell
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "75%",
              alignItems: "center",
              marginLeft: "auto",
            }}
            align="center"
          >
            {data["Ambiental"] &&
            data["Social"] &&
            data["Governanca"] &&
            data["Economico"] ? (
              <Link to="/escolha-da-fase">
                {/*<BootstrapTooltip title="Responder questionário">*/}
                <AcessoQuestionario
                  onClick={() => {
                    saveToLocalStorage();
                  }}
                >
                  acessar o
                  <br /> questionário
                </AcessoQuestionario>
                {/*</BootstrapTooltip>*/}
              </Link>
            ) : (
              <Link to="/escolha-da-fase">
                {/*<BootstrapTooltip title="Responder questionário">*/}
                <AcessoQuestionario
                  onClick={() => {
                    saveToLocalStorage();
                  }}
                >
                  acessar o
                  <br /> questionário
                </AcessoQuestionario>
                {/*</BootstrapTooltip>*/}
              </Link>
            )}

            {(!data["Ambiental"] &&
              !data["Social"] &&
              !data["Governanca"] &&
              !data["Economico"]) ||
            (data["Ambiental"] &&
              data["Social"] &&
              data["Governanca"] &&
              data["Economico"]) ? (
              <span></span>
            ) : (
              <Link to="/resultado-score">
                {/*<BootstrapTooltip title="Ver Score Parcial">*/}
                <AcessoResultado
                  onClick={() => {
                    saveToLocalStorage();
                  }}
                >
                  resultado <br />
                  parcial
                </AcessoResultado>
                {/*</BootstrapTooltip>*/}
              </Link>
            )}

            {data["Ambiental"] &&
              data["Social"] &&
              data["Governanca"] &&
              data["Economico"] && (
                <Link to="/resultado-score-final">
                  {/*<BootstrapTooltip title="Ver Score Final">*/}
                  <AcessoResultado
                    onClick={() => {
                      saveToLocalStorage();
                    }}
                  >
                    resultado <br />
                    final
                  </AcessoResultado>
                  {/*</BootstrapTooltip>*/}
                </Link>
              )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          color: "#808080",
                          fontWeight: "normal",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          textDecoration: "none",
                          border: "none",
                          paddingLeft: "32px",
                        }}
                      >
                        Opções
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#808080",
                          fontWeight: "normal",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          textDecoration: "none",
                          border: "none",
                        }}
                      >
                        Empresa
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#808080",
                          fontWeight: "normal",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          textDecoration: "none",
                          border: "none",
                        }}
                      >
                        Usuário
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.keepCompanyId}>
                        <TableCell component="th" scope="row">
                          <ButtonGroup
                            sylesize="small"
                            variant="text"
                            aria-label="text primary button group"
                            style={{
                              padding: "10px",
                            }}
                          >
                            <Link
                              onClick={() => {
                                saveToLocalStorage();
                              }}
                              to={`/atualizar-informacoes-empresa/${historyRow.keepCompanyId}`}
                              style={{
                                color: "#808080",
                                fontWeight: "normal",
                                padding: "10px",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                textDecoration: "none",
                              }}
                            >
                              Editar
                            </Link>
                            <button
                              onClick={() => {
                                handleClickOpenDialog();
                                saveToLocalStorage();
                              }}
                              style={{
                                color: "#808080",
                                marginLeft: 8,
                                fontWeight: "normal",
                                background: "transparent",
                                outline: "none",
                                padding: "10px",
                                border: "none",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              Remover
                            </button>
                          </ButtonGroup>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "#808080",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "14px",
                            color: "#808080",
                          }}
                        >
                          {user.name}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number,
    modificationDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ),
    id: PropTypes.number.isRequired,
    companyId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
};

function RowTable({ d, index }) {
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(`companies/${d._id}`);
      setLoading(true);
      setData(response.data);
    }
    setLoading(false);
    loadData();
  }, [d._id]);

  return (
    <>
      {loading && (
        <Row
          row={{
            key: d._id,
            id: index + 1,
            name: data && data.company.name,
            score: data && data.generalScore,
            companyId: d._id,
            modificationDate: new Date(
              data && data.company.updatedAt
            ).toLocaleDateString(),
            status: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{data && data.company.stageId.title}</span>
                {data && data.company.stageId.image && (
                  <img
                    style={{ marginLeft: 10 }}
                    src={data && data.company.stageId.image}
                    width={25}
                    alt="Símbolo da fase"
                  />
                )}
              </div>
            ),
            action: "",
            history: [
              {
                keepCompanyId: d._id,
                customerId: "11091700",
                amount: 3,
              },
            ],
          }}
        />
      )}
    </>
  );
}

export default function CollapsibleTable() {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`companies/author/${user._id}`);

      setLoading(false);
      setData(response.data.docs);
    }
    loadData();
  }, [user._id]);

  const goToRegisterNewCompany = () => {
    history.push("/new-company");
  };

  return (
    <>
      <HeaderAfterLogin />
      <Container>
        <TitleDashboard>
          <h3 className="card-title">Selecione uma empresa</h3>
        </TitleDashboard>
        <ButtonDashboard>
          <ButtonLogin
            onClick={() => goToRegisterNewCompany()}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #808080",
              color: "#808080",
            }}
          >
            <i className="fas fa-plus"></i> cadastrar nova empresa
          </ButtonLogin>
        </ButtonDashboard>

        <TableDashboard>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="left">Nome</TableCell>
                  <TableCell align="left">Score</TableCell>
                  <TableCell align="left">Última modificação</TableCell>
                  <TableCell align="left">Fases</TableCell>
                  <TableCell align="center">Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((d, index) => {
                  return <RowTable key={index} index={index} d={d} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableDashboard>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Carregando as empresas</span>
          </div>
        )}
      </Container>
      <FooterAfterLogin />
    </>
  );
}
