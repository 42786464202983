import styled from "styled-components";
import { darken } from "polished";

import SignUpBackgroudImg from "../../images/profile.jpg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  background: #ffffff;

  form {
    width: 53%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    label {
      width: -50%;
      color: #333333;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      font-size: 16px;

      &::placeholder {
        color: #333;
      }
    }
    select {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      //color: #CCCCCC;
      color: #333;
      margin: 0 0 10px;
      padding-right: 10px;
      text-decoration: none !important;

      &options {
        color: #006cff;
      }
    }

    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #6fa824;
      font-weight: bold;
      color: #fff;
      border: 0;
      outline: none;
      border-radius: 50px;
      font-size: 16px;
      transition: background 0.2s;
      cursor: pointer;
      &:hover {
        background: ${darken(0.03, "#6fa824")};
      }
    }
    a {
      text-align: center;
      margin-top: 1rem;
      font-size: 12px;
      font-family: normal normal medium 16px/50px Roboto;
      letter-spacing: 0px;
      color: #006cff;
      opacity: 0.8;
      font-weight: bold;
      &:hover {
        opacity: 1;
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  //background: url(${SignUpBackgroudImg}) no-repeat center;
  //background-size: cover;
  background: #233746;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalImg = styled.img`
  width: 260px;
  height: 100px;
  //border-radius: 10px 0 0 10px;
`;

export const Title = styled.h3`
  color: #333333;
  font-size: 25px;
  font-family: normal normal bold 25px/30px Roboto;
  font-weight: bold;
  width: 55%;
  text-align: left;

  @media screen and (max-width: 972px) {
    font-size: 20px;
  }

  @media screen and (max-width: 900px) {
    padding-top: 30px;
  }
`;

export const TermAndCondition = styled.div`
  display: flex;
  align-items: center;

  label {
    font-size: 12px;
    padding-left: 10px;
  }
`;

export const Form = styled.div`
  .minCaracteres {
    font-size: 10px;
  }
`;

export const Validation = styled.div`
  border: 1px solid red;
  display: none;
`;

export const PrivacyPolicy = styled.div`
  display: flex;

  label {
    margin-left: 10px;
    font-family: normal normal bold 25px/30px Roboto;
    weight: 300;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;

    a {
      font-family: normal normal bold 25px/30px Roboto;
      weight: 300;
      font-size: 15px;
    }
  }

  /* [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  } */
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    background: #fff;
  }
`;

export const ContentCompanyInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 700px;
  background: #ffffff;

  form {
    width: 53%;
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    label {
      color: #333333;
      margin-bottom: 1rem;
    }

    p {
      color: #333333;
      margin-bottom: 1.5rem;
      margin-bottom: 1.6rem;
      font-weight: bold;
    }

    input {
      background: #eeeeee;
      border: 0;
      outline: none;
      border-radius: 10px;
      height: 50px;
      padding: 15px;
      color: #333;
      margin: 0 0 10px;
      font-weight: bold;
      font-size: 16px;

      &::placeholder {
        color: #333;
      }
    }
    span {
      color: red;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #6fa824;
      font-weight: bold;
      color: #fff;
      border: 0;
      outline: none;
      border-radius: 50px;
      font-size: 16px;
      transition: background 0.2s;
      cursor: pointer;
      &:hover {
        background: ${darken(0.03, "#6fa824")};
      }
    }
    a {
      text-align: center;
      margin-top: 1rem;
      font-size: 12px;
      font-family: normal normal medium 16px/50px Roboto;
      letter-spacing: 0px;
      color: #006cff;
      opacity: 0.8;
      font-weight: bold;
      &:hover {
        opacity: 1;
      }
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: #6fa824;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const Layout = styled.div``;
