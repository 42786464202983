import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 45px;
`;

export const TabList = styled.div`
  height: 50px;
  display: flex;
  list-style: none;
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: #333;
  font-weight: bold;

  //border: 1px solid red;
`;

export const Tabs = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;

    @media screen and (max-width: 1100px) {
      width: 35px;
      height: 35px;
    }

    @media screen and (max-width: 700px) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 700px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .active-tabs {
    background-color: #fff;
  }

  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 4px;
    background: #6fa824;
  }
`;

export const NameActive = styled.div`
  color: #333333;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }

  @media screen and (max-width: 420px) {
    font-size: 13px;
  }
`;

export const NameNotActive = styled.div`
  color: #808080;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }

  @media screen and (max-width: 420px) {
    font-size: 13px;
  }
`;

export const LiActive = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
  cursor: default;
  margin: 0;
  padding: 0;

  .content {
    display: none;
    background-color: #eeeeee;
    padding: 10px;
    border: 1px solid red;
  }

  .active-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
`;

export const HrActiv = styled.div`
  background-color: #6fa824;
  width: 100%;
  height: 4px;
  margin: 0;
  padding: 0;
`;

export const ListHr = styled.div`
  display: flex;
  list-style: none;
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: #333;
  font-weight: bold;
  margin-top: 10px;
`;

export const ContentContainer = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
`;

export const DetailScore = styled.div`
  width: 100%;
  padding: 25px;
  background: #eeeeee;
  border: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #666666;
  font-size: 15px;
  font-weight: bold;
`;

export const CloseWindow = styled.div`
  display: flex;
  position: absolute;
  margin-left: 95%;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
`;

export const HrScore = styled.div`
  background-color: #6fa824;
  width: 100%;
  height: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
`;
