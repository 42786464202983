import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import jwt from "jsonwebtoken";
import * as S from "./styles";
import api from "../../services/api";
import qs from "qs";

const Template = () => {
  const [federation, setFederation] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const url = history.location.search;
  const obj = qs.parse(url);

  const token = obj["?query"];

  const decoded = token
    ? jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
    : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadFederation = async () => {
    try {
      setLoading(true);

      const federationId = decoded?.federationId || user?.federationId;
      const { data } = await api.get(`/federations/${federationId}`);
      setFederation(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadFederation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <div>
        {!loading && federation && (
          <div
            dangerouslySetInnerHTML={{
              __html: `${federation?.html}`,
            }}
          />
        )}
      </div>
    </S.Container>
  );
};

export default Template;
