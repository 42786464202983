import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLogin } from "../../globalStyles";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
//import { useAuth } from "../../hooks/auth";
import { OptionRadio } from "../OptionRadio";
import { OptionCheckbox } from "../OptionCheckbox";
import { compareArray } from "compare-object-array";
import { LineColumn } from "../LineColumn";
import sortArray from "sort-array";
import Footer from '../../template/Footer'

import {
  ContentQuestion,
  AnswerList,
  ButtonQuestion,
  BackgroundQuestion,
  AnswerListContainer,
  FixedDiv,
  Container,
  ContainerAll,
  Glossario,
} from "./style.element";

import { useHistory } from "react-router";
import {
  deleteAllAnswers,
  deleteBooleanAnswer,
  deleteMultipleAnswer,
  addBooleanAnswers,
  addUpdateMultipleAnswers,
} from "../../store/answerReducers";

function Economico() {
  const [dataQuesntion, setDataQuestion] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [specialQuestions, setSpecialQuestions] = useState([]);
  //const [cliked, setClicked] = useState(false);
  const params = useParams();
  //const [pogress, setPogress] = useState(1);
  const booleanAnswers = useSelector((state) => state.booleanAnswers);
  const multipleAnswers = useSelector((state) => state.multipleAnswers);
  const dispatch = useDispatch();

  const history = useHistory();

  React.useEffect(() => {
    async function loadSavedData() {
      dispatch(deleteAllAnswers());
      const response = await api.get(`companies/questions/${params.id}`);

      // console.log('COMPANY', data)
      const categoryId = "614ce5e4a9ed10f8b82ed487";

      if (response.data && response.data.answerEconomico) {
        response.data.answerEconomico.questionsAnswered.forEach(
          (ans, index) => {
            // console.log(ans.answerList)
            if (ans.idQuestion?.type === "boolean") {
              dispatch(
                addBooleanAnswers({
                  idQuestion: ans.idQuestion._id,
                  indicatorId: ans.indicatorId,
                  categoryId: categoryId,
                  currentId: ans.idQuestion._id,
                  answerList: ans.answerList[0],
                })
              );
            }

            if (ans.idQuestion?.type === "lineColumn") {
              ans.idQuestion.subquestion.forEach((questAns, index) => {
                dispatch(
                  addBooleanAnswers({
                    idQuestion: questAns._id,
                    indicatorId: ans.indicatorId,
                    categoryId: categoryId,
                    currentId: ans.idQuestion._id,
                    answerList: ans.answerList[index],
                  })
                );
              });
            }

            if (ans.idQuestion?.type === "multiple") {
              dispatch(
                addUpdateMultipleAnswers({
                  idQuestion: ans.idQuestion._id,
                  categoryId: categoryId,
                  indicatorId: ans.indicatorId,
                  answerList: ans.answerList,
                })
              );
            }
          }
        );
      }
    }

    async function loadData() {
      const categoryId = "614ce5e4a9ed10f8b82ed487";

      const res = await api.get(
        `special-question/filter?categoryId=${categoryId}&stageId=${params.stageId}`
      );

      setSpecialQuestions(
        sortArray(res.data.docs, {
          by: "order",
          order: "asc",
        }).map((item) => ({
          question: item.question,
          type: item.type,
          answers: item.answerList,
          id: item._id,
          dependentAnswers: item.dependentAnswerList,
          dependetIdQuestion: item.dependentIdQuestion,
          indicatorId: item.indicatorId,
          description: item.description,
          order: item.order,
        }))
      );

      const response = await api.get(
        `question/filter?categoryId=${categoryId}&stageId=${params.stageId}`
      );
      setDataQuestion(
        sortArray(response.data.docs, {
          by: "order",
          order: "asc",
        }).map((item) => ({
          question: item.question,
          type: item.type,
          answers:
            item.type === "lineColumn" ? item.subquestion : item.answerList,
          id: item._id,
          indicatorId: item.indicatorId,
          description: item.description,
          order: item.order,
        }))
      );
    }
    dispatch(deleteAllAnswers());
    loadSavedData();
    loadData();
  }, [dispatch, params.id, params.stageId]);

  const handleNextQuestion = useCallback(async () => {
    // Removendo Respostas
    // const booleanQuestionExists = booleanAnswers.find(
    //   (question) => question.idQuestion === dataQuesntion[currentQuestion].id
    // );

    // if (booleanQuestionExists) {
    //   if (!booleanQuestionExists?.answerList[0].cantResponse) {
    //     dispatch(
    //       deleteBooleanAnswer({
    //         idQuestion: dataQuesntion[currentQuestion].id,
    //       })
    //     );
    //   }
    // }

    // const multipleQuestionExists = multipleAnswers.find(
    //   (question) => question.idQuestion === dataQuesntion[currentQuestion].id
    // );

    // if (multipleQuestionExists){
    //   const existNotCanResponse = multipleQuestionExists.answerList.find((answer) => answer.cantResponse === false)

    //   if (existNotCanResponse){
    //     dispatch(
    //       deleteMultipleAnswer({
    //         idQuestion: dataQuesntion[currentQuestion].id,
    //       })
    //     );
    //   }
    // }

    const payload = {
      categoryId: "614ce5e4a9ed10f8b82ed487",
      questionsAnswered: [
        ...booleanAnswers.filter(
          (ans) => ans.categoryId === "614ce5e4a9ed10f8b82ed487"
        ),
        ...multipleAnswers.filter(
          (ans) => ans.categoryId === "614ce5e4a9ed10f8b82ed487"
        ),
      ],
    };

    const newPayload = {
      categoryId: payload.categoryId,
      questionsAnswered: payload.questionsAnswered.map((quest) => {
        if (dataQuesntion[currentQuestion]?.type === "lineColumn") {
          return {
            ...quest,
            idQuestion: quest.currentId,
            optionId: quest.idQuestion,
          };
        }

        if (quest.currentId) {
          return {
            ...quest,
            idQuestion: quest.currentId,
            optionId: quest.idQuestion,
          };
        }
        return quest;
      }),
    };

    if (dataQuesntion[currentQuestion].type !== "lineColumn") {
      const isAnswered = payload.questionsAnswered.find(
        (quest) => quest.idQuestion === dataQuesntion[currentQuestion].id
      );

      if (!isAnswered) {
        return toast.warn("Responda a questão, por favor 😅");
      }
    } else {
      const isAnswered = payload.questionsAnswered.find(
        (quest) => quest.currentId === dataQuesntion[currentQuestion].id
      );

      if (!isAnswered) {
        return toast.warn("Responda a questão, por favor 😅");
      }
    }

    // Perguntas intermediarias que não dependem de uma lineColumn
    if (dataQuesntion[currentQuestion].type !== "lineColumn") {
      const answeredsList = payload.questionsAnswered.find(
        (quest) => quest.idQuestion === dataQuesntion[currentQuestion].id
      )?.answerList;

      const dependentAnsweredsList = specialQuestions.find(
        (quest) =>
          quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
      )?.dependentAnswers;

      if (dependentAnsweredsList) {
        let isEqual = false;

        dependentAnsweredsList.forEach((dep) => {
          const answerExist = answeredsList.find(
            (ans) => String(ans._id) === String(dep._id)
          );

          if (answerExist) {
            isEqual = true;
          }
        });
        const dependentQUestion = specialQuestions.find(
          (quest) =>
            quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
        );

        if (isEqual) {
          setDataQuestion((oldState) => {
            return oldState.filter(
              (state) => state.id !== dependentQUestion.id
            );
          });

          setDataQuestion((oldState) => {
            const auxData = [
              ...oldState,
              specialQuestions.find(
                (quest) =>
                  quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
              ),
            ];

            return sortArray(auxData, {
              by: "order",
              order: "asc",
            });
          });
        } else {
          setDataQuestion((oldState) => {
            return oldState.filter(
              (state) => state.id !== dependentQUestion.id
            );
          });
        }
      }
    } else {
      // Perguntas intermediarias que dependem de uma lineColumn
      const questions = dataQuesntion.find(
        (quest) => quest.id === dataQuesntion[currentQuestion].id
      ).answers;
      const questionsPayload = payload.questionsAnswered.filter(
        (quest) => quest.currentId === dataQuesntion[currentQuestion].id
      );
      const questionsDependent = specialQuestions.find(
        (quest) =>
          quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
      )?.dependentAnswers;

      let addQuation = false;

      const size = questionsPayload.length;
      const totalOptions = questions?.length;
      //const sizeDependentAnswers = questionsDependent?.length;
      // const answeredsList =  payload.questionsAnswered.find(quest => quest.currentId === dataQuesntion[currentQuestion].id)?.answerList

      if (totalOptions !== size) {
        return toast.warn("Responda a todas as opções, por favor 😅");
      }

      Array.isArray(questionsDependent) &&
        questionsDependent.forEach((quest, index) => {
          questionsPayload.forEach((dep) => {
            const answerExist = dep.answerList.find(
              (ans) => String(ans._id) === String(quest._id)
            );
            if (answerExist) {
              addQuation = true;
            }
          });
        });

      const dependentQUestion = specialQuestions.find(
        (quest) =>
          quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
      );

      if (addQuation) {
        setDataQuestion((oldState) => {
          return oldState.filter((state) => state.id !== dependentQUestion.id);
        });

        setDataQuestion((oldState) => {
          const auxData = [
            ...oldState,
            specialQuestions.find(
              (quest) =>
                quest.dependetIdQuestion === dataQuesntion[currentQuestion].id
            ),
          ];

          return sortArray(auxData, {
            by: "order",
            order: "asc",
          });
        });
      } else {
        setDataQuestion((oldState) => {
          return oldState.filter((state) => state.id !== dependentQUestion?.id);
        });
      }
    }

    //
    try {
      await api.post(`/answers/${params.id}`, newPayload);
      if (currentQuestion < dataQuesntion.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        const { data: currentCheck } = await api.get(
          `companies/check/${params.id}`
        );

        const { data: myStage } = await api.get(`stages/${params.stageId}`);

        if (
          currentCheck["Ambiental"] &&
          currentCheck["Governanca"] &&
          currentCheck["Social"] &&
          currentCheck["Economico"] &&
          currentCheck?.stage.title === "Basic"
        ) {
          toast.success("Você concluiu a fase BASIC ✅");
        }

        if (
          currentCheck["Ambiental"] &&
          currentCheck["Governanca"] &&
          currentCheck["Social"] &&
          currentCheck["Economico"] &&
          currentCheck?.stage.title === "Standard"
        ) {
          toast.success("Você concluiu a fase STANDARD ✅");
        }

        if (
          currentCheck["Ambiental"] &&
          currentCheck["Governanca"] &&
          currentCheck["Social"] &&
          currentCheck["Economico"] &&
          currentCheck?.stage.title === "Premium"
        ) {
          toast.success("Você concluiu a fase PREMIUM ✅");
        }

        if (
          currentCheck?.stage.title === "Basic" &&
          myStage.title === "Standard"
        ) {
          await api.put(`/companies/${params.id}`, {
            stageId: myStage._id, // ID STANDARD STAGE
          });
          toast.success("Você concluiu o primeiro tema da fase STANDARD ✅");
        }

        if (
          currentCheck?.stage.title === "Standard" &&
          myStage.title === "Premium"
        ) {
          await api.put(`/companies/${params.id}`, {
            stageId: myStage._id, // ID PREMIUM STAGE
          });
          toast.success("Você concluiu o primeiro tema da fase PREMIUM ✅");
        }

        history.push(`/transicao-economico/${params.stageId}`);
      }
      toast.success("Questão respondida com sucesso 🙂");
    } catch (error) {
      toast.error("Não foi possível responder esta questão");
    }
  }, [
    booleanAnswers,
    currentQuestion,
    dataQuesntion,
    history,
    multipleAnswers,
    params.id,
    params.stageId,
    specialQuestions,
  ]);

  const handlePreviousQuestion = () => {
    //setClicked(true);
    if (currentQuestion >= 1 && currentQuestion < dataQuesntion.length + 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSkipQuestion = () => {
    const booleanQuestionExists = booleanAnswers.find(
      (question) => question.idQuestion === dataQuesntion[currentQuestion].id
    );
    const multipleQuestionExists = multipleAnswers.find(
      (question) => question.idQuestion === dataQuesntion[currentQuestion].id
    );

    if (booleanQuestionExists) {
      dispatch(
        deleteBooleanAnswer({
          idQuestion: dataQuesntion[currentQuestion].id,
        })
      );
    }

    if (multipleQuestionExists) {
      dispatch(
        deleteMultipleAnswer({
          idQuestion: dataQuesntion[currentQuestion].id,
        })
      );
    }

    if (currentQuestion === dataQuesntion.length - 1) {
      history.push(`/selecionar-tema/${params.stageId}`);
    }

    if (currentQuestion < dataQuesntion.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const descriptionAppear = () => {
    if (dataQuesntion[currentQuestion].description !== "") {
      return (
        <div className="description">
          <span className="descriptionTitle">Descrição:</span>
          <span className="descriptionText">
            {dataQuesntion[currentQuestion].description}
          </span>
        </div>
      );
    }
  };

  return (
    <>
      <ContainerAll>
        {dataQuesntion[currentQuestion] && (
          <Container>
            <BackgroundQuestion>
              <div className="containerGlossario">
                <h2>
                  Questão {currentQuestion + 1} de {dataQuesntion.length}
                </h2>
                <div className="glossarioButton">
                  <Glossario to="/glossario">Glossário</Glossario>
                </div>
              </div>
              <FixedDiv>
                <ContentQuestion>
                  <h2>{dataQuesntion[currentQuestion].question}</h2>
                  <div className="descriptionContainer">
                    {descriptionAppear()}
                  </div>
                </ContentQuestion>
                <AnswerListContainer>
                  {dataQuesntion[currentQuestion].answers.map((ans, index) => (
                    <AnswerList key={ans._id}>
                      {dataQuesntion[currentQuestion].type === "boolean" && (
                        <OptionRadio
                          id={ans._id}
                          index={index}
                          slug={"economic_question"}
                          idQuestion={dataQuesntion[currentQuestion].id}
                          indicatorId={
                            dataQuesntion[currentQuestion].indicatorId
                          }
                          categoryId="614ce5e4a9ed10f8b82ed487"
                          answerList={ans}
                          answer={ans.answer}
                        />
                      )}
                      {dataQuesntion[currentQuestion].type === "multiple" && (
                        <OptionCheckbox
                          id={ans._id}
                          index={index}
                          slug={"economic_question"}
                          idQuestion={dataQuesntion[currentQuestion].id}
                          indicatorId={
                            dataQuesntion[currentQuestion].indicatorId
                          }
                          categoryId="614ce5e4a9ed10f8b82ed487"
                          answerList={ans}
                          answer={ans.answer}
                        />
                      )}
                      {dataQuesntion[currentQuestion]?.type ===
                        "lineColumn" && (
                        <LineColumn
                          ans={ans}
                          slug={"economic_question"}
                          categoryId="614ce5e4a9ed10f8b82ed487"
                          idQuestion={dataQuesntion[currentQuestion].id}
                          indicatorId={
                            dataQuesntion[currentQuestion].indicatorId
                          }
                        />
                      )}
                    </AnswerList>
                  ))}
                </AnswerListContainer>
              </FixedDiv>
            </BackgroundQuestion>
          </Container>
        )}
      </ContainerAll>
      <ButtonQuestion>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <ButtonLogin
            onClick={handlePreviousQuestion}
            type="submit"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #999999",
              color: "#999999",
            }}
          >
            voltar
          </ButtonLogin>
          <button
            className="pularPergunta"
            type="button"
            onClick={handleSkipQuestion}
          >
            pular pergunta
          </button>
          <button onClick={handleNextQuestion} type="submit">
            {currentQuestion + 1 === dataQuesntion.length
              ? "finalizar"
              : "avançar"}
          </button>
        </div>
      <Footer/>
      </ButtonQuestion>
    </>
  );
}

export default Economico;
