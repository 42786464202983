import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
//import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import Chart from "../ResultPages/Chart";
import "../ResultPages/Result.css";
import api from "../../services/api";
//import UIModal from "../../components/Modal/ModalShareResults";
import { Link, animateScroll as scroll } from "react-scroll";
import { useParams } from "react-router-dom";
import {
  AccordionResultsAmbiental,
  AccordionResultsSocial,
  AccordionResultsEconomico,
  AccordionResultsGovernanca,
} from "../../components";
import "../../components/Modal/ModalShareResults.css";
import sortArray from "sort-array";
import "../ResultPages/elements";
//import { useReactToPrint } from "react-to-print";
import {
  FooterContainer,
  FooterInformation,
  FooterLinkSubTitle,
  FooterLinksWrapperHelpExport,
  FooterLinkText,
  Nav,
  NavbarContainer,
  NavLinks,
  NavLogo,
  NavMenu,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./ShareElements";
import BusinessIcon from "@material-ui/icons/Business";
//import PersonIcon from "@material-ui/icons/Person";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { Page } from "../../components/Export/Export.elements";

function ResultScoreFial() {
  const [data, setData] = useState([]);
  const params = useParams();
  //const [isModalVisible, setIsModalVisible] = useState(false);
  //const OpenModal = () => setIsModalVisible(true);
  const [newData, setNewData] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [onPrint, setOnPrint] = React.useState(false);
  const componentRef = useRef();

  /*const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });*/

  //load data of companies by ID
  useEffect(() => {
    async function loadData() {
      setLoading(true);

      const IDcompany = params?.id;
      const response = await api.get(`companies/${IDcompany}`);

      const {
        ambientalWeight,
        governancaWeight,
        socialWeight,
        economicoWeight,
      } = response.data.company;

      setNewData([
        {
          id: "ambiental",
          weight: ambientalWeight,
        },
        {
          id: "governanca",
          weight: governancaWeight,
        },
        {
          id: "social",
          weight: socialWeight,
        },
        {
          id: "economico",
          weight: 0,
        },
      ]);
      setLoading(false);

      setData(response.data);
      //console.log("API VAR ", process.env.REACT_APP_API_URL);
      //console.log("FRONT VAR ", process.env.REACT_FRONT_URL);
    }
    loadData();
  }, [params]);

  const IDcompany = localStorage.getItem("@legaro:companyID");

  const FinalResult = () => {
    if (data.generalScore >= 3001) {
      return (
        <div id="score-subtitle">
          <div className="score-subtitle-alta">
            <span className="text-score-subtitle">Resultado:</span> alta
            conformidade ESG
          </div>
        </div>
      );
    }
    if (data.generalScore <= 1500) {
      return (
        <div id="score-subtitle">
          <div className="score-subtitle-baixa">
            <span className="text-score-subtitle">Resultado:</span> baixa
            conformidade ESG
          </div>
        </div>
      );
    } else {
      return (
        <div id="score-subtitle">
          <div className="score-subtitle-media">
            <span className="text-score-subtitle">Resultado:</span> média
            conformidade ESG
          </div>
        </div>
      );
    }
  };

  const ExportPage = () => {
    const pdf = <ExportPage />;
    return pdf;
  };

  const FinalResultExplanation = () => {
    if (data.generalScore >= 3001) {
      return "A faixa do Score ESG obtida indica que a empresa aplica políticas de ESG condizentes com definições e normas nacionais e internacionais.";
    }
    if (data.generalScore <= 1500) {
      return "A faixa do Score ESG obtida indica que a empresa não aplica políticas de ESG condizentes com definições e normas nacionais e internacionais.";
    } else {
      return "A faixa do Score ESG obtida indica que a empresa aplica parcialmente políticas de ESG condizentes com definições e normas nacionais e internacionais.";
    }
  };

  //Exportação da página
  /*const exportTrigger = () => {
    setTimeout(function () {
      var accordion = document.getElementsByClassName("MuiCollapse-root")[0];
      accordion.classList.add("MuiCollapse-entered");
      accordion.style.height = "auto";
      accordion.classList.remove("MuiCollapse-hidden");
      document.getElementsByClassName("btn-accordion")[0].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[1].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[2].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[3].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[4].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[5].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[6].style.display =
        "none";
      document.getElementsByClassName("btn-accordion")[7].style.display =
        "none";

      var companyName = document.getElementById("ExportCompany");
      companyName.style.fontSize = "10px";
      var userName = document.getElementsByClassName("MuiButton-label")[0];
      userName.style.fontSize = "10px";

      var accordion2 = document.getElementsByClassName("MuiCollapse-root")[1];
      accordion2.classList.add("MuiCollapse-entered");
      accordion2.style.height = "auto";
      accordion2.classList.remove("MuiCollapse-hidden");

      var accordion3 = document.getElementsByClassName("MuiCollapse-root")[2];
      accordion3.classList.add("MuiCollapse-entered");
      accordion3.style.height = "auto";
      accordion3.classList.remove("MuiCollapse-hidden");

      var accordion4 = document.getElementsByClassName("MuiCollapse-root")[3];
      accordion4.classList.add("MuiCollapse-entered");
      accordion4.style.height = "auto";

      accordion4.classList.remove("MuiCollapse-hidden");

      var accordion5 = document.getElementsByClassName("MuiCollapse-root")[4];
      accordion5.classList.add("MuiCollapse-entered");
      accordion5.style.height = "auto";
      accordion5.classList.remove("MuiCollapse-hidden");

      var accordion6 = document.getElementsByClassName("MuiCollapse-root")[5];
      accordion6.classList.add("MuiCollapse-entered");
      accordion6.style.height = "auto";
      accordion6.classList.remove("MuiCollapse-hidden");

      var accordion7 = document.getElementsByClassName("MuiCollapse-root")[6];
      accordion7.classList.add("MuiCollapse-entered");
      accordion7.style.height = "auto";
      accordion7.classList.remove("MuiCollapse-hidden");

      var accordion8 = document.getElementsByClassName("MuiCollapse-root")[7];
      accordion8.classList.add("MuiCollapse-entered");
      accordion8.style.height = "auto";
      accordion8.classList.remove("MuiCollapse-hidden");
/*
      /*Edição página */
  /*document.getElementsByClassName("session-score")[0].style.flexFlow =
        "column";
      document.getElementsByClassName("session-score")[0].style.justifyContent =
        "center";
      document.getElementsByClassName("session-themes")[0].style.paddingBottom =
        "0";
      document.getElementsByClassName("session-themes")[0].style.paddingTop =
        "0";
      document.getElementsByClassName("result-container")[0].style.margin =
        "5% 5%";
      document.getElementsByClassName("score-title")[0].style.justifyContent =
        "center";
      document.getElementsByClassName("score-text")[0].style.justifyContent =
        "center";
      document.getElementsByClassName("score-text")[0].style.margin = "auto";
      document.getElementsByClassName("score-text")[0].style.textAlign =
        "center";
      document.getElementsByClassName("compartilharExportar")[0].style.display =
        "flex";
      document.getElementsByClassName(
        "compartilharExportar"
      )[0].style.flexWrap = "wrap";
      document.getElementsByClassName(
        "compartilharExportar"
      )[0].style.justifyContent = "center";
      document.getElementsByClassName("compartilharExportar")[0].style.margin =
        "auto";
      document.getElementsByClassName("text-score-subtitle")[0].style.fontSize =
        "20px";
      /*document.getElementsByClassName(
        "score-subtitle-media"
      )[0].style.fontSize = "20px";
      document.getElementsByClassName("score-subtitle-alta")[0].style.fontSize =
        "20px";
      document.getElementsByClassName(
        "score-subtitle-baixa"
      )[0].style.fontSize = "20px";
      document.getElementsByClassName("score-text")[0].style.fontSize = "14px";
      document.getElementsByClassName("score-description")[0].style.margin =
        "0";

      document.getElementsByClassName("btn-share-score")[0].style.display =
        "none";
      document.getElementsByClassName("btn-export-score")[0].style.display =
        "none";
      document.getElementsByClassName("theme-title")[0].style.fontSize = "16px";
      document.getElementsByClassName("theme-title")[1].style.fontSize = "16px";
      document.getElementsByClassName("theme-title")[2].style.fontSize = "16px";
      document.getElementsByClassName("theme-title")[3].style.fontSize = "16px";
      document.getElementsByClassName("score-theme-high")[0].style.fontSize =
        "20px";
      document.getElementsByClassName("score-theme-high")[1].style.fontSize =
        "20px";
      document.getElementsByClassName("score-theme-high")[2].style.fontSize =
        "20px";
      document.getElementsByClassName("score-theme-high")[3].style.fontSize =
        "20px";
      document.getElementsByClassName("scoreTotal-high")[0].style.fontSize =
        "14px";
      document.getElementsByClassName("scoreTotal-high")[1].style.fontSize =
        "14px";
      document.getElementsByClassName("scoreTotal-high")[2].style.fontSize =
        "14px";
      document.getElementsByClassName("scoreTotal-high")[3].style.fontSize =
        "14px";
      document.getElementsByClassName("theme-link")[0].style.display = "none";
      document.getElementsByClassName("theme-link")[1].style.display = "none";
      document.getElementsByClassName("theme-link")[2].style.display = "none";
      document.getElementsByClassName("theme-link")[3].style.display = "none";
      document.getElementsByClassName("hr-theme-card")[0].style.display =
        document.getElementsByClassName("hr-theme-card")[1].style.display =
        document.getElementsByClassName("hr-theme-card")[2].style.display =
        document.getElementsByClassName("hr-theme-card")[3].style.display =
          "none";
      document.getElementsByClassName("theme-details")[0].style.display =
        "none";
      document.getElementsByClassName("theme-details")[1].style.display =
        "none";
      document.getElementsByClassName("theme-details")[2].style.display =
        "none";
      document.getElementsByClassName("theme-details")[3].style.display =
        "none";

      document.getElementsByClassName("theme-item-link")[0].style.display =
        "none";
      document.getElementsByClassName("theme-item-link")[1].style.display =
        "none";
      document.getElementsByClassName("theme-item-link")[2].style.display =
        "none";
      document.getElementsByClassName("theme-item-link")[3].style.display =
        "none";
      document.getElementsByClassName("theme-item-title")[0].style.fontSize =
        "18px";
      document.getElementsByClassName("theme-item-title")[1].style.fontSize =
        "18px";
      document.getElementsByClassName("theme-item-title")[2].style.fontSize =
        "18px";
      document.getElementsByClassName("theme-item-title")[3].style.fontSize =
        "18px";

      //AccordionResultsAmbiental
      var accordionFontSize1 = document.getElementById("ExportFontSize1");
      var accordionFontSize2 = document.getElementById("ExportFontSize2");
      var accordionFontSize3 = document.getElementById("ExportFontSize3");
      var accordionFontSize4 = document.getElementById("ExportFontSize4");
      var accordionFontSize5 = document.getElementById("ExportFontSize5");
      var accordionFontSize6 = document.getElementById("ExportFontSize6");
      var accordionFontSize7 = document.getElementById("ExportFontSize7");
      var accordionFontSize8 = document.getElementById("ExportFontSize8");
      var accordionFontSize9 = document.getElementById("ExportFontSize9");
      var accordionFontSize10 = document.getElementById("ExportFontSize10");
      //AccordionResultsEconomico
      var accordionFontSize11 = document.getElementById("ExportFontSize11");
      var accordionFontSize12 = document.getElementById("ExportFontSize12");
      var accordionFontSize13 = document.getElementById("ExportFontSize13");
      var accordionFontSize14 = document.getElementById("ExportFontSize14");
      var accordionFontSize15 = document.getElementById("ExportFontSize15");
      var accordionFontSize16 = document.getElementById("ExportFontSize16");
      var accordionFontSize17 = document.getElementById("ExportFontSize17");
      var accordionFontSize18 = document.getElementById("ExportFontSize18");
      var accordionFontSize19 = document.getElementById("ExportFontSize19");
      var accordionFontSize20 = document.getElementById("ExportFontSize20");
      //AccordionResultsGovernance
      var accordionFontSize21 = document.getElementById("ExportFontSize21");
      var accordionFontSize22 = document.getElementById("ExportFontSize22");
      var accordionFontSize23 = document.getElementById("ExportFontSize23");
      var accordionFontSize24 = document.getElementById("ExportFontSize24");
      var accordionFontSize25 = document.getElementById("ExportFontSize25");
      var accordionFontSize26 = document.getElementById("ExportFontSize26");
      var accordionFontSize27 = document.getElementById("ExportFontSize27");
      var accordionFontSize28 = document.getElementById("ExportFontSize28");
      var accordionFontSize29 = document.getElementById("ExportFontSize29");
      var accordionFontSize30 = document.getElementById("ExportFontSize30");
      //AccordionResultsSocial
      var accordionFontSize31 = document.getElementById("ExportFontSize31");
      var accordionFontSize32 = document.getElementById("ExportFontSize32");
      var accordionFontSize33 = document.getElementById("ExportFontSize33");
      var accordionFontSize34 = document.getElementById("ExportFontSize34");
      var accordionFontSize35 = document.getElementById("ExportFontSize35");
      var accordionFontSize36 = document.getElementById("ExportFontSize36");
      var accordionFontSize37 = document.getElementById("ExportFontSize37");
      var accordionFontSize38 = document.getElementById("ExportFontSize38");
      var accordionFontSize39 = document.getElementById("ExportFontSize39");
      var accordionFontSize40 = document.getElementById("ExportFontSize40");

      //AccordionResultsAmbiental
      accordionFontSize1.style.fontSize = "11px";
      accordionFontSize2.style.fontSize = "11px";
      accordionFontSize3.style.fontSize = "11px";
      accordionFontSize4.style.fontSize = "11px";
      accordionFontSize5.style.fontSize = "11px";
      accordionFontSize6.style.fontSize = "11px";
      accordionFontSize7.style.fontSize = "11px";
      accordionFontSize8.style.fontSize = "11px";
      accordionFontSize9.style.fontSize = "11px";
      accordionFontSize10.style.fontSize = "11px";
      //AccordionResultsEconomico
      accordionFontSize11.style.fontSize = "11px";
      accordionFontSize12.style.fontSize = "11px";
      accordionFontSize13.style.fontSize = "11px";
      accordionFontSize14.style.fontSize = "11px";
      accordionFontSize15.style.fontSize = "11px";
      accordionFontSize16.style.fontSize = "11px";
      accordionFontSize17.style.fontSize = "11px";
      accordionFontSize18.style.fontSize = "11px";
      accordionFontSize19.style.fontSize = "11px";
      accordionFontSize20.style.fontSize = "11px";
      //AccordionResultsGovernance
      accordionFontSize21.style.fontSize = "11px";
      accordionFontSize22.style.fontSize = "11px";
      accordionFontSize23.style.fontSize = "11px";
      accordionFontSize24.style.fontSize = "11px";
      accordionFontSize25.style.fontSize = "11px";
      accordionFontSize26.style.fontSize = "11px";
      accordionFontSize27.style.fontSize = "11px";
      accordionFontSize28.style.fontSize = "11px";
      accordionFontSize29.style.fontSize = "11px";
      accordionFontSize30.style.fontSize = "11px";
      //AccordionResultsSocial
      accordionFontSize31.style.fontSize = "11px";
      accordionFontSize32.style.fontSize = "11px";
      accordionFontSize33.style.fontSize = "11px";
      accordionFontSize34.style.fontSize = "11px";
      accordionFontSize35.style.fontSize = "11px";
      accordionFontSize36.style.fontSize = "11px";
      accordionFontSize37.style.fontSize = "11px";
      accordionFontSize38.style.fontSize = "11px";
      accordionFontSize39.style.fontSize = "11px";
      accordionFontSize40.style.fontSize = "11px";

      var headerAjuda = document.getElementById("ExportAjuda");
      headerAjuda.style.display = "none";
      var headerPainel = document.getElementById("ExportPainel");
      headerPainel.style.display = "none";
      var headerLogo = document.getElementById("ExportLogo");
      headerLogo.style.width = "150px";
      /*var headerCompany = document.getElementById("ExportCompany");
      headerCompany.style.fontSize = "12px";
      var headerUser = document.getElementsByClassName("span.MuiButton-label");
      headerUser.style.fontSize = "12px";*/

  /*document.getElementById("FooterExport").style.display = "block";
      document.getElementById("footerExportLinksContainer").style.display =
        "none";
      document.getElementById("footerExportLContainer").style.marginTop =
        "375px";
      document.getElementById("ExportText").style.fontSize = "10px";
      document.getElementById("imagemLogo1").style.height = "30px";
      document.getElementById("imagemLogo2").style.height = "30px";
      document.getElementById("imagemLogo3").style.height = "30px";
      document.getElementById("imagemLogo4").style.height = "30px";*/

  //Tamanho do número do gráfico
  //document.getElementById("SvgjsText1059").style.fontSize = "70px";

  /*Fim edição página */

  /*handlePrint();
      setOnPrint(true);

      setTimeout(() => {
        accordion.classList.add("MuiCollapse-hidden");
        accordion.classList.remove("MuiCollapse-entered");
        accordion.style.height = "0px";
        accordion2.classList.add("MuiCollapse-hidden");
        accordion2.classList.remove("MuiCollapse-entered");
        accordion2.style.height = "0px";
        accordion3.classList.add("MuiCollapse-hidden");
        accordion3.classList.remove("MuiCollapse-entered");
        accordion3.style.height = "0px";
        accordion4.classList.add("MuiCollapse-hidden");
        accordion4.classList.remove("MuiCollapse-entered");
        accordion4.style.height = "0px";
        accordion5.classList.add("MuiCollapse-hidden");
        accordion5.classList.remove("MuiCollapse-entered");
        accordion5.style.height = "0px";
        accordion6.classList.add("MuiCollapse-hidden");
        accordion6.classList.remove("MuiCollapse-entered");
        accordion6.style.height = "0px";
        accordion6.classList.add("MuiCollapse-hidden");
        accordion6.classList.remove("MuiCollapse-entered");
        accordion6.style.height = "0px";
        accordion7.classList.add("MuiCollapse-hidden");
        accordion7.classList.remove("MuiCollapse-entered");
        accordion7.style.height = "0px";
        accordion8.classList.add("MuiCollapse-hidden");
        accordion8.classList.remove("MuiCollapse-entered");
        accordion8.style.height = "0px";

        document.getElementsByClassName("btn-accordion")[0].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[1].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[2].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[3].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[4].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[5].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[6].style.display =
          "flex";

        document.getElementsByClassName("btn-accordion")[7].style.display =
          "flex";*/

  /*Edição página */
  /*document.getElementsByClassName("session-score")[0].style.flexFlow =
          "row";
        document.getElementsByClassName("result-container")[0].style.margin =
          "10% 10%";
        document.getElementsByClassName("result-container")[0].style.marginTop =
          "5%";
        document.getElementsByClassName("score-title")[0].style.justifyContent =
          "start";
        document.getElementsByClassName("score-text")[0].style.justifyContent =
          "start";
        document.getElementsByClassName("score-text")[0].style.margin = "0";
        document.getElementsByClassName("score-text")[0].style.textAlign =
          "left";
        document.getElementsByClassName(
          "text-score-subtitle"
        )[0].style.fontSize = "40px";
        document.getElementsByClassName("score-text")[0].style.fontSize =
          "18px";
        document.getElementsByClassName(
          "compartilharExportar"
        )[0].style.justifyContent = "left";
        document.getElementsByClassName(
          "compartilharExportar"
        )[0].style.margin = "0";
        document.getElementsByClassName("btn-share-score")[0].style.display =
          "block";
        document.getElementsByClassName("btn-export-score")[0].style.display =
          "block";

        document.getElementsByClassName("theme-title")[0].style.fontSize =
          "25px";
        document.getElementsByClassName("theme-title")[1].style.fontSize =
          "25px";
        document.getElementsByClassName("theme-title")[2].style.fontSize =
          "25px";
        document.getElementsByClassName("theme-title")[3].style.fontSize =
          "25px";
        document.getElementsByClassName("score-theme-high")[0].style.fontSize =
          "40px";
        document.getElementsByClassName("score-theme-high")[1].style.fontSize =
          "40px";
        document.getElementsByClassName("score-theme-high")[2].style.fontSize =
          "40px";
        document.getElementsByClassName("score-theme-high")[3].style.fontSize =
          "40px";
        document.getElementsByClassName("scoreTotal-high")[0].style.fontSize =
          "22px";
        document.getElementsByClassName("scoreTotal-high")[1].style.fontSize =
          "22px";
        document.getElementsByClassName("scoreTotal-high")[2].style.fontSize =
          "22px";
        document.getElementsByClassName("scoreTotal-high")[3].style.fontSize =
          "22px";
        document.getElementsByClassName("theme-link")[0].style.display =
          "block";
        document.getElementsByClassName("theme-link")[1].style.display =
          "block";
        document.getElementsByClassName("theme-link")[2].style.display =
          "block";
        document.getElementsByClassName("theme-link")[3].style.display =
          "block";
        document.getElementsByClassName("hr-theme-card")[0].style.display =
          "block";
        document.getElementsByClassName("hr-theme-card")[1].style.display =
          "block";
        document.getElementsByClassName("hr-theme-card")[2].style.display =
          "block";
        document.getElementsByClassName("hr-theme-card")[3].style.display =
          "block";
        document.getElementsByClassName("theme-details")[0].style.display =
          "block";
        document.getElementsByClassName("theme-details")[1].style.display =
          "block";
        document.getElementsByClassName("theme-details")[2].style.display =
          "block";
        document.getElementsByClassName("theme-details")[3].style.display =
          "block";
        document.getElementsByClassName(
          "session-themes"
        )[0].style.paddingBottom = "5vh";
        document.getElementsByClassName("session-themes")[0].style.paddingTop =
          "5vh";

        document.getElementsByClassName("theme-item-link")[0].style.display =
          "block";
        document.getElementsByClassName("theme-item-link")[1].style.display =
          "block";
        document.getElementsByClassName("theme-item-link")[2].style.display =
          "block";
        document.getElementsByClassName("theme-item-link")[3].style.display =
          "block";
        document.getElementsByClassName("theme-item-title")[0].style.fontSize =
          "27px";
        document.getElementsByClassName("theme-item-title")[1].style.fontSize =
          "27px";
        document.getElementsByClassName("theme-item-title")[2].style.fontSize =
          "27px";
        document.getElementsByClassName("theme-item-title")[3].style.fontSize =
          "27px";

        //AccordionResultsAmbiental
        accordionFontSize1.style.fontSize = "15px";
        accordionFontSize2.style.fontSize = "15px";
        accordionFontSize3.style.fontSize = "15px";
        accordionFontSize4.style.fontSize = "15px";
        accordionFontSize5.style.fontSize = "15px";
        accordionFontSize6.style.fontSize = "15px";
        accordionFontSize7.style.fontSize = "15px";
        accordionFontSize8.style.fontSize = "15px";
        accordionFontSize9.style.fontSize = "15px";
        accordionFontSize10.style.fontSize = "15px";
        //AccordionResultsEconomico
        accordionFontSize11.style.fontSize = "15px";
        accordionFontSize12.style.fontSize = "15px";
        accordionFontSize13.style.fontSize = "15px";
        accordionFontSize14.style.fontSize = "15px";
        accordionFontSize15.style.fontSize = "15px";
        accordionFontSize16.style.fontSize = "15px";
        accordionFontSize17.style.fontSize = "15px";
        accordionFontSize18.style.fontSize = "15px";
        accordionFontSize19.style.fontSize = "15px";
        accordionFontSize20.style.fontSize = "15px";
        //AccordionResultsGovernance
        accordionFontSize21.style.fontSize = "15px";
        accordionFontSize22.style.fontSize = "15px";
        accordionFontSize23.style.fontSize = "15px";
        accordionFontSize24.style.fontSize = "15px";
        accordionFontSize25.style.fontSize = "15px";
        accordionFontSize26.style.fontSize = "15px";
        accordionFontSize27.style.fontSize = "15px";
        accordionFontSize28.style.fontSize = "15px";
        accordionFontSize29.style.fontSize = "15px";
        accordionFontSize30.style.fontSize = "15px";
        //AccordionResultsSocial
        accordionFontSize31.style.fontSize = "15px";
        accordionFontSize32.style.fontSize = "15px";
        accordionFontSize33.style.fontSize = "15px";
        accordionFontSize34.style.fontSize = "15px";
        accordionFontSize35.style.fontSize = "15px";
        accordionFontSize36.style.fontSize = "15px";
        accordionFontSize37.style.fontSize = "15px";
        accordionFontSize38.style.fontSize = "15px";
        accordionFontSize39.style.fontSize = "15px";
        accordionFontSize40.style.fontSize = "15px";

        document.getElementById("FooterExport").style.display = "none";
        document.getElementById("footerExportLinksContainer").style.display =
          "flex";
        document.getElementById("ExportText").style.fontSize = "14px";
        headerAjuda.style.display = "flex";
        headerPainel.style.display = "flex";
        headerLogo.style.width = "200px";
        document.getElementById("footerExportLContainer").style.marginTop = "0";
        document.getElementById("imagemLogo1").style.height = "55px";
        document.getElementById("imagemLogo2").style.height = "55px";
        document.getElementById("imagemLogo3").style.height = "55px";
        document.getElementById("imagemLogo4").style.height = "55px";

        companyName.style.fontSize = "initial";
        userName.style.fontSize = "initial";*/
  /*Fim edição página */
  /*setOnPrint(false);
      }, 600);
    }, 600);
  };*/

  return (
    <div ref={componentRef}>
      <div id="divToPrint" style={{ height: "2mm" }} />
      <Page singleMode={false} id="onePage">
        <div className="containerAllResults">
          <div className="docHeader" id="docHeader">
            {/*<HeaderAfterLogin />*/}
            {/*INÍCIO DO NOVO MENU*/}
            <Nav>
              <NavbarContainer>
                <NavLogo to="/">
                  <img
                    src={
                      "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.svg"
                    }
                    alt="LEGARO"
                    width="200px"
                    id="ExportLogo"
                  />
                </NavLogo>

                <NavMenu id="ExportNavMenu">
                  <NavLinks style={{ cursor: "default" }}>
                    <div
                      id="ExportCompany"
                      style={{
                        color: "#666666",
                        textTransform: "lowercase",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "default",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BusinessIcon
                        style={{
                          color: "#6FA824",
                          marginRight: "15px",
                          cursor: "default",
                        }}
                      />
                      {data.company?.name}
                    </div>
                  </NavLinks>
                </NavMenu>
              </NavbarContainer>
            </Nav>
            {/*FIM DO NOVO MENU*/}
          </div>
          <div className="result-container">
            {!loading && (
              <div className="session-score">
                <div className="score-graph">
                  {data.company && <Chart data={data} id="ExportChart" />}
                </div>

                <div className="score-description">
                  <div className="score-title">{FinalResult()}</div>
                  <div className="score-text">{FinalResultExplanation()}</div>
                  <br />
                  {/*<Link to="/atualizar-questionario">
              <button
                className="btn-atualize-score"
                style={{ cursor: "pointer" }}
              >
                atualizar Score <i className="fas fa-arrow-right"></i>
              </button>
            </Link>*/}
                  {/* {isModalVisible ? (
                    <UIModal onClose={() => setIsModalVisible(false)}>
                      <h1>Compartilhar Score</h1>
                      <div className="modal-links">
                        <div className="fa-3x fb-share-button" id="face">
                          <a
                            target="blank"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${frontURL}/compartilhe-seus-resultados/${IDcompany}`}
                            className="fb-xfbml-parse-ignore"
                          >
                            <i
                              style={{ color: "#1877F2" }}
                              className="fab fa-facebook-square"
                            ></i>
                          </a>
                        </div>

                        <div className="fa-3x">
                          <a
                            href={`https://api.whatsapp.com/send?text=${frontURL}/compartilhe-seus-resultados/${IDcompany}`}
                            target="blank"
                          >
                            <i id="whatsapp" className="fab fa-whatsapp"></i>
                          </a>
                        </div>
                        <div className="fa-3x">
                          <a
                            target="_blank"
                            href={`https://mail.google.com/mail/?extsrc=mailto&url=mailto%3A%3Fto%3D%26bcc%3D%2C%26subject%3DLEGARO%2520|%2520Obtenha%2520seu%2520score%2520ESG%26body%3DAcesse ${frontURL}/compartilhe-seus-resultados/${IDcompany} e obtenha seu score ESG.`}
                          >
                            <span className="fab fa-gmail"></span>
                          </a>
                        </div>
                        <div className="fa-3x">
                          <a
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${frontURL}/compartilhe-seus-resultados/${IDcompany}`}
                            target="blank"
                          >
                            <span id="linkedin" className="fab fa-linkedin"></span>
                          </a>
                        </div>
                      </div>
                    </UIModal>
                  ) : null} */}
                </div>
              </div>
            )}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="fa fa-spinner fa-pulse"
                ></i>
                <span style={{ marginLeft: 5 }}>
                  Buscando os temas e os indicadores 🕐
                </span>
              </div>
            )}
            {data && (
              <div className="session-themes">
                {sortArray(newData, {
                  by: "weight",
                  order: "desc",
                }).map((tema, index) => {
                  if (tema.id === "ambiental") {
                    return Ambiental(data, IDcompany, index);
                  }
                  if (tema.id === "social") {
                    return Social(data, IDcompany, index);
                  }
                  if (tema.id === "governanca") {
                    return Governanca(data, IDcompany, index);
                  }
                  if (tema.id === "economico") {
                    return Economico(data, IDcompany, index);
                  }
                })}
              </div>
            )}
            {/** INDICADORES **/}
            <div className="session-themes-score">
              {sortArray(newData, {
                by: "weight",
                order: "desc",
              }).map((tema, index) => {
                if (tema.id === "ambiental") {
                  return AmbientalIndicator(
                    index,
                    IDcompany,
                    data?.performancesIndicadores?.ambiental
                  );
                }
                if (tema.id === "social") {
                  return SocialIndicator(
                    index,
                    IDcompany,
                    data?.performancesIndicadores?.social
                  );
                }
                if (tema.id === "governanca") {
                  return GovernancaIndicator(
                    index,
                    IDcompany,
                    data?.performancesIndicadores?.governanca
                  );
                }
                if (tema.id === "economico") {
                  return EconomicoIndicator(
                    index,
                    IDcompany,
                    data?.performancesIndicadores?.economico
                  );
                }
              })}
            </div>
          </div>
          <div id="docFooter">
            {/*<FooterAfterLogin />*/}
            {/*INÍCIO DO NOVO FOOTER*/}
            <FooterContainer id="footerExportLContainer">
              <SocialMedia>
                <SocialMediaWrap>
                  <SocialLogo>
                    <img
                      src={
                        "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_SEM_SLOGAN.svg"
                      }
                      alt="LEGARO"
                    />
                  </SocialLogo>
                  <SocialIcons>
                    <SocialIconLink
                      to={{
                        pathname: "https://www.facebook.com/CodexCorporacao",
                      }}
                      target="_blank"
                      aria-label="Facebook"
                    >
                      <FaFacebook />
                    </SocialIconLink>
                    <SocialIconLink
                      to={{
                        pathname: "https://www.instagram.com/codexcorporacao/",
                      }}
                      target="_blank"
                      aria-label="Instagram"
                    >
                      <FaInstagram />
                    </SocialIconLink>
                    <SocialIconLink
                      to={{
                        pathname:
                          "https://www.youtube.com/channel/UCaPqUiAZpbeXPQbpJPJrvdQ",
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="Youtube"
                    >
                      <FaYoutube />
                    </SocialIconLink>
                    <SocialIconLink
                      to={{ pathname: "https://twitter.com/codexremote" }}
                      target="_blank"
                      aria-label="Twitter"
                    >
                      <FaTwitter />
                    </SocialIconLink>
                    <SocialIconLink
                      to={{
                        pathname: "https://br.linkedin.com/company/codexremote",
                      }}
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <FaLinkedin />
                    </SocialIconLink>
                  </SocialIcons>
                  <WebsiteRights>LEGARO © 2021</WebsiteRights>
                </SocialMediaWrap>
              </SocialMedia>
              <FooterLinksWrapperHelpExport id="FooterExport">
                <FooterLinkSubTitle style={{ marginRight: "15px" }}>
                  Entre em contato conosco:
                </FooterLinkSubTitle>
                <FooterLinkText>
                  +55 51 3209-4722 | +55 51 3206-0379 | contato@legaroesg.com.br
                  | Av. Borges de Medeiros. 695/5º Andar, Centro Histórico |
                  Porto Alegre / RS - CEP 90.020-023
                </FooterLinkText>
              </FooterLinksWrapperHelpExport>
              <FooterInformation>
                <hr color="#666666" />
                <p id="ExportText">
                  A LEGARO é um produto independente e pertencente à Codex.{" "}
                  <br />
                  Toda comunicação através da rede mundial de computadores está
                  sujeita a interrupções ou atrasos, podendo impedir ou
                  prejudicar o envio ou a recepção de informações atualizadas.
                </p>
              </FooterInformation>
            </FooterContainer>
            {/*FIM DO NOVO FOOTER*/}
          </div>
        </div>
      </Page>
    </div>
  );
}

export default ResultScoreFial;

// TEMAS
function Ambiental(data, IDcompany, index) {
  return (
    <div className="theme-card" id="theme-card-id-1">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon06.png"
          height="55px"
          alt="Ambiental"
          id="imagemLogo1"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Ambiental</div>
      <div className="mostraScore">
        <span className="score-theme-high">{data.ambientalScore}</span>
        <span className="scoreTotal-high">
          de {data.positionAmbientalWeight}
        </span>
      </div>
      <br />
      {/*<a
        className="theme-link"
        href={`/atualizar-questionario/${IDcompany}/ambiental-financeiro`}
        data-html2canvas-ignore={true}
      >
        Atualizar <i className="fas fa-arrow-right"></i>
      </a>*/}
      <hr className="hr-theme-card" data-html2canvas-ignore={true} />
      <Link
        to="sectionAmbiental"
        offset={-120}
        smooth={true}
        data-html2canvas-ignore={true}
      >
        <div className="theme-details">
          {" "}
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Social(data, IDcompany, index) {
  return (
    <div className="theme-card" id="theme-card-id-2">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon03.png"
          height="55px"
          alt="Social"
          id="imagemLogo2"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Social</div>
      <div className="mostraScore">
        <span className="score-theme-high"> {data.socialScore}</span>
        <span className="scoreTotal-high">de {data.positionSocialWeight}</span>
      </div>
      <br />
      {/*<a
        className="theme-link"
        href={`/atualizar-questionario/${IDcompany}/social`}
        data-html2canvas-ignore={true}
      >
        Atualizar <i className="fas fa-arrow-right"></i>
      </a>*/}
      <hr className="hr-theme-card" data-html2canvas-ignore={true} />
      <Link
        to="sectionSocial"
        offset={-120}
        smooth={true}
        data-html2canvas-ignore={true}
      >
        <div className="theme-details">
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Governanca(data, IDcompany, index) {
  return (
    <div className="theme-card" id="theme-card-id-3">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon12.png"
          height="55px"
          alt="Governança"
          id="imagemLogo3"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Governança</div>
      <div className="mostraScore">
        <span className="score-theme-high">{data.governancaScore}</span>
        <span className="scoreTotal-high">
          de {data.positionGovernancaWeight}
        </span>
      </div>
      <br />
      {/*<a
        className="theme-link"
        href={`/atualizar-questionario/${IDcompany}/governanca`}
        data-html2canvas-ignore={true}
      >
        Atualizar <i className="fas fa-arrow-right"></i>
      </a>*/}
      <hr className="hr-theme-card" data-html2canvas-ignore={true} />
      <Link
        to="sectionGovernanca"
        offset={-120}
        smooth={true}
        data-html2canvas-ignore={true}
      >
        <div className="theme-details">
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

function Economico(data, IDcompany, index) {
  return (
    <div className="theme-card" id="theme-card-id-4">
      <div className="theme-icon">
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon09.png"
          height="55px"
          alt="Econômico"
          id="imagemLogo4"
        />
      </div>
      <div className="theme-number">Tema {index + 1}</div>
      <div className="theme-title">Econômico</div>
      <div className="mostraScore">
        <span className="score-theme-high">{data.economicoScore}</span>
        <span className="scoreTotal-high">
          de {data.positionEconomicoWeight}
        </span>
      </div>
      <br />
      {/*<a
        className="theme-link"
        href={`/atualizar-questionario/${IDcompany}/economico`}
        data-html2canvas-ignore={true}
      >
        Atualizar <i className="fas fa-arrow-right"></i>
      </a>*/}
      <hr className="hr-theme-card" data-html2canvas-ignore={true} />
      <Link
        to="sectionEconomico"
        offset={-120}
        smooth={true}
        data-html2canvas-ignore={true}
      >
        <div className="theme-details">
          Ver Detalhes <i className="fas fa-angle-double-down"></i>
        </div>
      </Link>
    </div>
  );
}

// INDICADORES
function AmbientalIndicator(index, IDcompany, indicadores) {
  const names = _.keys(indicadores);

  const indicadoresAmbiental = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionAmbiental">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Ambiental</div>
        <div className="theme-container-links">
          {/*<a className="theme-item-link" href="/">
          Ver respostas <i className="fas fa-arrow-right"></i>
        </a> */}
          {/*<a
            className="theme-item-link"
            href={`/atualizar-questionario/${IDcompany}/ambiental`}
            data-html2canvas-ignore={true}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>*/}
        </div>
      </div>
      <div className="theme-score-container">
        <AccordionResultsAmbiental indicadores={indicadoresAmbiental} />
      </div>
    </div>
  );
}

function SocialIndicator(index, IDcompany, indicadores) {
  const names = _.keys(indicadores);

  const indicadoresSocial = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionSocial">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Social</div>
        <div className="theme-container-links">
          {/*<a className="theme-item-link" href="/">
          Ver respostas <i className="fas fa-arrow-right"></i>
        </a> */}
          {/*<a
            className="theme-item-link"
            href={`/atualizar-questionario/${IDcompany}/social`}
            data-html2canvas-ignore={true}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>*/}
        </div>
      </div>
      <div className="theme-score-container">
        <AccordionResultsSocial indicadores={indicadoresSocial} />
      </div>
    </div>
  );
}

function EconomicoIndicator(index, IDcompany, indicadores) {
  const names = _.keys(indicadores);

  const indicadoresEconomico = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionEconomico">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Econômico</div>
        <div className="theme-container-links">
          {/*<a className="theme-item-link" href="/">
          Ver respostas <i className="fas fa-arrow-right"></i>
        </a> */}
          {/*<a
            className="theme-item-link"
            href={`/atualizar-questionario/${IDcompany}/economico`}
            data-html2canvas-ignore={true}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>*/}
        </div>
      </div>
      <div className="theme-score-container">
        <AccordionResultsEconomico indicadores={indicadoresEconomico} />
      </div>
    </div>
  );
}

function GovernancaIndicator(index, IDcompany, indicadores) {
  const names = _.keys(indicadores);

  const indicadoresGovernanca = sortArray(
    names.map((name) => ({
      title: name,
      value: indicadores[name],
    })),
    {
      by: "value",
      order: "desc",
    }
  );

  return (
    <div className="theme-item" id="sectionGovernanca">
      <div className="theme-actions">
        <div className="theme-item-number">Tema {index + 1}</div>
        <div className="theme-item-title">Governança</div>
        <div className="theme-container-links">
          {/*<a className="theme-item-link" href="/">
            Ver respostas <i className="fas fa-arrow-right"></i>
          </a> */}
          {/*<a
            className="theme-item-link"
            href={`/atualizar-questionario/${IDcompany}/governanca`}
            data-html2canvas-ignore={true}
          >
            Atualizar <i className="fas fa-arrow-right"></i>
          </a>*/}
        </div>
      </div>
      <div className="theme-score-container">
        <AccordionResultsGovernanca indicadores={indicadoresGovernanca} />
      </div>
    </div>
  );
}
