import styled from "styled-components";
import { FaMagento } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";

export const Nav = styled.nav`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
`;

export const NavLi = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;

  ${Container}
`;

export const NavLogo = styled(Link)`
  color: #444444;
  justify-self: flex-start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-weight: bold;

  img {
    @media screen and (max-width: 1170px) {
      width: 150px;
    }
  }
`;

export const NavIcon = styled(FaMagento)`
  margin-right: 0.5rem;
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
    font-size: 1.5rem;
    cursor: pointer;
    //background: #101522;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #f2f2f2;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;

  @media screen and (max-width: 960px) {
    /* width: 100%; */
    &:hover {
      border: none;
    }
  }

  span {
    color: blue;
    font-size: 16px;
  }
`;

export const NavItemBtn = styled.li`
  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: 120px;
  }
`;

export const NavLinks = styled(Link)`
  color: #444444;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-weight: bold;
  background-color: white !important;
  cursor: pointer;

  select {
    border: none;
    outline: none;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #444444;
  }

  .dropbtn {
    color: #666666;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white !important;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    /* min-width: 100%; */
    z-index: 1;
  }
  .dropdown-content span {
    color: #666666;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a {
    color: #666666;
    //padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f2f4f0;
    transition: all 0.3s ease;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    /* width: 100%; */
    display: table;
    &:hover {
      color: #6fa824;
      transition: all 0.3s ease;
    }
  }
`;

export const LinkEditCompany = styled(Link)`
  text-decoration: none;

  &:active {
    color: #444444;
  }
`;

export const LinkEdit = styled(Link)`
  text-decoration: none;
  color: #006cff;
  font-weight: normal;

  &:active {
    color: #006cff;
  }
`;

export const PersonName = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  /* width: 100%; */
  border: none;
  outline: none;
`;

export const ButtonHeader = styled.button`
  border-radius: 30px;
  background: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  color: #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#6FA824" : "#6FA824")};
  }

  @media screen and (max-width: 960px) {
    /* width: 100%; */
  }
`;
