import React from "react";
import { TransitionAmbiental } from "../../components";
import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';

function TransitionAmbientalPage() {
  return (
    <>
      <HeaderAfterLogin />
      <TransitionAmbiental />
    </>
  );
}

export default TransitionAmbientalPage;
