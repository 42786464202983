import React from "react";
import { TransitionGovernanca } from "../../components";
import HeaderAfterLogin from '../../components/Header/HeaderAfterLogin';

function TransitionGovernancaPage() {
  return (
    <>
    <HeaderAfterLogin />
      <TransitionGovernanca />
    </>
  );
}

export default TransitionGovernancaPage;
