import React, {useState, useEffect} from 'react'
import PropTypes from "prop-types";
import {useSelector, useDispatch} from 'react-redux'
import {addMultipleAnswers, updateMultipleAnswers, deleteMultipleAnswer, deleteUpdateMultipleAnswer, updateMultRestrAnswers} from '../../store/answerReducers'

export const OptionCheckbox = ({id, idQuestion, indicatorId, index, slug,  categoryId,  answerList, answer}) => {
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const multipleAnswers = useSelector((state) => state.multipleAnswers)
  const dispatch = useDispatch()
  const [answeredList, setAnsewedList] = useState([])

  useEffect(() => {
    const isAnswered = multipleAnswers.find(answer => answer.idQuestion === idQuestion )
    
    if (isAnswered) {
      const {answerList: answered} = isAnswered
  
      setAnsewedList(answered)
    }
  }, [multipleAnswers, idQuestion])

  const handleSelect = () => {
    setToggleCheckBox(!toggleCheckBox)
    
    const isAnswered = multipleAnswers.find(answer => answer.idQuestion === idQuestion )
  
    // Questão já foi respondida e estou selecionando outra resposata
    if (isAnswered && !toggleCheckBox) {
      // minhas respostas anteriores
      const {answerList: answered} = isAnswered
      
      // idAnswer => id da resposta atual
      if (answerList.cantSelect === false) {

        dispatch(updateMultRestrAnswers({
          idQuestion,
          indicatorId,
          categoryId,
          answerList 
        }))
      } else {

        dispatch(updateMultipleAnswers({
          idQuestion,
          indicatorId,
          categoryId,
          answerList
        }))
      }

    }

    // Questão ainda não foi respondida e estou selecionando uma resposta
    if (!toggleCheckBox && !isAnswered) {
      dispatch(addMultipleAnswers({
        idQuestion,
        indicatorId,
        categoryId,
        answerList
      }))
    }
    
    // Questão ainda não foi respondida e estou descelecionando uma resposta
    if (toggleCheckBox && !isAnswered) {
      dispatch(deleteMultipleAnswer({
        idQuestion
      }))
    }

    // Questão já foi respondida e estou descelecionando uma resposta
    if (toggleCheckBox && isAnswered) {   
      const {answerList: answered} = isAnswered
      dispatch(deleteUpdateMultipleAnswer({
        answer: answerList.answer,
        weights: answerList.weights
      }))
    }


    // console.log("ANSWER", answer)
    // console.log("ID", id)
    // console.log("ID QUESTION", idQuestion)
    // console.log("answerList", answerList)
    // console.log("Category Id", categoryId)
  }

  return (
    <label
      htmlFor={`${slug}_${id}`}
    >
      <input
        id={`${slug}_${id}`}
        name={`${slug}_${idQuestion}`}
        type="checkbox"
        value={`${slug}_${id}`}
        // checked={toggleCheckBox}
        checked={
          answeredList.find(ans => ans._id === answerList._id) ? true : false
        }
        onClick={handleSelect}
      />
    <span>{answer}</span>
  </label>
  )
}

OptionCheckbox.propTypes = {
  id: PropTypes.string.isRequired,  
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  idQuestion: PropTypes.string.isRequired, 
  indicatorId: PropTypes.string, 
  categoryId: PropTypes.string.isRequired, 
  answerList: PropTypes.any.isRequired, 
  answer: PropTypes.string.isRequired
}