import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Link, animateScroll as scroll } from "react-scroll";

import { itens } from "./data";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import { FooterAfterLogin } from "../../components";
import {
  DataContainer,
  Letter,
  LettersContainer,
  Title,
} from "./glossario.elements";
import { Container } from "../../globalStyles";

//Ações de paginação customizada - Material UI

/* const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
  }

  td,
  th {
    border: 1px solid red;
    text-align: left;
  }

  th {
    background-color: red;
  }
  `
); */

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itens.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const itemsLength = () => {
    var itemsLength = itens.length;

    console.log(itemsLength);
  };
  /* const onClickLetterA = () => {
    var items = itens.find(function (item) {
      return item.titulo === "A";
    });
    console.log(items);

    return items;
  }; */

  return (
    <>
      <HeaderAfterLogin />
      <Container>
        <Title>Glossário</Title>
        <LettersContainer>
          <ul>
            <Link
              to=""
              offset={-120}
              smooth={true}
              data-html2canvas-ignore={true}
              onClick={itemsLength}
            >
              <li>A</li>
            </Link>
            <li onClick={itemsLength}>B</li>
            <li>C</li>
            <li>D</li>
            <li>E</li>
            <li>F</li>
            <li>G</li>
            <li>H</li>
            <li>I</li>
            <li>J</li>
            <li>K</li>
            <li>L</li>
            <li>M</li>
            <li>N</li>
            <li>O</li>
            <li>P</li>
            <li>Q</li>
            <li>R</li>
            <li>S</li>
            <li>T</li>
            <li>U</li>
            <li>V</li>
            <li>W</li>
            <li>X</li>
            <li>Y</li>
            <li>Z</li>
          </ul>
        </LettersContainer>
        <TableContainer component={Paper} style={{ border: "none" }}>
          {/* <Root sx={{ width: 500, maxWidth: "100%" }}> */}
          <Table
            sx={{ minWidth: 500 }}
            aria-label="custom pagination table"
            style={{ border: "none" }}
          >
            <TableBody>
              {/* <Letter id="letraA">A</Letter> */}
              {(rowsPerPage > 0
                ? itens.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : itens
              )
                /* .filter((item) => item.letra === "A") */
                .map((item) => (
                  <TableRow
                    key={item.name}
                    style={{
                      border: "none",
                      color: "#6fa824",
                      fontSize: "30px",
                      fontWeight: "bold",
                    }}
                    className="glossario-titulo"
                  >
                    {item.titulo}
                    <TableCell
                      style={{ border: "none" }}
                      className="glossario-termo"
                    >
                      {item.termo}
                    </TableCell>
                    <DataContainer>
                      <TableCell style={{ border: "none" }}>
                        {item.explicacao}
                      </TableCell>
                      <TableCell style={{ border: "none" }}>
                        {item.fonte}
                      </TableCell>
                    </DataContainer>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/*     </Root> */}
        </TableContainer>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={itens.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "Termos por página",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Container>
      <FooterAfterLogin />
    </>
  );
}
