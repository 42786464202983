import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../hooks/auth";
import jwt from "jsonwebtoken";
import * as S from "./styles";
import api from "../../services/api";
import qs from "qs";
import { CloseSignUpWindow, ModalImg } from "./styles";
import "./signUpText.css";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const SignUpText = () => {
  const [federation, setFederation] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { user } = useAuth();
  const url = history.location.search;
  const obj = qs.parse(url);

  const token = obj["?query"];
  console.log("QUERY", token);

  const decoded = token
    ? jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
    : null;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadFederation = async () => {
    try {
      setLoading(true);

      const federationId = decoded?.federationId || user?.federationId;
      const { data } = await api.get(`/federations/${federationId}`);
      setFederation(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadFederation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <div style={{ display: "flex" }}>
        {!loading && federation ? (
          <div>
            <CloseSignUpWindow>
              <Link to={`/sign-up?query=${token}`}>
                <ArrowBackIcon />
              </Link>
            </CloseSignUpWindow>
            <div
              dangerouslySetInnerHTML={{
                __html: `${federation?.signintext}`,
              }}
              style={{
                height: "95vh",
                margin: "auto 5vw",
                overflow: "hidden",
              }}
            />
          </div>
        ) : (
          <div class="closeBtn">
            <CloseSignUpWindow>
              <Link to="/">x</Link>
            </CloseSignUpWindow>{" "}
            <div>
              <ModalImg
                src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
                alt="someone"
                /* style={{ height: "45vh", margin: "27vh 0" }} */
                style={{ height: "45vh", margin: "27vh 35vh" }}
              />
            </div>
          </div>
        )}
      </div>
    </S.Container>
  );
};

export default SignUpText;
