import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import {
  Container,
  ButtonMoveAndAnswer,
  ButtonMoveAndAnswerEconomic,
  ButtonLogin,
} from "../../globalStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ScoreSection,
  ScoreWrapper,
  ScoreHeading,
  ScoreContainer,
  ScoreCardInfo,
  ScoreCardIcon,
  ScoreCardPlan,
  ScoreCardCost,
  ScoreCardFeatures,
  ScoreCardFeature,
  ScoreCardMove,
  ScoreCardEconomic,
  ScoreCardSome,
  ButtonNext,
} from "./Score.elements";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import api from "../../services/api";
import { toast } from "react-toastify";
import sortArray from "sort-array";
import { ScoreCardContainer } from "./Score.elements";

const modifyTitlte = {
  Ambiental: "Ambiental",
  Social: "Social",
  Governanca: "Governança",
};

function Score() {
  //const [category, setCategory] = useState([]);
  //const [characters, updateCharacters] = useState(category);
  const [newarray, setNewarray] = useState([]);
  const [newarrayAux, setNewarrayAux] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [fistTime, setFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const history = useHistory();

  useEffect(() => {
    setFirstTime(true);
    async function loadData() {
      const response = await api.get(`categories`);
      //setCategory(response.data);

      const newarrayAux = response.data.filter(function (element) {
        return (
          element._id !== "614ce5e4a9ed10f8b82ed487" &&
          element._id !== "614ce9e7a9ed10f8b82ed514"
        );
      });

      const companyId = localStorage.getItem("@legaro:companyID");

      const categories = JSON.parse(localStorage.getItem("@legaro:categories"));

      // if (!categories) {
      setLoading(true);
      const { data } = await api.get(`companies/${companyId}`);
      const {
        ambientalWeight,
        governancaWeight,
        socialWeight,
        economicoWeight,
      } = data.company;

      const categoryArray = newarrayAux.map((item) => {
        if (item._id === "614ce734a9ed10f8b82ed4c3") {
          return {
            ...item,
            weight: ambientalWeight,
          };
        }

        if (item._id === "614ce21ea9ed10f8b82ed41e") {
          return {
            ...item,
            weight: socialWeight,
          };
        }

        if (item._id === "614cdb8e138f5ef792524023") {
          return {
            ...item,
            weight: governancaWeight,
          };
        }
      });

      setLoading(false);
      setAllCategories(categoryArray);
      // }
      setNewarrayAux(newarrayAux);
    }
    loadData();

    // const categories = JSON.parse(localStorage.getItem('@legaro:categories'))

    // if (categories) {
    //   const newCategories = categories.filter(function(element) {
    //     return (
    //       element._id !== '614ce5e4a9ed10f8b82ed487' && element._id !== '614ce9e7a9ed10f8b82ed514'
    //     )
    //   });

    //   setAllCategories(newCategories)

    // }
  }, []);

  useEffect(() => {
    async function loadInitialPosition() {
      const companyId = localStorage.getItem("@legaro:companyID");
      const items = Array.from(newarrayAux);

      const { data } = await api.get(`companies/${companyId}`);
      const {
        ambientalWeight,
        governancaWeight,
        socialWeight,
        economicoWeight,
      } = data.company;

      // FISRT TIME
      if (
        ambientalWeight === 1 &&
        governancaWeight === 1 &&
        socialWeight === 1
      ) {
        const newItems = items.map((it, index) => {
          return generateNewItems(index, it);
        });

        newItems.push({
          weight: 1.35,
          _id: "614ce5e4a9ed10f8b82ed487",
          description:
            "Apresenta o desempenho econômico e financeiro da empresa,considerando riscos e oportunidades, assim como a organização de finanças e a disponibilização de investimentos alinhados às práticas de gestão.",
          image: "https://codexremote.com.br/esgimages/legaro/icon08.png",
          title: "Economico",
          priority: 4,
        });

        localStorage.setItem("@legaro:categories", JSON.stringify(newItems));
        // console.log("LOAD ITEMS", newItems)
      }

      // NOT FIRST TIME
      if (
        ambientalWeight !== 1 &&
        governancaWeight !== 1 &&
        socialWeight !== 1
      ) {
        const newItems = items.map((item, index) => {
          if (item._id === "614ce734a9ed10f8b82ed4c3") {
            return {
              ...item,
              weight: ambientalWeight,
            };
          }

          if (item._id === "614ce21ea9ed10f8b82ed41e") {
            return {
              ...item,
              weight: socialWeight,
            };
          }

          if (item._id === "614cdb8e138f5ef792524023") {
            return {
              ...item,
              weight: governancaWeight,
            };
          }
        });

        newItems.push({
          weight: 1.35,
          _id: "614ce5e4a9ed10f8b82ed487",
          description:
            "Apresenta o desempenho econômico e financeiro da empresa,considerando riscos e oportunidades, assim como a organização de finanças e a disponibilização de investimentos alinhados às práticas de gestão.",
          image: "https://codexremote.com.br/esgimages/legaro/icon08.png",
          title: "Economico",
          priority: 4,
        });

        localStorage.setItem("@legaro:categories", JSON.stringify(newItems));
      }
    }

    loadInitialPosition();
  }, [newarrayAux]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    setFirstTime(false);

    let auxList = [];

    const pegando = allCategories[result.source.index];
    const destino = allCategories[result.destination.index];

    const state = allCategories.map((state, index) => {
      if (index === result.source.index) {
        return generateNewItems(index, destino);
      }
      if (index === result.destination.index) {
        return generateNewItems(index, pegando);
      }
      return generateNewItems(index, state);
    });

    auxList = state;

    // console.log("REORDERED", reorderedItem)

    setNewarray((oldState) => auxList);
    setAllCategories((oldState) => auxList);

    localStorage.setItem(
      "@legaro:categories",
      JSON.stringify([
        ...auxList,
        {
          weight: 1.35,
          _id: "614ce5e4a9ed10f8b82ed487",
          description:
            "Apresenta o desempenho econômico e financeiro da empresa,considerando riscos e oportunidades, assim como a organização de finanças e a disponibilização de investimentos alinhados às práticas de gestão.",
          image: "https://codexremote.com.br/esgimages/legaro/icon08.png",
          title: "Economico",
          priority: 4,
        },
      ])
    );
  };

  const generateNewItems = (index, item) => {
    let weight;
    if (index === 0) {
      weight = 1.35;
    }

    if (index === 1) {
      weight = 1.2;
    }

    if (index === 2) {
      weight = 1.1;
    }

    return {
      weight: weight,
      _id: item._id,
      description: item.description,
      image: item.image,
      priority: item.priority,
      title: item.title,
    };
  };

  const handleBack = () => {
    history.push("/dashboard");
  };

  const gotToSelectTheme = async () => {
    const categories = JSON.parse(localStorage.getItem("@legaro:categories"));
    const companyId = localStorage.getItem("@legaro:companyID");
    try {
      await api.put(`companies/${companyId}`, {
        ambientalWeight: categories.find((cat) => cat.title === "Ambiental")
          .weight,
        governancaWeight: categories.find((cat) => cat.title === "Governanca")
          .weight,
        socialWeight: categories.find((cat) => cat.title === "Social").weight,
        economicoWeight: categories.find((cat) => cat.title === "Economico")
          .weight,
      });

      toast.success("Alterações salvas com sucesso! 😃");

      history.push(`/selecionar-tema/${params.stageId}`);
    } catch (error) {
      toast.error("Não foi possível salvar as alterações, tente novamente!");
    }
  };

  return (
    <IconContext.Provider value={{ color: "#a9b3c1", size: 64 }}>
      <Container>
        <HeaderAfterLogin />
        <ScoreHeading>
          Mova os temas conforme sua <br />
          ordem de prioridade
        </ScoreHeading>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i style={{ fontSize: 30 }} className="fa fa-spinner fa-pulse"></i>
            <span style={{ marginLeft: 5 }}>Buscando os temas...</span>
          </div>
        ) : (
          <ScoreSection>
            <ScoreWrapper>
              <ScoreContainer>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <ScoreCardSome
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {(fistTime
                          ? allCategories &&
                            sortArray(allCategories, {
                              by: "weight",
                              order: "desc",
                            })
                          : newarray
                        ).map(
                          (
                            { _id, title, label, image, description },
                            index
                          ) => {
                            return (
                              <Draggable
                                key={_id}
                                draggableId={_id}
                                index={index}
                              >
                                {(provided) => (
                                  <ScoreCardMove
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ScoreCardInfo displayPosition={index + 1}>
                                      <ScoreCardContainer>
                                        <ScoreCardIcon>
                                          <img src={image} alt={`${title}`} />
                                        </ScoreCardIcon>
                                        <ScoreCardPlan key={index}>
                                          Tema {index + 1}
                                        </ScoreCardPlan>
                                        <ScoreCardCost>
                                          {modifyTitlte[title]}
                                        </ScoreCardCost>
                                        <ScoreCardFeatures>
                                          <ScoreCardFeature>
                                            {description}
                                          </ScoreCardFeature>
                                        </ScoreCardFeatures>
                                      </ScoreCardContainer>
                                      <ButtonMoveAndAnswer>
                                        <strong>mover</strong>{" "}
                                        <i className="fas fa-arrows-alt"></i>
                                      </ButtonMoveAndAnswer>
                                    </ScoreCardInfo>
                                  </ScoreCardMove>
                                )}
                              </Draggable>
                            );
                          }
                        )}
                        {provided.placeholder}
                      </ScoreCardSome>
                    )}
                  </Droppable>
                </DragDropContext>
                {!loading && (
                  <ScoreCardEconomic>
                    <ScoreCardInfo className="economic_info">
                      <ScoreCardContainer>
                        <ScoreCardIcon>
                          <img
                            src="https://codexremote.com.br/esgimages/legaro/icon08.png"
                            alt="Econômico"
                          />
                        </ScoreCardIcon>
                        <ScoreCardPlan>Tema 4</ScoreCardPlan>
                        <ScoreCardCost>Econômico</ScoreCardCost>
                        <ScoreCardFeatures>
                          <ScoreCardFeature>
                            Apresenta o desempenho econômico e financeiro da
                            empresa, considerando riscos e oportunidades, assim
                            como a organização de finanças e a disponibilização
                            de investimentos alinhados às práticas de gestão.
                          </ScoreCardFeature>
                        </ScoreCardFeatures>
                      </ScoreCardContainer>
                      <ButtonMoveAndAnswerEconomic to="/selecionar-tema">
                        <strong>fixo</strong>
                      </ButtonMoveAndAnswerEconomic>
                    </ScoreCardInfo>
                  </ScoreCardEconomic>
                )}
              </ScoreContainer>
            </ScoreWrapper>
          </ScoreSection>
        )}
        {!loading && (
          <ButtonNext>
            <ButtonLogin
              type="submit"
              onClick={() => gotToSelectTheme()}
              style={{
                backgroundColor: "#6FA824",
                color: "#FFFFFF",
                marginLeft: "12px",
              }}
            >
              próximo
              <i
                style={{ marginLeft: "10px" }}
                className="fas fa-arrow-right"
              ></i>{" "}
            </ButtonLogin>
            {/*<Button onClick={() => gotToSelectTheme()}>próximo</Button>*/}
          </ButtonNext>
        )}
      </Container>
    </IconContext.Provider>
  );
}
export default Score;
