import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Accordion.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width: "90%" }}>
      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            <strong>O que é a Legaro?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Legaro é uma plataforma da empresa Codex que permite o diagnóstico
            de responsabilidade social, ambiental e de governança dentro de uma
            empresa por meio da pontuação ESG. A união de “Legado” e “Futuro”,
            constituem a marca do nosso produto que tem como objetivo ser o
            mediador do seu posicionamento quanto às métricas ESG.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>O que é ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            ESG é o termo em inglês que significa “Environmental, Social and
            Governance”, ou seja, se refere à parte ambiental, social e de
            governança de uma empresa. A partir dos fatores ESG, é possível
            entender a realidade em que a empresa está situada, a fim de que
            sejam implementadas práticas que melhorem o seu posicionamento junto
            a questões focadas no meio ambiente, no ambiente social interno e
            externo, e ainda garanta à organização uma forte governança para
            tomada de decisão.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>O que é Pontuação ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O Score ESG consiste em uma pontuação ou classificação que mede o
            desempenho da sua empresa em relação aos atributos ESG - ele mede a
            sustentabilidade e repercussão ética do seu negócio. Isso significa
            que a partir dessa pontuação, você entenderá se o seu negócio está
            de acordo com fatores ambientais, sociais e de governança.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>Por que o ESG é tão importante?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ser uma empresa atrativa para investimentos é essencial, certo? Sim!
            Desde 2020, quando Larry Fink, CEO da Black Rock, empresa que
            comanda um fundo entre 8 e 9 trilhões de investimentos mundiais,
            vendeu ações de empresas que não estavam de acordo com as
            estratégias ESG, os investidores não estão mais apenas interessados
            nos resultados financeiros de suas aplicações. Eles também têm
            interesse no seu impacto e no papel que seus ativos podem causar na
            promoção de questões globais.
            <br />
            Assim, além de estar bem posicionado e mostrar responsabilidade
            quanto ao meio ambiente e sustentabilidade, o ESG é imprescindível
            para que uma empresa seja alvo de investimentos.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>O que é maturidade ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O ESG é um termo indispensável para empresas que buscam retornos
            positivos e impacto de longo prazo na sociedade, no meio ambiente e
            no desempenho dos negócios. Maturidade ESG representa o bom
            posicionamento da empresa diante dos fatores ambiental, social e de
            governança, e a consolidação das práticas ESG - como o engajamento
            proativo de seus colaboradores; medidas que foquem na
            sustentabilidade; a formação de uma equipe capacitada e informada
            sobre ESG; aprimorar sua governança interna e relatar periodicamente
            sua história de sustentabilidade.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>Somente grandes empresas devem implementar o ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Não! Independentemente do tamanho, rendimento ou proporção, uma
            empresa necessita da implementação do ESG para se tornar mais
            atrativa a investimentos; para ter acesso a crédito com mais
            facilidade; conseguir gerenciar riscos e oportunidades de mercado;
            ter consciência com a segurança no trabalho dos seus colaboradores;
            oferecer produtos de maior qualidade; reduzir custos; ampliar os
            negócios; reter os melhores talentos, estabelecer e fortalecer a
            marca no mercado.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>Como implementar o E do ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dentro do “E” (Ambiental), você precisa entender o que cabe dentro
            da parte ambiental da sua empresa. Comece pela legalização, fique
            atento às licenças ambientais, cuide na hora de comprar matéria
            prima, engaje seus colaboradores, otimize recursos, gerencie
            cuidados da poluição do ar, da água, do solo.Tudo o que possui
            relação com o meio ambiente precisa ser cuidado!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>Como implementar o S do ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O “S” (Social), é confundido por muitos com ações fora do meio
            empresarial. Mas na verdade, ele representa as ações internas em
            conjunto com as ações externas de uma empresa. As ações sociais
            precisam começar pelos colaboradores internos, na preocupação que a
            empresa tem em reter talentos, em treinar quem trabalha, cuidar da
            saúde, do corpo e da mente de seus colaboradores.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>Como implementar o G do ESG?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dentro do G (Governança), temos as boas práticas de gestão
            corporativa, que nada mais são do que a definição dos objetivos da
            empresa. Dentro disso, se enquadram a questão ética, governança
            sustentável, valores, estratégias, organização em relação às
            políticas transformacionais, entre outros.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginBottom: "3%",
          borderRadius: "20px",
          boxShadow: "none",
          border: "1px solid #CCCCCC",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>
            <strong>O que é Greenwashing?</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Greenwashing do inglês, “lavagem verde”, pode ser definida como o
            ato enganoso de empresas, indústrias, governos, organizações e
            indivíduos que tentam promover práticas, produtos e serviços falsos
            e não ecologicamente corretos como serviços alinhados aos parâmetros
            ESG. Isso pode ser mostrado por meio de marcas, rótulos falsos e
            embalagens, usados para induzir seus clientes ou investidores a
            acreditar que sua marca é ecologicamente correta - quando na
            realidade não é.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
