import React, { useState } from "react";
//import { FiArrowLeft, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import api from "../../services/api";
import * as Yup from "yup";
//import { useSelector } from 'react-redux';
import {
  Container,
  Background,
  Content,
  ModalImg,
  CloseWindow,
  Title,
  Description,
} from "./Style.Elements";

function PasswordChange() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [hiddenPassword, setHiddenPassord] = useState(true);

  //const loading = useSelector(useAuth());

  async function handleSubmit({ password1, password2 }) {
    if (password1 !== password2) {
      return toast.error("Essas senhas não coincidem. Tente novamente.");
    }
    try {
      setLoading(true);
      const token = history.location.search.split("token=")[1];
      await api.post("change-password", {
        token: token,
        newPassword: password1,
      });

      setLoading(false);
      toast.success(`Senha alterada com sucesso!`);
      history.push("sign-in");
    } catch (error) {
      setLoading(false);
      toast.error("Não foi possível alterar sua senha.");
    }
  }

  return (
    <Container>
      <CloseWindow>
        <Link to="/">x</Link>
      </CloseWindow>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          className="lazy"
        />
      </Background>
      <Content>
        <Title>Redefinir senha de acesso</Title>
        <Description>
          Preencha os dados abaixo para redefir sua senha.
        </Description>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="password1">Nova senha</label>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              style={{ width: "100%" }}
              name="password1"
              spellCheck={false}
              type={hiddenPassword ? "password" : "text"}
              onclick="return clicked()"
              required
            />
            <div
              style={{ color: "#444", marginLeft: 10, cursor: "pointer" }}
              onClick={() => setHiddenPassord(!hiddenPassword)}
            >
              {hiddenPassword ? (
                <RemoveRedEyeOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </div>
          </div>

          <label htmlFor="password2">Confirme a nova senha</label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              style={{ width: "100%" }}
              name="password2"
              spellCheck={false}
              type={hiddenPassword ? "password" : "text"}
              onclick="return clicked()"
              required
            />
            <div
              style={{ color: "#444", marginLeft: 10, cursor: "pointer" }}
              onClick={() => setHiddenPassord(!hiddenPassword)}
            >
              {hiddenPassword ? (
                <RemoveRedEyeOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </div>
          </div>
          <button type="submit">
            {loading ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "SALVAR"
            )}
            {/*{loading ? 'Carregando...' : 'ENTRAR'}*/}
          </button>
          <Link to="/sign-in">Cancelar</Link>
        </Form>
      </Content>
    </Container>
  );
}

export default PasswordChange;
