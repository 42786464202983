import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  FaleComAGente,
  FormTitulo,
  Formulario,
  Legend,
  DivForm,
} from "./HelpForm.elements";
import api from "../../services/api";
import { Form } from "@rocketseat/unform";

const SubjectOptions = [
  { value: "", name: "Selecione o assunto" },
  { value: "Tema Ambiental", name: "Tema Ambiental" },
  {
    value: "Tema Governança",
    name: "Tema Governança",
  },
  {
    value: "Tema Econômico",
    name: "Tema Econômico",
  },
  {
    value: "Tema Social",
    name: "Tema Social",
  },
  {
    value: "A plataforma",
    name: "A plataforma",
  },
  {
    value: "Outros",
    name: "Outros",
  },
];

function HelpForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  async function handleSubmit() {
    if (!name || !email || !subject || !message || !subject) {
      return toast.error("Não foi enviar sua mensagem");
    }

    try {
      const response = await api.post("/helps", {
        name,
        email,
        message,
        subject,
      });

      //console.log("COMPANY FULL DATA", response);

      toast.success("Mensagem enviada!");

      window.location.reload(false);
    } catch (error) {
      //console.log("ERRO", error);
      return toast.error("Erro ao enviar sua mensagem");
    }
  }

  return (
    <FaleComAGente id="results" className="search-results">
      <FormTitulo>
        <h2>Não conseguiu tirar suas dúvidas?</h2>
        <p>Envie-nos uma mensagem através do formulário de contato.</p>
      </FormTitulo>

      <Formulario>
        <Form onSubmit={handleSubmit}>
          <Legend>
            <strong>Formulário de contato</strong>
          </Legend>
          <DivForm>
            <label htmlFor="name">
              Nome<span style={{ color: "#f00" }}> *</span>
            </label>
            <br />
            <input
              type="text"
              required
              minLength="8"
              name="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
            />
          </DivForm>
          <DivForm>
            <label htmlFor="email">
              E-mail<span style={{ color: "#f00" }}> *</span>
            </label>
            <br />
            <input
              name="email"
              id="email"
              className="input_formulario"
              type="email"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              onChange={(e) => setEmail(e.target.value)}
            />
          </DivForm>

          <DivForm>
            <label htmlFor="subject">
              Assunto<span style={{ color: "#f00" }}> *</span>
            </label>
            <br />
            <select name="subject" onChange={(e) => setSubject(e.target.value)}>
              {SubjectOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </DivForm>
          <DivForm>
            <label htmlFor="message">
              Mensagem<span style={{ color: "#f00" }}> *</span>
            </label>
            <br />
            <textarea
              className="input_formulario"
              name="message"
              id="message"
              placeholder=""
              required
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </DivForm>
          <button className="btn-submit" type="submit">
            Enviar
          </button>
        </Form>
      </Formulario>
    </FaleComAGente>
  );
}

export default HelpForm;
