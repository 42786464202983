import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { ButtonLogin } from "../../globalStyles";
import { Link } from "react-router-dom";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  PersonName,
  NavLi,
} from "./Style.elements";

import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useAuth } from "../../hooks/auth";
import api from "../../services/api";

function Header() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  const { user, signOut } = useAuth();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get(`companies/author/${user._id}`);
      setData(response.data.docs);
    }
    loadData();
  }, [user._id]);

  return (
    <>
      <IconContext.Provider value={{ color: "#101522" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              <img
                src={
                  "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.webp"
                }
                alt="LEGARO"
                width="200px"
                id="ExportLogo"
              />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks onClick={closeMobileMenu}>
                  <Link
                    to="/ajuda-id"
                    style={{ textDecoration: "none", color: "#444444" }}
                  >
                    Ajuda
                  </Link>
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks onClick={closeMobileMenu}>
                  <BusinessIcon style={{ color: "#6FA824" }} />
                  <NavLi>
                    <select>
                      {data.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <Link value="empresa">é como</Link>
                  </NavLi>
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks onClick={closeMobileMenu}>
                  <PersonIcon
                    style={{
                      color: "#6FA824",
                    }}
                  />
                  <div>
                    <PersonName>{user.name}</PersonName>
                    <select>
                      <option value="0">
                        <Link to="/editar-login">Editar perfil</Link>
                      </option>
                      <option value="1">
                        <span onClick={signOut}>Sair</span>
                      </option>
                    </select>
                  </div>
                  <KeyboardArrowDownIcon style={{ color: "#6FA824" }} />
                </NavLinks>
              </NavItem>

              <NavItemBtn>
                {button ? (
                  <ButtonLogin
                    onClick={signOut}
                    primary
                    style={{ borderColor: "#707070", color: "#707070" }}
                  >
                    Sair
                  </ButtonLogin>
                ) : (
                  <ButtonLogin
                    onClick={closeMobileMenu}
                    fontBig
                    primary
                    style={{ borderColor: "#707070", color: "#707070" }}
                  >
                    Sair
                  </ButtonLogin>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Header;
