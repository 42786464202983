import React from "react";
import { Footer, Navbar } from "../../components";
import { Title, Container, Content, Text, Image } from "./style.elements";

function AboutESG() {
  return (
    <Container>
      <Navbar />
      <Title>
        <strong>O que é ESG?</strong>
      </Title>
      <Content>
        <Image>
          <img
            src="https://codexremote.com.br/esgimages/legaro/Home1.webp"
            alt="imagem"
          />
        </Image>
        <Text>
          <h2>
            <strong>
              Entenda o que é ESG e quais os benefícios para sua empresa.
            </strong>
          </h2>{" "}
          <br />
          <p className="lead">
            O ESG (Environmental, Social and Governance) é o termo em inglês que
            se refere aos três pilares da sustentabilidade de um negócio:
            Ambiental, Social e Governança. A sigla está incorporada em
            estratégias de mitigação de risco, atitudes, crenças, comportamentos
            e investimentos de um negócio.
            <br />
            <br />
            As práticas ESG representam uma transformação de paradigma na
            relação entre investidores e organizações. Atualmente, qualquer ação
            associada à sustentabilidade e à preocupação ambiental e social, é
            tida como parte essencial da estratégia de inúmeras empresas.
            <br />
            <br />
            Deste modo, as medidas ESG são consideradas métricas que norteiam
            empresas diante de fatores como sustentabilidade, funcionários,
            comunidade, meio ambiente, investidores e clientes.
            <br />
            <br />
            O ESG tem se tornado um fator essencial para que uma organização
            tenha uma boa colocação no mercado, seja vista de forma positiva
            pela sociedade, esteja de acordo com questões sustentáveis e
            ambientais, e ainda se torne atrativa para investidores.
            <br />
            <br />
            Se a sua empresa busca crescimento, adotar o ESG é fundamental para
            que você obtenha sucesso! Entre os principais benefícios dessas
            práticas, destacam-se: a melhoria na gestão de risco das
            organizações; o posicionamento correto quanto às questões de
            sustentabilidade e meio ambiente; o engajamento dos investidores, da
            sua equipe, clientes e mercado; uma significativa melhora nos
            padrões de trabalho e claro, a redução de gastos e o direcionamento
            de custos que promovem o aumento do seu desempenho financeiro.
            <br />
            <br />E é exatamente para motivar o seu sucesso e positivar o seu
            efeito no mundo, que a Codex criou a Legaro!
          </p>
        </Text>
      </Content>
      <Footer />
    </Container>
  );
}

export default AboutESG;
