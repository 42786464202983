import styled from "styled-components";
//import { Link } from "react-router-dom";

export const FaleComAGente = styled.div`
  display: flex;
  margin-top: 30px;
  padding-bottom: 50px;
  align-items: start;
  width: 60%;

  @media screen and (max-width: 1290px) {
    align-items: flex-start;
    padding-left: 5%;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }
`;

export const FormTitulo = styled.div`
  color: #333333;
  width: 90%;

  @media screen and (max-width: 1290px) {
    width: 60%;
    align-items: left;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: left;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    width: 80%;
    font-weight: 500;
    margin-bottom: 3%;

    @media screen and (max-width: 1290px) {
      font-size: 30px;
      width: 80%;
      align-items: left;
      margin-left: 15%;
    }

    @media screen and (max-width: 960px) {
      font-size: 30px;
      width: 80%;
      align-items: left;
      margin-left: 0;
    }
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 15px;
    font-family: "Montserrat", sans-serif;
    width: 75%;

    @media screen and (max-width: 1290px) {
      margin-bottom: 3%;
      margin-left: 15%;
      width: 70%;
    }

    @media screen and (max-width: 960px) {
      margin-bottom: 3%;
      width: 100%;
      margin-left: 0;
    }
  }
`;

export const Formulario = styled.div`
  color: #333333;
  width: 100%;

  button {
    border-radius: 30px;
    background: white;
    white-space: nowrap;
    padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
    color: #707070;
    font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
    outline: none;
    border: 1px solid #707070;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1290px) {
    width: 100%;
    align-items: center;
    margin-right: 3%;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    align-items: left;
    margin-right: 10%;
  }
`;

export const Legend = styled.div`
  color: #333333;
  margin-bottom: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
`;

export const DivForm = styled.div`
  label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: #333333;
  }
  input,
  select,
  textarea {
    padding-left: 15px;
    color: #707070;
    height: 35px;
    width: 100%;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid #999999;
  }
`;

export const LabelFormulario = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333333;
`;

export const InputFormulario = styled.div`
  input,
  select,
  textarea {
    padding-left: 15px;
    color: #707070;
    height: 35px;
    width: 100%;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid #999999;
  }

  textarea {
    height: 100px;
    padding-top: 15px;
    resize: vertical;
  }
`;
