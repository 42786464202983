import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Container } from "./menu.elements";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-toastify";
//import { DialogRemoveAction } from "../../../components";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#fff",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#666666",
      },
    },
  },
}))(MenuItem);

//const actionID = localStorage.getItem("@legaro:actionID");

export default function CustomizedMenus({ id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [openDialog, setOpenDialog] = React.useState(false);
  const history = useHistory();
  const IDcompany = localStorage.getItem("@legaro:companyID");
  const perfomancesIndicatores = history.location.state;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleDeleteAction = async () => {
    try {
      await api.delete(`action-plans/${id}`);

      toast.success("Ação removida com sucesso!");
      window.location.reload();
    } catch (error) {
      toast.error("Não foi possível remover esta ação!");
    }
  };

  /*  const reload = () => {
    window.location.reload();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleClosedDialog = () => {
    setOpenDialog(false);
    reload();
  }; */

  return (
    <div>
      <Container style={{ width: "5px" }}>
        {/* <DialogRemoveAction
          open={openDialog}
          handleClosedDialog={handleClosedDialog}
          onExit={reload}
        /> */}
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{
            color: "#666666",
            backgroundColor: "white",
            border: "unset",
            boxShadow: "unset",
            textTransform: "lowercase",
            fontFamily: "Montserrat",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          ...
        </Button>

        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem>
            <Link
              to={{
                pathname: `/atualizar-acao/${id}`,
                state: perfomancesIndicatores,
              }}
              style={{
                width: '100%',
                textDecoration: "none",
                color: "#666666",
              }}
            >
              <ListItemIcon
                 style={{with: '100%', justifyContent: 'space-between', alignItems: 'center'}}
              >
                <i className="fas fa-edit"></i>
                <ListItemText primary="editar"  style={{marginLeft: '20px'}}/>
              </ListItemIcon>
            </Link>
          </StyledMenuItem> 
          <StyledMenuItem>
            <ListItemIcon
              style={{with: '100%', justifyContent: 'space-between', alignItems: 'center'}}
            >
              <i class="fas fa-trash-alt"></i>
              <ListItemText
                primary="remover"
                style={{ color: "#666666", marginLeft: '20px'}}
                onClick={handleDeleteAction}
              />
            </ListItemIcon>
          </StyledMenuItem>
        </StyledMenu>
      </Container>
    </div>
  );
}
