import React, { useEffect, useState } from "react";
import {
  Container,
  ImagemConclusaoTema,
  TextoConclusao,
  ButtonContainer,
  DetalhamentoScore,
  TopText,
  CloseWindow,
  DetalhamentoScoreContainer,
  Progress,
} from "./Transition.elements";
import { Button } from "../../globalStyles";
import { Link, useParams } from "react-router-dom";
import api from '../../services/api'

function TransitionSocial() {
  const [data, setData] = useState({})
  const takeID = localStorage.getItem("@legaro:companyID")
  const [loading, setLoading] = useState(true)
  const params = useParams()

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await api.get(`/companies/check/${takeID}`);
      // console.log(response.data)
      setData(response.data);
      setLoading(false);
    }
    loadData();
  }, [takeID]);
  return (
    <Container>
      <DetalhamentoScoreContainer>
        <DetalhamentoScore>
          <TopText>Detalhamento do Score » Tema Social</TopText>
          <CloseWindow to="/dashboard">
            <strong>x</strong>
          </CloseWindow>
        </DetalhamentoScore>
        <Progress></Progress>
      </DetalhamentoScoreContainer>
      <ImagemConclusaoTema>
        <img
          src="https://codexremote.com.br/esgimages/legaro/icon14.png"
          alt="Social"
        />
      </ImagemConclusaoTema>
      <TextoConclusao>
        <h2>Você concluiu o tema Social!</h2>
        <p>
          Suas respostas estão salvas, e você tem a pontuação parcial.
          <br /> Para obter a pontuação final do ESG, responda os demais temas.
        </p>
        <span></span>
      </TextoConclusao>
      {!loading && <ButtonContainer>
     
      { 
        data['Ambiental'] && data['Social'] && data['Governanca'] && data['Economico'] ?  
        <Link to='/resultado-score-final' >
        <Button>
          <strong>Ver resultado final</strong>{" "}
          <i class="fas fa-arrow-right"></i>
        </Button>
      </Link>
      : <Link to={`/selecionar-tema/${params.stageId}`} >
        <Button>
          <strong>responder outro tema</strong>{" "}
          <i class="fas fa-arrow-right"></i>
        </Button>
      </Link>
      }
      </ButtonContainer>}
    </Container>
  );
}
export default TransitionSocial;
