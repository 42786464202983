import React, { useEffect } from "react";
import HeaderAfterLogin from "../../components/Header/HeaderAfterLogin";
import NavBarQuestions from "../../components/NavBarQuestion/NavbarQuestion";
import { ContainerQuestion, Container, ButtonQuestion } from "./style.element";
import { useDispatch } from "react-redux";
import { deleteAllAnswers } from "../../store/answerReducers";

function Questionaire() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(deleteAllAnswers());
  }, [dispatch]);

  return (
    <>
      <Container>
        <HeaderAfterLogin />
        <ContainerQuestion>
          <NavBarQuestions />
        </ContainerQuestion>
        {/*<ButtonQuestion>
          <button className="btnVoltar" type="submit">
            <i className="fas fa-arrow-left"></i> voltar
          </button>
          <button className="btnPularPergunta" type="button">
            pular pergunta
          </button>
          <button className="btnAvancar" type="button">
            avançar <i className="fas fa-arrow-right"></i>
          </button>
        </ButtonQuestion>*/}
      </Container>
    </>
  );
}

export default Questionaire;
