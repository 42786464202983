import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button, ButtonLogin } from "../../globalStyles";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink,
  NavOptions,
  SignInAndOut,
} from "./Navbar.elements";

//import  { Modal }  from '../Modal/Modal';

function NavbarAmbiental() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      {/*<Modal showModal={showModal} setShowModal={setShowModal} />*/}
      <IconContext.Provider value={{ color: "#101522" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
              {/*<NavIcon />*/}
              <img
                src={
                  "https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_ESCURO.svg"
                }
                alt="LEGARO"
                width="200px"
                id="ExportLogo"
              />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>

            <NavMenu onClick={handleClick} click={click}>
              <NavOptions>
                <NavItem>
                  <NavLinks to="/o-que-e-esg" onClick={closeMobileMenu}>
                    o que é ESG
                  </NavLinks>
                </NavItem>

                <NavItem>
                  <NavLinks to="/somos-a-codex" onClick={closeMobileMenu}>
                    quem somos
                  </NavLinks>
                </NavItem>

                <NavItem>
                  <NavLinks to="/uma-plataforma" onClick={closeMobileMenu}>
                    a plataforma
                  </NavLinks>
                </NavItem>

                <NavItem>
                  <NavLinks to="/seja-parceiro" onClick={closeMobileMenu}>
                    seja parceiro
                  </NavLinks>
                </NavItem>
              </NavOptions>

              <SignInAndOut>
                <NavItemBtn>
                  {button ? (
                    <NavBtnLink to="/sign-in" className="buttonEntrar">
                      <ButtonLogin primary>entrar</ButtonLogin>
                    </NavBtnLink>
                  ) : (
                    <NavBtnLink to="/sign-in" className="buttonEntrar">
                      <ButtonLogin onClick={closeMobileMenu} fontBig primary>
                        entrar
                      </ButtonLogin>
                    </NavBtnLink>
                  )}
                </NavItemBtn>

                <NavItemBtn>
                  {button ? (
                    <NavBtnLink to="/sign-up" className="buttonInscrevase">
                      <Button primary>inscreva-se</Button>
                    </NavBtnLink>
                  ) : (
                    <NavBtnLink to="/sign-up" className="buttonInscrevase">
                      <Button onClick={closeMobileMenu} fontBig primary>
                        inscreva-se
                      </Button>
                    </NavBtnLink>
                  )}
                </NavItemBtn>
              </SignInAndOut>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default NavbarAmbiental;
