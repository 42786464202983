import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import api from "../../services/api";
import "./AccordionResults.css";
import {
  TypographyText,
  TypographyContainer,
  Indicators,
  IndiscatorsWeight,
  IndicatorsList,
  TypographyColumnContainer,
  ButtonKnowMore,
} from "./AccordionResults.elements";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AccordionResultsAmbiental({ indicadores }) {
  const classes = useStyles();
  //const IDcompany = localStorage.getItem("@legaro:companyID");
  const [data, setData] = React.useState([]);

  //load data of companies by ID
  React.useEffect(() => {
    async function loadData() {
      const IDcompany = localStorage.getItem("@legaro:companyID");
      const response = await api.get(`companies/${IDcompany}`);

      //console.log('o que é: ', response.data.company._id)
      setData(response.data);
    }
    loadData();
  }, []);

  return (
    <div className={classes.root} style={{ width: "100%" }}>
      <Accordion
        style={{
          marginBottom: "2%",
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "#FCFCFC",
          border: "1px solid #CCCCCC",
          justifyContent: "center",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#666666", width: "20px" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyText>
            <Typography
              className={classes.heading}
              id="ExportFontSize1"
              style={{
                color: "#333333",
                fontSize: "15px",
                fontFamily: "Montserrat",
              }}
            >
              <i
                className="fas fa-arrow-up"
                style={{
                  color: "#6FA824",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              ></i>{" "}
              <strong>Indicadores para comemorar</strong>
            </Typography>
          </TypographyText>
        </AccordionSummary>
        <AccordionDetails
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ width: "100%", padding: "0 !important" }}>
            {indicadores && (
              <TypographyContainer>
                {indicadores[0]?.value !== null && (
                  <TypographyColumnContainer>
                    <IndicatorsList
                      id="justify"
                      style={{ marginRight: "300px" }}
                    >
                      <Indicators id="ExportFontSize2">
                        {indicadores && indicadores[0]?.title}
                      </Indicators>
                      <IndiscatorsWeight id="ExportFontSize3">
                        {indicadores && indicadores[0]?.value} %
                      </IndiscatorsWeight>
                    </IndicatorsList>
                  </TypographyColumnContainer>
                )}
                {indicadores[1]?.value !== null && (
                  <TypographyColumnContainer>
                    <IndicatorsList style={{ marginRight: "300px" }}>
                      <Indicators id="ExportFontSize4">
                        {indicadores && indicadores[1]?.title}
                      </Indicators>
                      <IndiscatorsWeight id="ExportFontSize5">
                        {indicadores && indicadores[1]?.value} %
                      </IndiscatorsWeight>
                    </IndicatorsList>
                  </TypographyColumnContainer>
                )}
                {/*<IndicatorsMoreDetails>
                Responda a Fase 2 para obter um score de maior exatidão.
              </IndicatorsMoreDetails>*/}
                <ButtonKnowMore to="/escolha-da-fase" className="btn-accordion">
                  <p>Atualizar</p>
                  <i
                    style={{ marginLeft: "12px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </ButtonKnowMore>
              </TypographyContainer>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-1"
        style={{
          marginBottom: "2%",
          borderRadius: "10px",
          boxShadow: "none",
          backgroundColor: "#FCFCFC",
          border: "1px solid #CCCCCC",
          justifyContent: "center",
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#666666", width: "20px" }} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyText>
            <Typography
              className={classes.heading}
              id="ExportFontSize6"
              style={{
                color: "#333333",
                fontSize: "15px",
                fontFamily: "Montserrat",
              }}
            >
              <i
                className="fas fa-arrow-down"
                style={{
                  color: "#F52D29",
                  marginBottom: "10px",
                  marginRight: "12px",
                }}
              ></i>
              <strong>Indicadores a melhorar</strong>
            </Typography>
          </TypographyText>
        </AccordionSummary>
        <AccordionDetails
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={{ width: "100%", padding: "0 !important" }}>
            {indicadores && (
              <TypographyContainer>
                {indicadores[indicadores.length - 1]?.value !== null && (
                  <TypographyColumnContainer>
                    <IndicatorsList style={{ marginRight: "300px" }}>
                      <Indicators id="ExportFontSize7">
                        {indicadores &&
                          indicadores[indicadores.length - 1]?.title}
                      </Indicators>
                      <IndiscatorsWeight id="ExportFontSize8">
                        {indicadores &&
                          indicadores[indicadores.length - 1]?.value}{" "}
                        %
                      </IndiscatorsWeight>
                    </IndicatorsList>
                  </TypographyColumnContainer>
                )}
                {indicadores[indicadores.length - 2]?.value !== null && (
                  <TypographyColumnContainer>
                    <IndicatorsList style={{ marginRight: "300px" }}>
                      <Indicators id="ExportFontSize9">
                        {indicadores &&
                          indicadores[indicadores.length - 2]?.title}
                      </Indicators>
                      <IndiscatorsWeight id="ExportFontSize10">
                        {indicadores &&
                          indicadores[indicadores.length - 2]?.value}{" "}
                        %
                      </IndiscatorsWeight>
                    </IndicatorsList>
                  </TypographyColumnContainer>
                )}
                {/*<IndicatorsMoreDetails>
                Tenha acesso às questões de cada indicador fazendo um upgrade
              </IndicatorsMoreDetails>*/}

                <ButtonKnowMore to="/escolha-da-fase" className="btn-accordion">
                  <p>Atualizar</p>
                  <i
                    style={{ marginLeft: "12px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </ButtonKnowMore>
              </TypographyContainer>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
