import React from "react";
import ReactDOM from "react-dom";

// import { Container } from './styles';

const portalRoot = document.getElementById("portal-root");

const UIModal = ({ onClose = () => {}, children }) => {
  return ReactDOM.createPortal(
    <div className="ui-modal_overlay">
      <div className="ui-modal">
        <button
          type="button"
          className="ui-modal_close-button"
          onClick={onClose}
        >
          x
        </button>
        {children}
      </div>
    </div>,
    portalRoot
  );
};

export default UIModal;
