import React from 'react'
import {Title} from './styles'
import {OptionRadioColumLine} from '../OptionRadioColumLine'
import {AnswerList} from '../AllQuestionaires/style.element'

export const LineColumn = ({ ans, categoryId, idQuestion, indicatorId, slug}) => {

  // console.log("ANS", ans)

  return (
    <div>

      <Title>{ans.option}</Title>
{      ans.answerList.map((answ, index) => (
        <AnswerList key={answ._id}>

          <OptionRadioColumLine
          key={index}
          id={answ._id}
          index={index}
          slug={slug}
          idQuestion={ans._id}
          indicatorId={indicatorId}
          categoryId={categoryId}
          answerList={answ}
          answer={answ.answer}
          currentId={idQuestion}
        />
        </AnswerList>
      ))}
    </div>
  )
}