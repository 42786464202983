import React, { useState, useEffect } from "react";
//import { FiArrowLeft, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import * as Yup from "yup";
//import { useSelector } from 'react-redux';
import { useAuth } from "../../hooks/auth";
import SignUpText from "../../template/SignUpText/index";
import api from "../../services/api";
import jwt from "jsonwebtoken";

import {
  Container,
  Background,
  Content,
  ModalImg,
  CloseWindow,
  Title,
} from "./Style.Elements";

// Validation
const schema = Yup.object().shape({
  email: Yup.string().email("Email Inválido").required("E-mail é obrigatório"),
  password: Yup.string(),
});

function Login() {
  const history = useHistory();
  const { signIn, loading, setLoading } = useAuth();
  const [hiddenPassword, setHiddenPassord] = useState(true);
  const { user } = useAuth();
  const token = history.location.search.split("=")[1];
  const [federation, setFederation] = useState();

  const loadFederation = async () => {
    try {
      setLoading(true);

      const decoded = token
        ? await jwt.verify(token, process.env.REACT_APP_JWT_FEDERATION_SECRET)
        : null;

      const federationId = decoded?.federationId || user?.federationId;
      const { data } = await api.get(`/federations/${federationId}`);
      setFederation(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    loadFederation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //const loading = useSelector(useAuth());

  async function handleSubmit({ email, password }) {
    try {
      await signIn({
        email,
        password,
        method: "signin",
      });

      /*localStorage.setItem('token', response.data.token)
      console.log('token', response.data.token)*/

      history.push("/dashboard");

      toast.success(`Bem vindo, Login efetuado com sucesso!`);
    } catch (error) {
      setLoading(false);
      toast.error("Senha ou E-mail incorretos!");
    }
  }

  return (
    <Container>
      {/* <CloseWindow>
        <Link to="/">x</Link>
      </CloseWindow>
      <Background>
        <ModalImg
          src="https://codexremote.com.br/esgimages/legaro/LOGO_LEGARO_HORIZONTAL_TEXTO_CLARO_FUNDO_AZUL.webp"
          className="lazy"
        />
      </Background> */}
      <Background>
        <SignUpText />
      </Background>
      <Content>
        <Title>Login</Title>
        <Form schema={schema} onSubmit={handleSubmit}>
          <label htmlFor="email">
            E-mail<span style={{ color: "#f00" }}> *</span>
          </label>
          <Input name="email" type="email" />
          <label htmlFor="">
            Senha<span style={{ color: "#f00" }}> *</span>
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              style={{ width: "100%" }}
              name="password"
              spellCheck={false}
              type={hiddenPassword ? "password" : "text"}
              onclick="return clicked()"
            />
            <div
              style={{ color: "#444", marginLeft: 10, cursor: "pointer" }}
              onClick={() => setHiddenPassord(!hiddenPassword)}
            >
              {hiddenPassword ? (
                <RemoveRedEyeOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </div>
          </div>

          <button type="submit">
            {loading ? (
              <i style={{ fontSize: 20 }} className="fa fa-spinner fa-pulse" />
            ) : (
              "ENTRAR"
            )}
          </button>
          {/* <Link to="/recuperar-senha">Esqueci minha senha</Link> */}

          {!loading && federation ? (
            <Link to={`/recuperar-senha?query=${token}`}>
              Esqueci minha senha
            </Link>
          ) : (
            <div style={{ display: "flex" }}>
              <Link
                to="/recuperar-senha"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px auto",
                  textDecoration: "none",
                }}
              >
                Esqueci minha senha
              </Link>
            </div>
          )}
        </Form>
      </Content>
    </Container>
  );
}

export default Login;
