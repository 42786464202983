import React from "react";

import {
  Container,
  ImagemConclusaoTema,
  Menu,
} from "./TransitionGeral.elements";

function TransitionGeral() {
  return (
    <>
      <Menu>
        <p>
          <strong>Detalhamento do Score » Tema 4: Econômico</strong>
        </p>
      </Menu>
      <hr />
      <Container>
        <ImagemConclusaoTema>
          <img
            src="https://codexremote.com.br/esgimages/legaro/icon32.png"
            alt="Geral"
          />
          <h2>Seu Score CODEXSG está disponível!</h2>
        </ImagemConclusaoTema>
      </Container>
    </>
  );
}

export default TransitionGeral;
