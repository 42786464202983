import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  /* ${(props) =>
    props.styles[0]?.top &&
    css`
      padding-top: ${props.styles[0].top}px;
    `} */
`;
