import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainerAll = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
`;
export const Glossario = styled(Link)`
  border: 1px solid rgb(153, 153, 153);
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 50px;
  color: rgb(153, 153, 153);
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #f5f5f0;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 90%;
  flex-wrap: wrap;
  font-family: "Roboto", sans-serif;
  padding-bottom: 30px;
  padding-left: 30px;

  @media screen and (max-width: 664px) {
    padding-left: 0;
  }
`;

export const ContentQuestion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  //align-items: center;
  background: #fff;
  text-align: justify !important;
  width: 100%;

  h2 {
    margin-top: 2rem;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0px;
    color: #333333;
    font-size: 25px;
    font-weight: normal;
    text-align: justify !important;
    justify-content: stretch;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 500px) {
      font-size: 20px;
      text-align: justify !important;
    }

    @media screen and (max-width: 400px) {
      font-size: 17px;
      text-align: justify !important;
    }
  }

  /*.tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
*/
  /* Position the tooltip text */
  /*position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
  /*opacity: 0;
    transition: opacity 0.3s;*/

  /* Tooltip arrow */
  /*.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }*/

  /* Show the tooltip text when you mouse over the tooltip container */
  /*.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }*/

  .descriptionContainer {
    display: flex;
    justify-content: left;
    width: 100%;
    align-items: left;
  }

  .description {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 15px;
  }

  .descriptionTitle {
    font-size: 18px;
    color: #666666;
    padding-left: none;
    padding-top: 8px;
    line-height: 1.5;

    @media screen and (max-width: 500px) {
      font-size: 16px;
      text-align: justify !important;
    }

    @media screen and (max-width: 400px) {
      font-size: 15px;
      text-align: justify !important;
    }
  }

  .descriptionText {
    font-size: 18px;
    padding: 8px;
    line-height: 1.5;

    @media screen and (max-width: 500px) {
      font-size: 16px;
      text-align: justify !important;
    }

    @media screen and (max-width: 400px) {
      font-size: 15px;
      text-align: justify !important;
    }
  }
`;

export const AnswerList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  font-family: "Roboto", sans-serif;
  width: 100%;

  input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
  }

  input[type="checkbox"]:before {
    content: "";
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #e9e9e9;
  }

  input[type="checkbox"]:checked:before {
    content: "";
    display: flex;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #6fa824;
  }
  input[type="checkbox"]:checked:after {
    content: "";
    display: flex;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 7px;
  }

  input[type="radio"] {
    position: absolute;
    cursor: pointer;
  }

  input[type="radio"]:before {
    content: "";
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    left: -6px;
    background-color: #e9e9e9;
    border-radius: 50px;
  }

  input[type="radio"]:checked:before {
    content: "";
    display: flex;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    left: -6px;
    background-color: #6fa824;
  }
  input[type="radio"]:checked:after {
    content: "";
    display: flex;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 3px;
  }
`;

export const ButtonQuestion = styled.div`
  display: flex;
  flex-direction:column ;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding-bottom: 2%;
  padding-top: 1%;
  z-index: 3;

  button {
    width: 20%;
    height: 51px;
    background: #6fa824;
    border: 1px solid #6fa824;
    cursor: pointer;
    border-radius: 50px;

    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 16px;

    @media screen and (max-width: 664px) {
      font-size: 14px;
      width: 40%;
    }
  }

  .pularPergunta {
    background-color: #fff;
    color: #6fa824;
    margin-right: 2%;
    margin-left: 2%;

    @media screen and (max-width: 760px) {
      font-size: 14px;
    }
  }
`;

export const BackgroundQuestion = styled.div`
  background: #fff;
  width: 100%;
  font-family: "Roboto", sans-serif;

  .containerGlossario {
    //border: 1px solid red;
    display: flex;
    justify-content: space-between;

    .glossarioButton {
      //border: 1px solid red;
      display: flex;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
  }
`;

//export const PogressBar = styled.div`
//  width: 100%;

//  margin-top: -2rem;
//  color: #6fa824;

//  progress {
//    width: 100%;
//  }
//`;

export const AnswerListContainer = styled.div`
  margin: 3rem 0 5rem 0;
  padding-bottom: 10%;

  span {
    margin-left: 30px;
    color: #333333;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    display: flex;
    line-height: 25px;
    flex-wrap: wrap;

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }

    @media screen and (max-width: 400px) {
      font-size: 17px;
    }
  }

  @media screen and (max-width: 984px) {
    padding-bottom: 25%;
  }
`;

export const FixedDiv = styled.div`
  height: 30em;
`;
