import styled from "styled-components";

export const CookieContainer = styled.div`
  h1 {
    color: #6fa824;
    font-size: 20px;
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: black;
    margin-bottom: 20px;
  }
  a {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
  }
`;
